.mfa-field-wrapper {
  position: relative;
}
.mfa-field-wrapper .mfa-field-container {
  display: flex;
  justify-content: center;
}
.mfa-field-wrapper .mfa-field-container .input-fake {
  position: relative;
  background: #fff;
  border: 2px solid #B4B4B4;
  margin: 0 2px;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.mfa-field-wrapper .mfa-field-container .input-fake .mfa-dash {
  position: absolute;
  font-size: 35px;
  color: #B4B4B4;
  left: 13px;
}
.mfa-field-wrapper .mfa-field-container .input-real {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  width: 330px;
  height: 42px;
  position: absolute;
  top: 2px;
  padding: 0 0 5px 13px;
  font-size: 30px;
  font-family: monospace;
  font-weight: bold;
  text-align: left;
  letter-spacing: 26px;
  background: transparent;
  color: #61B4FF;
  outline: none;
  border: 0;
}

.radio-list {
  overflow: auto;
  clear: both;
  margin-bottom: 20px;
}

.radio-list.horizontal .options {
  overflow: auto;
}
.radio-list.horizontal .control-group {
  float: left;
  display: inline-block;
}
.radio-list.horizontal .control-group label {
  margin-right: 15px;
  display: block;
  float: left;
  margin-right: 20px;
}
.radio-list.horizontal .control-group input {
  margin-right: 15px;
  margin-left: 1px;
  display: block;
  float: left;
  margin-top: 6px;
}

.multi-input-field .label-message-group {
  display: flex;
  justify-content: space-between;
}
.multi-input-field .multi-input-group {
  display: flex;
  align-items: center;
}
.multi-input-field .multi-input {
  font-size: 16px;
  height: 35px;
  width: 443px;
  padding: 7px;
}
.multi-input-field .action-btn {
  margin-left: 10px;
  font-size: 18px !important;
  line-height: 30px;
  height: 33px !important;
  width: 48px !important;
  margin-bottom: 3px;
}
.multi-input-field .isDisabled, .multi-input-field .isDisabled:hover {
  opacity: 0.5 !important;
}
.multi-input-field .error {
  color: #DB5E4B;
  padding-right: 66px;
}
.multi-input-field .message {
  font-size: 15px;
  color: #61B4FF;
  margin: 0 !important;
  padding-right: 66px;
}
.multi-input-field .submitted-list {
  margin-top: 2px;
  padding: 0;
  width: 443px;
}
.multi-input-field .submitted-list .multi-item {
  width: 443px;
  border: 1px solid lightgray;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.multi-input-field .submitted-list .multi-item .remove {
  font-style: italic;
  padding-right: 5px;
}
.multi-input-field .submitted-list .multi-item:hover {
  background: #DB5E4B;
  color: white;
  opacity: 0.8;
}

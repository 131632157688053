.dashboard-in-patients {
  overflow-y: hidden;
}
.dashboard-in-patients .breadcrumb-bar {
  padding: 0;
}
.dashboard-in-patients .breadcrumb-bar button, .dashboard-in-patients .breadcrumb-bar input {
  border: none;
}
.dashboard-in-patients .breadcrumb-bar button:focus, .dashboard-in-patients .breadcrumb-bar input:focus {
  outline: none;
}
.dashboard-in-patients .breadcrumb-bar .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  color: #FFFFFF;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 20px 0 20px;
  height: 50px;
  background: #5B6272;
  color: #ffffff;
  font-size: 14;
  font-weight: 900;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left {
  display: flex;
  padding-bottom: 10px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter {
  font-size: 14px;
  padding: 0 20px 0 20px;
  margin-right: 1px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background: #6D778B;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter .group-filter-label {
  line-height: 16px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper {
  position: relative;
  width: 156px;
  padding: 0;
  max-height: 218px;
  display: flex;
  justify-content: flex-start;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-label {
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  height: 100%;
  width: 100%;
  align-items: center;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-label span {
  display: inline-block;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container {
  position: absolute;
  left: -2px;
  top: 40px;
  border-top: 3px solid #f5f5f5;
  border-radius: 5px;
  background: #FFFFFF;
  max-height: 218px;
  width: 157px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-searchbox {
  width: 100%;
  overflow-x: hidden;
  height: 22px;
  color: #787878;
  padding: 5px 10px 12px 10px;
  border-bottom: 2px solid #e9e9e9;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu {
  margin-bottom: 0px;
  max-height: 181px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding-left: 10px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child:first-child {
  padding-top: 4px !important;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child:hover {
  background: #DCF1FF;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child.no-results:hover, .dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child.too-many-results:hover {
  cursor: not-allowed;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter:hover {
  background: #848EA0;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .selectedGroupFilter, .dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .selectedGroupFilter:hover {
  background: #A2ACBC;
}
.dashboard-in-patients .breadcrumb-bar .non-admin-keyword-search {
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container {
  height: 30px;
  margin-left: 9px;
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container .search-field-input {
  height: 100%;
  font-weight: 900;
  flex-shrink: 0;
  width: 353px;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container .asNonAdmin {
  width: 303px;
  font-weight: 600;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container .search-field-button {
  height: 30px;
  width: 85px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #61B4FF;
  color: #FFF;
}
.dashboard-in-patients .breadcrumb-bar .add-patient-button {
  height: 30px;
  width: 115px;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
  background: #61B4FF;
}
.dashboard-in-patients .breadcrumb-bar .add-patient-button:hover {
  background: #359bf5;
}
.dashboard-in-patients .breadcrumb-bar .addPatientButtonAsOwner {
  float: right;
  background: #6D778B;
  color: #FFFFFF;
}
.dashboard-in-patients .breadcrumb-bar .addPatientButtonAsOwner:hover {
  background: #5c6375;
}
.dashboard-in-patients .breadcrumb-bar .filters-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0 20px;
  height: 50px;
  width: 100vw;
  background: #A2ACBC;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filters {
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filters .search-container {
  height: 30px;
  width: 353px;
  margin-left: 9px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filters .search-container .search-input {
  height: 100%;
  width: 100%;
  font-weight: 900;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter {
  border-radius: 10px 10px 0 0;
  background: #B6C0D0;
  height: 100%;
  padding: 0px 20px;
  margin-right: 1px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group {
  height: 100%;
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-label, .dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #646464;
  line-height: 16px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-label {
  font-size: 14px;
  padding-right: 8px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-number {
  font-size: 18px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filterNumberImportant {
  color: #DB5E4B !important;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter:hover {
  background: #C6CEDB;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter:hover .filter-text-group .filter-label, .dashboard-in-patients .breadcrumb-bar .filters-container .filter:hover .filter-text-group .filter-number {
  color: #646464;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter, .dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter:hover {
  background: #FFFFFF;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter .filter-text-group .filter-label, .dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter:hover .filter-text-group .filter-label {
  color: #646464 !important;
}

.patients {
  display: flex;
  flex-direction: column;
}
.patients button, .patients input {
  border: none;
}
.patients .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: 9px;
  color: #DCDCDC;
}
.patients .filters {
  display: flex;
  padding: 10px 20px 0 20px;
  height: 50px;
  width: 100vw;
  background: #A2ACBC;
}
.patients .filters .filter {
  border-radius: 10px 10px 0 0;
  background: #B6C0D0;
  height: 100%;
  padding: 0px 20px;
  margin-right: 1px;
}
.patients .filters .filter .filter-text-group {
  height: 100%;
  display: flex;
}
.patients .filters .filter .filter-text-group .filter-label, .patients .filters .filter .filter-text-group .filter-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #646464;
  line-height: 16px;
}
.patients .filters .filter .filter-text-group .filter-label {
  font-size: 14px;
  padding-right: 8px;
}
.patients .filters .filter .filter-text-group .filter-number {
  font-size: 18px;
}
.patients .filters .filter .filter-text-group .filterNumberImportant {
  color: #DB5E4B !important;
}
.patients .filters .filter:hover {
  background: #C6CEDB;
}
.patients .filters .filter:hover .filter-text-group .filter-label, .patients .filters .filter:hover .filter-text-group .filter-number {
  color: #646464;
}
.patients .filters .selectedFilter, .patients .filters .selectedFilter:hover {
  background: #FFFFFF;
}
.patients .filters .selectedFilter .filter-text-group .filter-label, .patients .filters .selectedFilter:hover .filter-text-group .filter-label {
  color: #646464 !important;
}
.patients .keyword-search-help-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
}
.patients .keyword-search-help-container .keyword-search-help {
  display: flex;
  width: 440px;
}
.patients .keyword-search-help-container .keyword-search-help .keyword-search-text-content {
  margin-left: 14px;
  padding-top: 35px;
}
.patients .keyword-search-help-container .keyword-search-help .keyword-search-text-content .main-text {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
}
.patients .search-status {
  margin-top: 10px;
  margin-left: 20px;
}
.patients .search-status .search-status-text, .patients .search-status .search-status-number {
  font-weight: 900;
  color: #646464;
}
.patients .search-status .search-status-text {
  font-size: 14px;
  padding-right: 8px;
}
.patients .search-status .search-status-number {
  font-size: 18px;
}
.patients .patient-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 140px);
  overflow: scroll;
}
.patients .patient-table-container .loading-indicator {
  position: sticky;
  left: 50%;
  bottom: 50%;
  height: 75px;
  width: 75px;
}
.patients .patient-table-container .no-results-container {
  position: absolute;
  z-index: -1;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.patients .patient-table-container .no-results-container .no-results {
  padding: 18px 26px 18px 18px;
  width: fit-content;
  background: #F5F5F5;
  border-radius: 5px;
}
.patients .patient-table-container .no-results-container .no-results .no-results-found-header {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #CC5062;
}
.patients .patient-table-container .no-results-container .no-results .you-can-search-on {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}
.patients .patient-table-container .no-results-container .no-results .fields-to-search-on .field-to-search-on {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding: 0 !important;
  margin: 0 !important;
}
.patients .patient-table-container .patient-table {
  margin-top: 30px;
  margin-bottom: 25px;
  border-collapse: collapse;
  width: 100%;
}
.patients .patient-table-container .patient-table .hideTableRows {
  visibility: hidden;
}
.patients .patient-table-container .patient-table .hideTableRows tr .view-patient-details-button {
  visibility: hidden;
}
.patients .patient-table-container .patient-table thead.hideTableHead {
  visibility: hidden;
}
.patients .patient-table-container .patient-table thead tr:hover {
  background: inherit;
}
.patients .patient-table-container .patient-table td, .patients .patient-table-container .patient-table th {
  padding: 3px 0;
}
.patients .patient-table-container .patient-table th {
  font-weight: normal;
  font-size: 12px;
}
.patients .patient-table-container .patient-table th:hover {
  text-decoration: underline;
  cursor: pointer;
}
.patients .patient-table-container .patient-table td {
  font-weight: 900;
  font-size: 14px;
}
.patients .patient-table-container .patient-table td.negative {
  color: #DB5E4B;
}
.patients .patient-table-container .patient-table tr {
  cursor: default;
}
.patients .patient-table-container .patient-table tr td:first-child, .patients .patient-table-container .patient-table tr th:first-child {
  padding-left: 20px;
}
.patients .patient-table-container .patient-table tr:hover {
  background: #DCF1FF;
}
.patients .patient-table-container .patient-table tr:hover .view-patient-details-button {
  visibility: visible;
}
.patients .patient-table-container .patient-table .td-with-button {
  text-align: right;
  padding-right: 20px;
}
.patients .patient-table-container .patient-table .td-with-button .view-patient-details-button {
  visibility: hidden;
  height: 25px;
  width: 103px;
  background: #6D778B;
  border: none;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  border-radius: 5px;
}
.patients .patient-table-container .patient-table .td-with-button .view-patient-details-button:focus {
  outline: none;
}
.patients .patient-table-container .patient-table .edit-icon {
  margin-right: 5px;
}
.patients .patient-table-container .patient-table .clickedRow {
  background: #61B4FF !important;
  color: #FFFFFF !important;
}
.patients .patient-table-container .patient-table .clickedRow .view-patient-details-button {
  visibility: visible;
}
.patients .search-loading-indicator {
  margin-left: 50vw;
  margin-top: 40vh;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .view-patient-details-button {
    width: 120px !important;
  }
}
@-moz-document url-prefix() {
  .view-patient-details-button {
    width: 120px !important;
  }
}
.portal-container-in-patients {
  overflow-y: scroll;
}
.portal-container-in-patients .close-button {
  color: #787878;
}
.portal-container-in-patients .modal-panel {
  margin-top: 50px !important;
}

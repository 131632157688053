.assessment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
.assessment-overlay .container {
  margin: 200px auto 0;
  width: 600px;
  border-radius: 15px;
  background: white;
  padding: 0;
  overflow-y: scroll;
  height: 600px;
  cursor: auto;
}
.assessment-overlay .container h1 {
  background: #61B4FF;
  color: white;
  padding: 6px 12px;
  width: 100%;
}
.assessment-overlay .container h3 {
  margin-left: 12px;
  margin-top: 20px;
}
.assessment-overlay .container .questions, .assessment-overlay .container .results {
  list-style: none;
  margin: 0 0 0 12px;
  padding: 0;
}
.assessment-overlay .container .questions .assessment-label, .assessment-overlay .container .results .assessment-label {
  margin-bottom: 0;
  color: #6D778B;
}
.assessment-overlay .container .questions .assessment-value, .assessment-overlay .container .results .assessment-value {
  font-weight: bold;
}
.assessment-overlay .container .no-results {
  margin-left: 16px;
}

@media print {
  @page {
    size: A4 !important;
    margin: 0 !important;
  }
  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    overflow: visible !important;
  }
  #navigationBar .nav-divider, #navigationBar .nav-pages-container, #navigationBar .nav-pages, #navigationBar .account-options-container {
    display: none;
  }
  .dashboard-layout #topBars {
    height: auto;
  }
  .SplitPane {
    display: block !important;
    height: auto !important;
    position: relative !important;
    overflow: visible !important;
  }
  .Pane {
    width: auto !important;
  }
  .Resizer {
    display: none !important;
  }
  #breadCrumbBar, .notifications-wrapper, #beacon-container, .MuiDivider-root, .user-actions, [alt=edit] {
    display: none;
  }
  .btn, .editable img, .user-basic img {
    display: none !important;
  }
  body {
    padding: 20px !important;
  }
  #navigationBar {
    position: relative;
    margin: 10px 0;
  }
  #navigationBar .logo {
    background: #888 !important;
    padding: 10px;
    border-radius: 5px;
    margin: 0 auto;
    float: none;
    font-size: 0 !important;
  }
  .welcome-to-the-danger-zone #navigationBar .navigation-bar {
    background: none !important;
  }
  .user-basic {
    page-break-after: always !important;
  }
  .user-basic h3 {
    font-size: 16px;
  }
  .user-basic h1 {
    font-size: 30px !important;
    overflow: hidden;
  }
  .user-basic h1, .user-basic h2, .user-basic h3, .user-basic h4 {
    color: #656565 !important;
  }
  .user-basic > div {
    width: 200px;
    border: 2px solid #ddd;
    border-radius: 15px;
    padding: 10px;
    float: left;
    height: 400px;
    margin: 10px 10px 10px 0;
  }
  .user-basic h4, .user-basic h2 {
    font-weight: bold;
    margin: 10px 0;
  }
  .data-set {
    margin: 0 !important;
  }
  .data-set .labels {
    margin-top: 0px !important;
    font-size: 16px;
    color: #656565;
  }
  .data-set p {
    color: #656565 !important;
    margin-bottom: 5px !important;
  }
  .Pane2 {
    clear: both;
  }
  .user-events {
    margin: 0 !important;
    padding: 30px 0 0 0 !important;
  }
  .user-events .neutral, .user-events .negative, .user-events .positive {
    margin-bottom: 10px !important;
    color: white !important;
  }
  .user-events li {
    border-radius: 10px !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .background-group {
    margin-top: 20px;
  }
  .background-group .data-set {
    margin-right: 10px !important;
  }
  .background-group .background-fields {
    margin-bottom: 20px;
  }
  .engagement-card-interaction-group {
    display: block !important;
  }
  .engagement-card-interaction-group .child-1, .engagement-card-interaction-group .child-2 {
    width: 800px;
    margin-left: 0 !important;
  }
  .engagement-card-interaction-group .card-interactions {
    float: none;
    margin-bottom: 30px;
  }
  .engagement-card-interaction-group .card-interactions .header-text {
    font-size: 30px;
  }
  .program-progress {
    clear: both;
  }
  .card-interactions .card-container {
    padding: 20px 0 0 0 !important;
    border: none !important;
    overflow-y: visible !important;
    background: none !important;
  }
  .no-metric-data .chart-container {
    display: none;
  }
  .no-metric-data .overlay {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    text-align: left !important;
  }
  .no-metric-data h3 {
    margin: 0 !important;
    font-size: 16px;
  }
  .colored-bar .background-color {
    background: #DBDCDB !important;
  }
  .colored-bar-positive .inner-colored-bar {
    background: #3CBF3D !important;
  }
  .colored-bar-neutral .inner-colored-bar {
    background: #FFBA31 !important;
  }
  .colored-bar-negative .inner-colored-bar {
    background: #DB5E4B !important;
  }
  .card-interactions .card-container .card-row .complete {
    background: url("checkmark.svg") center center no-repeat #61B4FF !important;
  }
  .user-events .neutral {
    background: #FFBA31 !important;
  }
  .user-events .negative {
    background-color: #DB5E4B !important;
  }
  .user-events .positive {
    background-color: #3CBF3D !important;
  }
  .dashboard-layout .page-container, .SplitPane .sidebar, .SplitPane .page-content {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    top: auto !important;
    height: auto !important;
    overflow: visible !important;
    overflow-y: visible !important;
  }
  .sidebar {
    background: white !important;
  }
  div.user-basic.user-basic-invite > div:nth-child(7), div.user-basic.user-basic-invite > div:nth-child(8), div.user-basic.user-basic-invite > div:nth-child(9) {
    height: auto !important;
    padding-bottom: 20px !important;
    overflow: visible !important;
  }
  .user-events, .assessment-scores {
    page-break-before: always !important;
  }
}

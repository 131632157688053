.program-list-col {
  padding-right: 0;
  padding-left: 0;
}

.card-list-col {
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid #DFDFDF;
  width: calc(100% - 375px);
}

.cards {
  flex-wrap: nowrap !important;
}

.dashboard-in-cards {
  background-color: #afd371;
}

.dashboard-in-cards #topBars {
  background-color: #dcf1ff;
}

.card-list-content {
  text-align: left;
  background-color: #dcf1ff;
  background-image: url("./../../assets/patient_banner_background.png");
  background-repeat: no-repeat;
  background-position: top, center;
  height: 323px;
}

.preview-col {
  width: 375px;
}

.autocomplete-form-control {
  padding: 6px 12px !important;
  font-size: 14px !important;
  margin-top: 0 !important;
  height: 35px !important;
}

.react-autosuggest__suggestions-list {
  max-height: 250px;
  overflow-y: scroll;
  display: inline-block;
  border: 1px solid #B4B4B4;
  padding: 0;
  margin: 0;
}
.react-autosuggest__suggestions-list li {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0;
  width: auto;
}
.react-autosuggest__suggestions-list li:hover, .react-autosuggest__suggestions-list .react-autosuggest__suggestion--highlighted {
  background-color: #61B4FF;
  color: white;
}

.cards-charts-filter-container {
  padding: 8px 8px 0 0 !important;
  margin: 0 !important;
}
.cards-charts-filter-container .form-control, .cards-charts-filter-container .Select--single {
  width: 75% !important;
  float: left;
  box-sizing: border-box;
}
.cards-charts-filter-container label {
  padding-top: 4px;
  margin-right: 10px;
  float: left;
  box-sizing: border-box;
  margin-bottom: 0 !important;
}
.cards-charts-filter-container .col-md-4:first-child {
  padding-left: 0 !important;
}
.cards-charts-filter-container .col-md-4 {
  margin-bottom: 0 !important;
}

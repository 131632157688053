.customers {
  float: left;
}

.calendars {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.core-calendar-container {
  margin: 10px;
}

.core-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

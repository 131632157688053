.admin-overlay {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
}
.admin-overlay .admin-overlay-close-modal {
  position: absolute;
  right: 13px;
  top: 20px;
  color: #787878;
  width: 26px !important;
  height: 26px !important;
  font-size: 18px;
}
.admin-overlay .customer-dropdown-loading {
  position: absolute;
  top: 35px;
  left: 385px;
}
.admin-overlay .customer-dropdown {
  position: absolute;
  top: 26px;
  left: 275px;
  border: 1px solid rgba(44, 44, 44, 0.4588235294);
  width: 322px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
}
.admin-overlay .customer-dropdown .customer-search {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 14px;
  color: #5e5e5e;
  padding: 10px;
  border: none;
  background: #f3f3f3;
  box-shadow: 0px 0px 3px 1px rgba(114, 114, 114, 0.9019607843);
}
.admin-overlay .customer-dropdown .customer-search:focus {
  outline: none;
  border: none;
}
.admin-overlay .customer-dropdown .customer-list {
  border-radius: 5px;
  min-height: fit-content;
  max-height: 150px;
  min-width: 320px;
  overflow: scroll;
}
.admin-overlay .customer-dropdown .customer-list .customer-item {
  padding-left: 10px;
  padding-bottom: 3px;
  color: #787878;
  font-size: 14px;
}
.admin-overlay .customer-dropdown .customer-list .customer-item:hover {
  background: #DCF1FF;
}

.extra-fields-loading {
  height: 235px;
  position: relative;
  display: flex;
}
.extra-fields-loading .extra-fields-loading-spinner {
  position: absolute;
  height: 60px;
  width: 60px;
  left: 310px;
  top: 70px;
}

.patient-invite-form {
  width: 100%;
  background: #FAFAFA;
  min-height: 507px;
}
.patient-invite-form .selected-customer {
  position: absolute;
  top: 35px;
  left: 275px;
  font-weight: 900;
  font-size: 14px;
  color: #61B4FF;
}
.patient-invite-form .selected-customer .clear-customer {
  margin-left: 11px;
  height: 20px;
  width: 20px;
  font-size: 12px;
  display: none;
}
.patient-invite-form .selected-customer .clear-customer:hover {
  opacity: 0.95;
}
.patient-invite-form .selected-customer:hover {
  text-decoration: underline;
}
.patient-invite-form .selected-customer:hover .clear-customer {
  display: inline;
}
.patient-invite-form .form-group {
  position: relative;
}
.patient-invite-form .form-group .error-message {
  position: absolute;
  top: 0;
  right: 20px;
  font-weight: normal;
  font-size: 12px;
}
.patient-invite-form .label-has-error {
  color: #DB5E4B !important;
}
.patient-invite-form .label-has-error .asterisk {
  color: #DB5E4B !important;
}
.patient-invite-form .has-error {
  border-color: #DB5E4B !important;
}
.patient-invite-form .sections-container {
  display: flex;
}
.patient-invite-form .asterisk {
  color: #61B4FF;
  padding-left: 1px;
}
.patient-invite-form .patient-invite-header-group {
  display: flex;
  align-items: flex-end;
  margin-bottom: 26px;
  color: #787878;
}
.patient-invite-form .patient-invite-header-group p, .patient-invite-form .patient-invite-header-group h2 {
  margin: 0;
}
.patient-invite-form .patient-invite-header-group h2 {
  font-size: 24px;
  font-weight: 900;
  margin-right: 10px;
}
.patient-invite-form .patient-invite-header-group p {
  font-size: 12px;
  font-weight: normal;
  line-height: 19px;
}
.patient-invite-form .disabled-surgery-date {
  cursor: not-allowed;
}
.patient-invite-form .disabled-surgery-date .dummy-label, .patient-invite-form .disabled-surgery-date .dummy-field {
  color: #C8C8C8;
}
.patient-invite-form .disabled-surgery-date .dummy-label {
  font-weight: normal;
  line-height: 23px;
}
.patient-invite-form .disabled-surgery-date .dummy-field {
  font-style: italic;
  font-size: 14px;
  margin-top: 9px;
}
.patient-invite-form .patient-invite-drilldown-field .filterField:hover {
  cursor: pointer;
}
.patient-invite-form .patient-invite-drilldown-field .filterField {
  padding: 6px 12px;
  color: #787878;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
}
.patient-invite-form .patient-invite-drilldown-field .dropdown .withChild .parent, .patient-invite-form .patient-invite-drilldown-field .dropdown .withChild .clickable {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 27px;
}
.patient-invite-form .dateInputField {
  position: relative;
  width: 175px;
}
.patient-invite-form .patient-invite-date-field {
  margin-top: 0;
  height: 40px;
  width: 79px;
  font-size: 14px;
  border-color: #ccc;
  border-radius: 4px;
  color: #787878;
  font-weight: 900;
}
.patient-invite-form .react-datepicker__input-container input {
  padding-left: 10px;
  padding-right: 10px;
}
.patient-invite-form .patient-invite-date-field:focus, .patient-invite-form .patient-invite-drilldown-field .filterField:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.patient-invite-form .patient-invite-date-field:hover {
  cursor: pointer;
}
.patient-invite-form .react-datepicker__day-name {
  color: #656565;
}
.patient-invite-form .optional-date-container {
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  position: absolute;
  left: 85px;
  top: 25px;
  display: flex;
}
.patient-invite-form .optional-date-container input {
  height: 20px !important;
  width: 22px !important;
  background-size: 15px;
  margin-top: 4px !important;
}
.patient-invite-form .optional-date-container label[for=optionalDate] {
  margin: 0;
  margin-left: 7px;
  font-weight: normal;
  line-height: 16px;
}
.patient-invite-form .patient-invite-fields {
  width: 550px;
}
.patient-invite-form .patient-invite-fields label {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 12px;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field {
  margin-right: 20px;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean {
  order: 9999;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control {
  width: 249px;
  height: 53px !important;
  position: reset;
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control .form-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control .form-group label {
  position: static !important;
  margin: 0 !important;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control .form-group input {
  margin: 0 !important;
  margin-right: 15px !important;
  position: static !important;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field .medium {
  width: 250px;
  color: #787878;
  font-weight: 900;
  height: 40px;
  margin-top: 0;
  font-size: 14px;
  background: #ffffff;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field .medium::placeholder {
  font-weight: 900;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field .medium:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.patient-invite-form .patient-invite-fields .field-pair {
  display: flex;
}
.patient-invite-form .patient-invite-fields .field-pair .large, .patient-invite-form .patient-invite-fields .field-pair .small, .patient-invite-form .patient-invite-fields .field-pair .medium {
  margin-right: 20px;
}
.patient-invite-form .patient-invite-fields .field-pair .large, .patient-invite-form .patient-invite-fields .field-pair .small {
  color: #787878;
  font-weight: 900;
  height: 40px;
}
.patient-invite-form .patient-invite-fields .field-pair .large::placeholder, .patient-invite-form .patient-invite-fields .field-pair .small::placeholder {
  font-weight: 900;
}
.patient-invite-form .patient-invite-fields .field-pair .large {
  width: 322px;
}
.patient-invite-form .patient-invite-fields .field-pair .small {
  width: 175px;
}
.patient-invite-form .patient-invite-submit-button {
  display: block;
  height: 40px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border-radius: 5px;
  margin: 20px auto 0px auto;
  background: #6D778B;
  border: none;
  cursor: pointer;
}
.patient-invite-form .patient-invite-submit-button:focus {
  border: none;
}
.patient-invite-form .consent-checkbox {
  position: relative;
}
.patient-invite-form .consent-checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
}
.patient-invite-form .consent-checkbox label[for=consent] {
  margin-top: 7px;
  padding-left: 40px;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}
.patient-invite-form .disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

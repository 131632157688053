.stats-container {
  margin: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #434a4a;
  color: white;
}
.stats-container .positive {
  color: #00ff00;
}
.stats-container .negative {
  color: #ff0000;
}
.stats-container h1 {
  color: white;
}
.stats-container .value {
  font-size: 40px;
}

.title {
  text-align: center;
  padding-top: 5px;
  color: white;
}

.dashboard-in-kpi-stats {
  background-color: #000000;
}
.dashboard-in-kpi-stats h1 {
  margin-bottom: 0px;
}

.difference {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.difference span {
  margin-bottom: -5px;
  margin-left: 4px;
  margin-right: 4px;
}

.stat {
  display: flex;
  flex-direction: row;
  align-items: flex-end !important;
}
.stat p {
  margin-bottom: 0px;
}

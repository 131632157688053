.card-interactions {
  float: right;
}
.card-interactions .header-text {
  font-size: 21px;
  margin-bottom: 6px;
}
.card-interactions .cardContainerEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 308px;
}
.card-interactions .cardContainerEmpty .no-cards {
  font-size: 18px;
  color: grey;
  font-weight: 900;
}
.card-interactions .cardContainerWithCards {
  height: 308px;
}
.card-interactions .card-container {
  padding: 20px 20px 0px 20px;
  width: 388px;
  border: 1px solid #C4CEE4;
  border-radius: 6px;
  background: #F8FAFF;
  font-size: 14px;
  overflow-y: scroll;
}
.card-interactions .card-container a:hover {
  text-decoration: none;
}
.card-interactions .card-container .card-row {
  display: flex;
}
.card-interactions .card-container .card-row .complete {
  height: 26px;
  min-width: 26px;
  margin-right: 12px;
  background: url("checkmark.svg") center center no-repeat #61B4FF;
  background-size: 15px;
  border: none;
  border-radius: 50%;
}
.card-interactions .card-container .card-row .incomplete {
  margin-right: 12px;
  height: 26px;
  width: 26px;
}
.card-interactions .card-container .card-row .incomplete:hover {
  cursor: auto;
}
.card-interactions .card-container .card-row .cardActivityComplete {
  font-size: 14px;
  font-weight: 900;
  color: #B4B4B4;
}
.card-interactions .card-container .card-row .cardActivityComplete:hover {
  cursor: pointer;
}
.card-interactions .card-container .card-row .cardActivityIncomplete {
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
}
.card-interactions .card-container .card-row .cardActivityComplete:hover {
  cursor: pointer;
}

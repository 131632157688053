.delete-peer {
  padding: 0px 30px 0 30px;
  text-align: center;
}
.delete-peer .patient-list-link {
  font-weight: 900;
  font-size: 18px;
  color: #61B4FF;
}
.delete-peer .successful-delete-message {
  padding-bottom: 30px;
  margin-top: 25px;
  margin-bottom: 0px;
}
.delete-peer .patient-list-link:hover {
  text-decoration: none;
}

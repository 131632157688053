.change-partner-overlay, .change-partner-overlay .customer-dropdown {
  position: relative;
  top: 0;
  left: 0;
}

.change-partner-overlay {
  background: none;
  backdrop-filter: none;
}

.patient-invite-fields .program-dropdown {
  margin-bottom: 20px;
}
.patient-invite-fields .program-dropdown input {
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.patient-invite-fields .program-dropdown .dropdown {
  min-height: fit-content;
  max-height: 250px;
  overflow: scroll;
}
.patient-invite-fields .program-dropdown .dropdown li {
  padding: 0 15px;
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.patient-invite-fields .program-dropdown .dropdown li:hover {
  background: rgba(97, 180, 255, 0.3);
}

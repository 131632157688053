.pagination-container {
  display: flex;
  justify-content: center;
}
.pagination-container .caret.left, .pagination-container .caret.right {
  color: #4D5C7C !important;
  margin: 0 !important;
}
.pagination-container .caret.left.single-arrow {
  margin-right: 10px !important;
}
.pagination-container .caret.right.single-arrow {
  margin-left: 10px !important;
}
.pagination-container .min-page-arrows, .pagination-container .max-page-arrows {
  display: inline-flex;
}
.pagination-container .pagination .active {
  font-weight: bold;
}
.pagination-container .pagination li a {
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  text-align: center;
  display: inline-block;
  color: #656565;
  font-weight: lighter;
  border: none;
}
.pagination-container .pagination li a:hover {
  text-decoration: underline;
  color: #212735;
  background: none;
}
.pagination-container .pagination li a:hover .caret {
  color: #212735 !important;
}
.pagination-container .pagination li a:focus-within {
  background: #f0ecec;
}

.has-error {
  border-color: #DB5E4B !important;
}

.error-message {
  margin-top: 3px;
  display: inline-block;
  color: #DB5E4B !important;
}

.formik-text-field label {
  margin-bottom: 5px;
  margin-top: 10px;
}

.formik-text-field:first-child label {
  margin-top: 0;
}

.flex .formik-text-field:first-child label {
  margin-top: 10px;
}

.formik-text-field select {
  min-width: 500px;
  width: 100%;
  margin-bottom: 10px;
}

.formik-sortable .Select-value {
  display: none;
}

.formik-sortable-field-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.formik-sortable-field {
  display: flex;
  background-color: #ebf5ff;
  border-radius: 2px;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  font-size: 0.9em;
  margin-top: 5px;
  width: max-content;
}

.formik-sortable-field .item-grab-icon {
  width: 18px;
  background: url("grab.svg") center center no-repeat;
  border-right-style: solid;
  border-right-color: rgba(0, 126, 255, 0.24);
}

.formik-sortable-field .item-label {
  min-width: max-content;
  padding: 5px 19px 5px 5px;
  widows: -webkit-fill-available;
}

.formik-sortable-field .item-clear {
  padding: 6px;
  border-left-style: solid;
  border-left-color: rgba(0, 126, 255, 0.24);
  width: 20px;
}

.formik-sortable-field .item-clear:hover {
  background-color: #d8ebff;
}

.card-interactions-worker {
  width: 100%;
}
.card-interactions-worker ul {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  list-style: none;
  margin: 0;
  padding: 20px;
  max-height: 500px;
  overflow-y: scroll;
}

.drilldown-select .clickable {
  cursor: pointer;
}
.drilldown-select .form-control {
  padding: 0;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.drilldown-select .form-control .filterField {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  display: block;
  width: 100%;
  padding: 0 20px;
  position: relative;
  margin-top: 0;
  border: 0;
}
.drilldown-select .dropdown {
  background: white;
  border: 1px solid #CCCCCC;
  list-style: none;
  position: relative;
  padding: 0;
  display: none;
  z-index: 9999;
}
.drilldown-select .dropdown .parent, .drilldown-select .dropdown .submenu li {
  padding: 5px 20px;
  display: block;
  white-space: nowrap;
}
.drilldown-select .dropdown .parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drilldown-select .dropdown .parent .caret {
  display: none;
  border-left: 6px dashed;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  padding-right: 0;
}
.drilldown-select .dropdown .parent:hover .caret {
  display: inline;
}
.drilldown-select .dropdown .withChild:hover .parent, .drilldown-select .dropdown .submenu li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.drilldown-select .dropdown .withChild {
  position: relative;
}
.drilldown-select .dropdown .submenu {
  display: none;
  position: absolute;
  left: 100%;
  background: white;
  list-style: none;
  top: -1px;
  border: 1px solid #CCCCCC;
  padding: 0;
}
.drilldown-select .dropdown .withChild:hover .submenu, .drilldown-select .dropdown .withChild.hasMatchedChild:first-of-type .submenu {
  display: block;
}
.drilldown-select .dropdown:hover .withChild.hasMatchedChild:first-of-type .submenu {
  display: none;
}
.drilldown-select .dropdown:hover .withChild.hasMatchedChild:first-of-type:hover .submenu {
  display: block;
}
.drilldown-select .isOpen .dropdown {
  display: block;
}

.dashboard-layout #topBars {
  height: auto;
}
.dashboard-layout #root > div {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) .content-container {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) .content-container .workers {
  height: calc(100% - 50px);
}
.dashboard-layout #root > div > div:nth-child(2) .content-container .workers .datatable {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) .content-container .workers .datatable .MuiTablePagination-actions {
  margin-right: 100px;
}

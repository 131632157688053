.update-password-container .error-message {
  display: block;
  margin-left: 45px !important;
}
.update-password-container .form-group {
  display: block !important;
}
.update-password-container .btn-main {
  width: 280px;
  margin-left: auto;
  margin-right: auto;
  color: white !important;
  text-decoration: none !important;
}

.flex {
  display: flex;
  flex-direction: row;
}

input[type=checkbox] {
  margin-bottom: -5px !important;
  margin-top: 10px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 23px !important;
  box-sizing: border-box;
}

.checkbox-form-control {
  height: 30px !important;
}
.checkbox-form-control label {
  top: 0px !important;
}

.portal-updateBackground {
  overflow-y: scroll;
}

.patient-details-header {
  margin-bottom: 12px !important;
}

.patient-details-form .date-input, .patient-details-form .patient-height-container, .patient-details-form .multiselect, .patient-details-form .number-input, .patient-details-form .radio-list {
  margin-top: 8px !important;
  color: #868786 !important;
}
.patient-details-form .date-input label, .patient-details-form .patient-height-container label, .patient-details-form .multiselect label, .patient-details-form .number-input label, .patient-details-form .radio-list label {
  margin-bottom: 4px;
  font-size: 20px;
}
.patient-details-form .number-input {
  width: 33%;
}
.patient-details-form .multiselect {
  width: 50%;
}
.patient-details-form .date-input {
  margin: 23px 0 !important;
}
.patient-details-form .radio-list {
  margin-bottom: 5px;
}
.patient-details-form .radio-list .control-group label {
  font-size: 16px;
  color: #656565;
}
.patient-details-form .patient-height-container {
  display: flex;
  flex-direction: row;
  color: #868786;
}
.patient-details-form .patient-height-container label {
  font-size: 16px;
}
.patient-details-form .patient-height-container .patient-height-input {
  margin-right: 5px;
  width: 33%;
}
.patient-details-form .patient-detail-form-buttons {
  margin-top: 10px;
}
.patient-details-form .patient-detail-form-buttons .btn {
  margin-top: 10px;
  height: 50px;
  width: 120px;
}
.patient-details-form .patient-detail-form-buttons .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
  height: 50px;
  width: 120px;
}

.bottom-bar .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
}
.bottom-bar .btn-danger, .bottom-bar .btn-primary {
  margin-right: 20px;
}
.bottom-bar .btn {
  width: 120px;
}

.pull-right.buttons {
  padding-bottom: 15px;
}

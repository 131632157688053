.portal-container {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.portal-container .background-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
}
.portal-container .modal-panel {
  background: #FAFAFA;
  position: relative;
  z-index: 9999;
  width: 30%;
  min-width: 400px;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.portal-container .modal-panel .content-container {
  box-sizing: border-box;
  margin-top: 30px;
}
.portal-container .modal-panel-with-width {
  min-width: auto;
}
.portal-container .close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #61B4FE;
  font-size: 20px;
}
.portal-container .content-container {
  padding-bottom: 20px;
}

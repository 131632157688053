.phases-page {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
}
.phases-page .top {
  padding: 20px 30px 0 30px;
}
.phases-page .top h2 {
  padding-top: 5px;
  margin-bottom: 0;
}
.phases-page .phases-list {
  padding-left: 0;
  margin: 20px 0 0 0;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}
.phases-page .phase-list-item {
  display: block;
  padding: 8px 30px 8px 30px;
  font-weight: bold;
}
.phases-page .phase-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.phases-page .phase-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.phases-page .phase-list-item:hover .fa {
  display: inline-block;
}
.phases-page .phase-list-item-active {
  background-color: #61B4FF !important;
  color: white;
}
.phases-page .doubleclick {
  height: 20px;
  bottom: 10px;
  left: 0;
  right: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 14px;
}
.phases-page .empty-message {
  font-size: 26px;
  width: 100%;
  margin-top: calc(50vh - 150px);
  opacity: 0.5;
  font-weight: bold;
  text-align: center;
}

.dashboard-as-staff .phases-list {
  max-height: calc(100vh - 128px);
}
.dashboard-as-staff .phases-list-item:hover .fa {
  display: none;
}

.no-breadcrumb .phases-page {
  height: calc(100vh - 50px);
}

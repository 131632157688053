.has-error {
  border-color: #DB5E4B !important;
}

.error-message {
  margin-top: 3px;
  display: inline-block;
  color: #DB5E4B !important;
}

.formik-text-field {
  display: block;
}

.formik-text-field label {
  margin-bottom: 5px;
  margin-top: 10px;
  display: inline-block;
  max-width: 100%;
}

.formik-text-field input {
  min-width: 500px;
  width: 100%;
  margin-bottom: 10px;
}

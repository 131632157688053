.manage-staff {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  min-height: fit-content;
  max-height: 515px;
}
.manage-staff .checkbox-form-control {
  margin-top: 12px;
}
.manage-staff .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-staff label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-staff h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-staff h3 {
  font-size: 22px;
}
.manage-staff h3, .manage-staff h4 {
  margin: 0 0 5px 0;
}
.manage-staff .message {
  margin-top: 20px;
  margin-bottom: 0;
}
.manage-staff .clinician-dropdown .select-clinician-form {
  display: flex;
  margin-top: 5px;
}
.manage-staff .clinician-dropdown .select-clinician-form .select-clinician-drilldown {
  display: inline-block;
  width: 300px;
  margin-right: 4px;
}
.manage-staff .clinician-dropdown .select-clinician-form .select-clinician-btn {
  height: 40px;
  width: 80px;
  border-radius: 3px;
  font-size: 16px;
}
.manage-staff .clinician-dropdown .select-clinician-form .filterField {
  color: #656565;
  padding: 0 7px;
  font-size: 16px;
}
.manage-staff .display-info {
  margin-bottom: 20px;
}
.manage-staff .display-info .selected-clinician, .manage-staff .display-info .selected-codes {
  margin-bottom: 5px;
  font-size: 15px;
}
.manage-staff .display-info span {
  font-size: 16px;
  font-weight: 900;
}
.manage-staff .display-info .action-btn {
  margin-left: 3px;
}
.manage-staff .staff-actions {
  margin-top: 20px;
}
.manage-staff .staff-actions .staff-action-btns {
  margin-bottom: 20px;
}
.manage-staff .staff-actions .staff-dropdown {
  position: relative;
}
.manage-staff .staff-actions .staff-dropdown .staff-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-staff .staff-actions .staff-dropdown .staff-list {
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-staff .staff-actions .staff-dropdown .staff-list .staff-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-staff .staff-actions .staff-dropdown .staff-list .staff-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-staff .staff-actions .staff-dropdown .staff-list .isSelected, .manage-staff .staff-actions .staff-dropdown .staff-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-staff .update-staff .update-staff-header span {
  font-weight: 900;
  font-size: 18px;
}
.manage-staff .update-staff-form, .manage-staff .create-staff-form {
  margin-top: 20px;
}
.manage-staff .update-staff-form .if-left-blank {
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}
.manage-staff .edit-field {
  margin-bottom: 7px;
}
.manage-staff .action-btn {
  height: 30px;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-staff .action-btn:hover {
  opacity: 0.85;
}

.registration-container {
  min-width: 925px;
  min-height: 578px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.registration-container .registration-wrapper .peerwell-logo-container img {
  margin-bottom: 20px;
  height: 38px;
  width: 259px;
  object-fit: cover;
}
.registration-container .registration-wrapper .registrant-email {
  margin-bottom: 22px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 900;
  color: #787878;
}
.registration-container .registration-wrapper .registration-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registration-container .registration-wrapper .registration-form .form-group {
  margin-bottom: 0;
}
.registration-container .registration-wrapper .registration-form .form-group .registration-field {
  border: 1px solid #B4B4B4;
  width: 250px;
  height: 40px;
  margin-top: 0;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 10px;
  color: #787878;
  margin-bottom: 10px;
}
.registration-container .registration-wrapper .registration-form .form-group .registration-field:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.registration-container .registration-wrapper .registration-form .form-group .registration-field.hidden {
  margin-bottom: 0;
}
.registration-container .registration-wrapper .registration-form .dummy-field {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.registration-container .registration-wrapper .registration-form .registration-button {
  margin-top: 10px;
  background: #6D778B;
  width: 250px;
  height: 40px;
  color: #ffffff;
  font-weight: 900;
  border-radius: 5px;
  border: none;
}
.registration-container .registration-wrapper .registration-form .registration-button:focus {
  outline: none;
}
.registration-container .registration-wrapper .registration-form .registration-button:hover {
  opacity: 0.9;
}
.registration-container .registration-wrapper .registration-form .password-requirements {
  position: absolute;
  visibility: hidden;
  top: -20px;
  left: 270px;
  width: 400px;
}
.registration-container .registration-wrapper .registration-form .password-requirements .requirement {
  display: flex;
  align-items: center;
}
.registration-container .registration-wrapper .registration-form .password-requirements .requirementSatisfied .checkmark {
  visibility: visible;
}
.registration-container .registration-wrapper .registration-form .password-requirements .requirementSatisfied .description {
  color: #61B4FF;
}
.registration-container .registration-wrapper .registration-form .password-requirements.asCareTeamUser {
  top: 20px;
}
.registration-container .registration-wrapper .registration-form .showPasswordRequirements {
  visibility: visible;
}
.registration-container .registration-wrapper .registration-form .hidePasswordRequirements .requirementSatisfied .checkmark {
  visibility: hidden;
}
.registration-container .registration-wrapper .registration-form .password-repeat-requirements {
  display: none;
}
.registration-container .registration-wrapper .registration-form .error-message {
  position: absolute;
  display: none;
}
.registration-container .registration-wrapper .registration-form .has-error {
  width: 250px;
  height: 40px;
}
.registration-container .registration-wrapper .registration-form .checkmark {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 12px;
  background: url("checkmark.svg") center center no-repeat #61B4FF;
  background-size: 14px;
  border: none;
  border-radius: 50%;
  visibility: hidden;
}
.registration-container .registration-wrapper .registration-form .disabled {
  background: #DCDCDC;
  cursor: not-allowed;
}
.registration-container .registration-wrapper .registration-form .disabled:hover {
  opacity: 1;
}

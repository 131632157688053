.release-form .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.release-form .form .notification {
  margin-top: 10px;
  font-weight: 900;
  text-align: center;
}
.release-form h1 {
  margin-top: 16px;
  margin-bottom: 18px;
}
.release-form .undo-release {
  color: rgb(180, 180, 180);
  font-weight: 500;
}
.release-form .btn {
  line-height: 40px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.release-form .btn-default {
  background-color: #B4B4B4 !important;
}

.portal-release .content-container {
  margin-top: 0 !important;
}
.portal-release .options {
  margin-left: 10px !important;
}
.portal-release .options input {
  margin-right: 10px !important;
}
.portal-release .options label {
  margin-right: 10px !important;
}
.portal-release .options .control-group:last-child label {
  margin-right: 0 !important;
}
.portal-release input[type=submit], .portal-release btn {
  width: 115px !important;
}

.portal-container .close-button {
  height: 26px;
  width: 26px;
  font-size: 18px;
  top: 20px;
  right: 12px;
}

.modal-panel {
  margin-top: 130px !important;
}

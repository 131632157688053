.chart-legend {
  display: inline-block;
  overflow: auto;
  margin-right: 20px;
}

.rv-discrete-color-legend-item {
  cursor: text !important;
  background: white !important;
  padding: 0;
  display: inline-block;
  margin-right: 20px;
}
.rv-discrete-color-legend-item span {
  display: inline-block;
  float: left;
}

.rv-discrete-color-legend {
  display: block !important;
  white-space: normal !important;
}

.rv-discrete-color-legend-item__color {
  display: block;
  float: left;
  width: 12px !important;
  height: 24px !important;
  margin-right: 5px;
  margin-top: 7px;
}

.chart-legend .description {
  font-size: 12px;
  color: #3a3a48;
  display: inline-block;
  margin-top: -5px;
  vertical-align: text-top;
  font-weight: bold;
}

.hasOneBubble {
  position: relative;
  width: 200px;
  display: block;
  height: 50px;
}
.hasOneBubble .rv-discrete-color-legend {
  position: absolute;
  top: 0;
  left: 0;
}
.hasOneBubble .description {
  position: absolute;
  top: 5px;
  left: 20px;
}
.hasOneBubble .rv-discrete-color-legend-item__title {
  display: none !important;
}

.rv-xy-plot {
  height: auto !important;
  margin-bottom: 40px !important;
}

.chart-container {
  width: 100%;
  padding: 20px;
  padding-left: 0;
  padding-top: 0;
  overflow: hidden;
}

.plot-container {
  width: 100%;
  overflow: hidden;
}

.allow-scroll-x .plot-container {
  overflow-x: scroll;
}

.goal {
  position: relative;
  margin-top: -14px;
  width: 100%;
}
.goal .lines {
  position: absolute;
  left: 60px;
  right: 22px;
  top: 12px;
  border-top: 3px dashed #5AD700;
  display: block;
}

.hasGoals .rv-xy-plot .rv-hint--horizontalAlign-right:last-child {
  right: -20px !important;
  left: -20px !important;
  z-index: 1000;
}

.annotation-value {
  background: #5AD700;
  display: block;
  position: absolute;
  right: 22px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  padding: 3px;
  font-size: 14px;
}

.annotation-value-surgery {
  background-color: #787878;
  right: -22px !important;
}

.rv-crosshair {
  top: 0 !important;
  z-index: 9999;
}

.rv-crosshair__line {
  background: #787878;
  width: 2px;
  height: 381px !important;
}

.customers {
  float: left;
}

.calendars {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.weekly-adoption-container {
  margin: 10px;
}

.weekly-adoption-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.profile-picture-container {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 38px;
  overflow: hidden;
  background: #ccc;
  float: right;
  margin-right: 40px;
  margin-top: -75px;
  cursor: pointer;
}

.profile-picture-edit-text {
  position: absolute;
  width: 75px;
  height: 75px;
  vertical-align: middle;
  line-height: 75px;
  text-align: center;
  z-index: 2;
  color: white;
  font-weight: bold;
  display: none;
}

.profile-picture-container:hover .profile-picture-edit-text {
  display: block;
}

.profile-picture-container img {
  position: absolute;
  width: 75px;
  height: 75px;
  z-index: 1;
}

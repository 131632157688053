.smaller-number h2 {
  font-size: 18px !important;
}

.simple-number {
  width: 100%;
  box-sizing: border-box;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.simple-number h2 {
  margin-bottom: 0;
  font-size: 30px;
}
.simple-number p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.simple-number-alert h2 {
  color: #DB5E4B;
}

.signin-container {
  width: 400px;
  margin: 75px auto;
  height: 500px;
  position: relative;
}

.forgot-password-container {
  width: 400px;
  margin: 75px auto;
  height: 500px;
}
.forgot-password-container .forgot-password-field {
  margin-top: 5px !important;
}
.forgot-password-container p {
  margin-bottom: 30px;
}
.forgot-password-container h1 {
  font-weight: 500;
  margin-bottom: 40px;
}

.login-container {
  min-width: 925px;
  min-height: 578px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container .login-wrapper .forgot-password, .login-container .login-wrapper .send-new-code {
  margin-top: 20px;
  font-size: 12px;
  color: #B4B4B4;
  text-decoration-line: underline;
}
.login-container .login-wrapper .send-new-code.isResending {
  color: #dfdfdf !important;
  cursor: not-allowed;
}
.login-container .login-wrapper .forgot-password:hover, .login-container .login-wrapper .send-new-code:hover {
  color: #7c7b7b;
}
.login-container .login-wrapper .new-code-sent {
  display: inline-block;
  margin-top: 20px;
  font-size: 12px;
  cursor: default;
}
.login-container .login-wrapper .login-error {
  margin-top: 15px;
  position: absolute;
  bottom: -35px;
  color: #DB5E4B;
}
.login-container .login-wrapper .mfa-header {
  margin-bottom: 30px;
  font-size: 18px;
}
.login-container .login-wrapper .peerwell-logo-container img {
  margin-bottom: 20px;
  height: 38px;
  width: 259px;
  object-fit: contain;
}
.login-container .login-wrapper .login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container .login-wrapper .login-form .form-group {
  margin-bottom: 0;
}
.login-container .login-wrapper .login-form .form-group .login-field {
  border: 1px solid #B4B4B4;
  width: 250px;
  height: 40px;
  margin-top: 0;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 10px;
  color: #787878 !important;
  margin-bottom: 10px;
}
.login-container .login-wrapper .login-form .form-group .login-field:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.login-container .login-wrapper .login-form .form-group .login-field.hidden {
  margin-bottom: 0;
}
.login-container .login-wrapper .login-form .login-button {
  margin-top: 15px;
  background: #6D778B;
  width: 250px;
  height: 40px;
  color: #ffffff;
  font-weight: 900;
  border-radius: 5px;
  border: none;
}
.login-container .login-wrapper .login-form .login-button:focus {
  outline: none;
}
.login-container .login-wrapper .login-form .login-button:hover {
  opacity: 0.9;
}
.login-container .login-wrapper .login-form .disabled {
  background: #DCDCDC;
  cursor: not-allowed;
}
.login-container .login-wrapper .login-form .disabled:hover {
  opacity: 1;
}

.confirm-outer-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  color: #6D778B;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-container {
  padding: 30px;
  border-radius: 10px;
  box-shadow: #8c8484 4px 4px 10px;
  text-align: center;
  background: white;
  max-width: fit-content;
}
.confirm-container .delete-button {
  display: block;
  margin: 27px auto 17px auto;
}
.confirm-container .go-back {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 900;
}
.confirm-container .go-back:hover {
  color: #61B4FF;
}
.confirm-container .subtext {
  font-weight: normal;
}

.no-metric-data {
  position: relative;
}
.no-metric-data h3 {
  margin-top: 180px;
}
.no-metric-data .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}
.no-metric-data .rv-xy-plot__series--linemark {
  display: none !important;
}
.no-metric-data .chart-container {
  opacity: 0.3;
  filter: blur(2px);
}
.no-metric-data .csv-download-button {
  pointer-events: none;
  cursor: not-allowed;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
.dashboard-as-default .navigation-bar {
  background: #5B6272;
}

.dashboard-as-owner .navigation-bar, .dashboard-as-care_team_user .navigation-bar {
  background: #A2ACBC;
}

.navigation-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  position: relative;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}
.navigation-bar .nav-pages-container {
  flex-grow: 1;
}
.navigation-bar .nav-pages-container .nav-pages {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page {
  padding-right: 22px;
  font-weight: 900;
  list-style: none;
  color: #FFFFFF;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page a, .navigation-bar .nav-pages-container .nav-pages .nav-page a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.navigation-bar .nav-pages-container .nav-pages .withChild a {
  padding-left: 15px;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page .isChild {
  padding-right: 0;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page .isChild a {
  color: #656565;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined {
  position: relative;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > a::after, .navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > span::after {
  content: "";
  position: absolute;
  background: #FFFFFF;
  display: block;
  height: 1px;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > a::after {
  width: calc(100% - 20px);
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > span::after {
  width: calc(100% - 45px);
}
.navigation-bar .help-link, .navigation-bar .help-link:hover {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  text-decoration: none;
}
.navigation-bar .help-link .question-circle, .navigation-bar .help-link:hover .question-circle {
  padding-right: 7px;
  margin-top: 2px;
}
.navigation-bar .help-link .help-text, .navigation-bar .help-link:hover .help-text {
  margin-top: 2px;
}
.navigation-bar .navigation-dropdown {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -20px;
  right: -40px;
  top: 34px !important;
  overflow: visible;
  min-width: unset;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.navigation-bar .navigation-dropdown li {
  font-size: 14px !important;
  color: #6D778B;
}
.navigation-bar .navigation-dropdown li a {
  font-weight: 510 !important;
  color: #6D778B;
}
.navigation-bar .account-options-container {
  position: relative;
  margin-right: 6px;
}
.navigation-bar .account-options-container .user-fullname {
  color: #FFFFFF;
  font-weight: 510;
  font-size: 14px;
  white-space: nowrap;
  vertical-align: middle;
}
.navigation-bar .account-options-container .account-options {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -20px;
  right: 0;
  top: 33px !important;
}
.navigation-bar .account-options-container .account-options li {
  font-size: 14px !important;
}
.navigation-bar .account-options-container .account-options li a {
  font-weight: 510 !important;
  color: #6D778B;
}
.navigation-bar .logo {
  flex-shrink: 0;
  width: 124px;
}
.navigation-bar .logo img {
  height: 100%;
  width: 100%;
}
.navigation-bar .partner-logo {
  max-width: 300px;
  height: 20px;
  margin-right: 40px;
}
.navigation-bar .nav-divider {
  height: 30px;
  border-left: 1px solid #A2ACBC;
  padding-right: 20px;
  margin-left: 20px;
}
.navigation-bar .main-loading-indicator {
  opacity: 0.5;
  width: 19px;
  height: 19px;
}
.navigation-bar .dropdown-toggle {
  color: #818DA8;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none !important;
}
.navigation-bar .dropdown-menu li:hover {
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.navigation-bar .dropdown-menu a:hover {
  background: none;
  text-decoration: none;
}
.navigation-bar .caret {
  color: #DCDCDC;
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: 8px;
}
.navigation-bar .open .caret {
  transform: rotate(180deg);
}
.navigation-bar .withChild {
  position: relative;
  overflow: visible;
  padding: 0;
}
.navigation-bar .withChild .submenu {
  list-style: none;
  position: absolute;
  left: 100%;
  background-color: #F7F8F7;
  top: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: none;
  padding: 0;
}
.navigation-bar .withChild .submenu li {
  width: 100%;
  padding: 5px 20px;
  white-space: nowrap;
}

html {
  margin: 0;
  padding: 0;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  color: #656565;
  font-family: Roboto;
  background-color: #fefefe;
}

button {
  font-family: Roboto;
}

a {
  color: #787878;
}

a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.page-container {
  min-width: 855px;
}

h1 {
  color: #656565;
  font-size: 30px;
  margin-top: 0;
  font-weight: 500;
}

.btn, .btn:hover, .btn:focus, .btn[disabled] {
  background-color: #61B4FF !important;
  border: none;
  line-height: 23px;
  height: 55px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 19px;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

.btn[disabled] {
  background-color: #B4B4B4 !important;
}

.btn-main {
  padding: 13px;
  vertical-align: middle;
  width: 100%;
  margin-top: 20px;
  display: block;
}

input[type=text], input[type=tel], input[type=password], .phone-control {
  background: none;
  font-size: 19px;
  height: 50px;
  padding: 10px 15px;
  border: 1px solid #B4B4B4;
  margin-top: 50px;
  font-weight: normal;
  color: #61B4FF;
  -webkit-appearance: none !important;
          appearance: none !important;
  box-shadow: none !important;
}

input:focus {
  outline: none !important;
  border: 1px solid #61B4FF;
}

input.has-error, input.has-error:focus {
  border: 1px solid #DB5E4B !important;
}

input[type=text]:disabled, input[type=password]:disabled {
  color: #656565;
}

/* do not group these rules (it will then fail in all browsers) */
*::-webkit-input-placeholder {
  color: #C4C4C4 !important;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #C4C4C4 !important;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #C4C4C4 !important;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #C4C4C4 !important;
}

a:hover {
  cursor: pointer;
  color: #61B4FF;
}

p, label {
  font-size: 19px;
  line-height: 23px;
  margin-bottom: 20px;
}

table a {
  color: #61B4FF;
}

.big-number {
  color: #61B4FF;
  font-size: 37px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.caret-up {
  transform: rotate(180deg);
}

.caret.right {
  transform: rotate(270deg);
}

.caret.left {
  transform: rotate(90deg);
}

@media screen {
  html, body {
    height: 100%;
    overflow-x: hidden;
  }
  .dashboard-layout .dropdown-menu {
    border-radius: 0;
    top: auto;
  }
  .dashboard-layout #topBars {
    height: 100px;
  }
  .dashboard-layout #root {
    min-height: calc(100% - 100px);
    position: relative;
  }
  .dashboard-layout #root > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .dashboard-layout .content-container, .dashboard-layout .page-container {
    min-height: 100%;
  }
  .dashboard-layout .page-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .dashboard-layout .page-container > div {
    min-height: 100%;
    margin: 0;
  }
  .dashboard-layout .page-container .with-sidebar {
    overflow: auto;
  }
  .dashboard-layout .limit-width {
    max-width: 1290px !important;
  }
  .dashboard-layout .sidebar {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    width: 200px;
    height: 100%;
    float: left;
    box-sizing: border-box;
    position: relative;
    background-color: #6D778B;
    overflow-y: scroll;
  }
  .dashboard-layout .sidebar-right {
    float: right;
    right: 0;
    z-index: 9999;
  }
  .dashboard-layout .sidebar p a {
    color: #61B4FF;
  }
  .dashboard-layout .page-content {
    box-sizing: border-box;
    float: left;
    padding-left: 35px;
    padding-top: 20px;
    width: calc(100% - 210px);
    padding-right: 35px;
    min-height: 100%;
  }
  .dashboard-layout h1, .dashboard-layout h2, .dashboard-layout h3, .dashboard-layout h4 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .dashboard-layout h2 {
    font-size: 25px;
  }
  .dashboard-layout p, .dashboard-layout table, .dashboard-layout li {
    font-size: 16px;
  }
  .dashboard-layout a {
    color: #656565;
  }
  .dashboard-layout a:hover {
    text-decoration: underline;
    color: #656565;
    opacity: 1;
  }
  .dashboard-layout .table thead th {
    font-weight: normal;
    border: none;
  }
  .dashboard-layout .table tbody tr {
    border: none;
  }
  .dashboard-layout .table .bold-first-column tr td:first-child, .dashboard-layout .table .bold-first-column tr th:first-child {
    font-weight: bold;
  }
  .dashboard-layout .single-row-table thead th {
    font-weight: normal;
    border: none;
    padding-bottom: 0;
    font-size: 12px;
    padding-top: 0;
  }
  .dashboard-layout .single-row-table thead th:first-child {
    padding-left: 0;
  }
  .dashboard-layout .single-row-table tbody td {
    font-weight: bold;
    border: none;
    padding-top: 0;
  }
  .dashboard-layout .single-row-table tbody td:first-child {
    padding-left: 0;
  }
  .dashboard-layout input[type=password], .dashboard-layout input[type=tel] {
    margin-top: 0;
    background: white;
  }
  .dashboard-layout .error-message {
    color: #DB5E4B;
  }
  .dashboard-layout .btn-sm {
    height: 30px;
    line-height: 16px;
    font-size: 14px;
  }
  .form .form-message {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .btn-danger, .btn-danger:hover {
    background: #DB5E4B !important;
  }
  .action-btn {
    min-width: 50px;
    border: none;
  }
  input[type=checkbox] {
    outline: 2px solid #61B4FF;
    margin-bottom: -10px !important;
    margin-top: 10px !important;
    -webkit-appearance: none;
            appearance: none;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 23px;
    box-sizing: border-box;
  }
  input[type=checkbox]:checked {
    background: url(/static/media/checkmark.424f7cd8.svg) center center no-repeat #61B4FF !important;
    background-size: 26px;
    border: none;
  }
  .no-breadcrumb #topBars {
    height: 50px;
  }
  .no-breadcrumb #root {
    min-height: calc(100% - 50px);
  }
  .no-breadcrumb .sidebar {
    height: calc(100% - 50px) !important;
  }
  .welcome-to-the-danger-zone #navigationBar .navigation-bar {
    background-color: #eb4511 !important;
    background-image: linear-gradient(315deg, #eb4511 0%, #b02e0c 74%) !important;
  }
}

@media print {
  @page {
    size: A4 !important;
    margin: 0 !important;
  }
  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    overflow: visible !important;
  }
  #navigationBar .nav-divider, #navigationBar .nav-pages-container, #navigationBar .nav-pages, #navigationBar .account-options-container {
    display: none;
  }
  .dashboard-layout #topBars {
    height: auto;
  }
  .SplitPane {
    display: block !important;
    height: auto !important;
    position: relative !important;
    overflow: visible !important;
  }
  .Pane {
    width: auto !important;
  }
  .Resizer {
    display: none !important;
  }
  #breadCrumbBar, .notifications-wrapper, #beacon-container, .MuiDivider-root, .user-actions, [alt=edit] {
    display: none;
  }
  .btn, .editable img, .user-basic img {
    display: none !important;
  }
  body {
    padding: 20px !important;
  }
  #navigationBar {
    position: relative;
    margin: 10px 0;
  }
  #navigationBar .logo {
    background: #888 !important;
    padding: 10px;
    border-radius: 5px;
    margin: 0 auto;
    float: none;
    font-size: 0 !important;
  }
  .welcome-to-the-danger-zone #navigationBar .navigation-bar {
    background: none !important;
  }
  .user-basic {
    page-break-after: always !important;
  }
  .user-basic h3 {
    font-size: 16px;
  }
  .user-basic h1 {
    font-size: 30px !important;
    overflow: hidden;
  }
  .user-basic h1, .user-basic h2, .user-basic h3, .user-basic h4 {
    color: #656565 !important;
  }
  .user-basic > div {
    width: 200px;
    border: 2px solid #ddd;
    border-radius: 15px;
    padding: 10px;
    float: left;
    height: 400px;
    margin: 10px 10px 10px 0;
  }
  .user-basic h4, .user-basic h2 {
    font-weight: bold;
    margin: 10px 0;
  }
  .data-set {
    margin: 0 !important;
  }
  .data-set .labels {
    margin-top: 0px !important;
    font-size: 16px;
    color: #656565;
  }
  .data-set p {
    color: #656565 !important;
    margin-bottom: 5px !important;
  }
  .Pane2 {
    clear: both;
  }
  .user-events {
    margin: 0 !important;
    padding: 30px 0 0 0 !important;
  }
  .user-events .neutral, .user-events .negative, .user-events .positive {
    margin-bottom: 10px !important;
    color: white !important;
  }
  .user-events li {
    border-radius: 10px !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .background-group {
    margin-top: 20px;
  }
  .background-group .data-set {
    margin-right: 10px !important;
  }
  .background-group .background-fields {
    margin-bottom: 20px;
  }
  .engagement-card-interaction-group {
    display: block !important;
  }
  .engagement-card-interaction-group .child-1, .engagement-card-interaction-group .child-2 {
    width: 800px;
    margin-left: 0 !important;
  }
  .engagement-card-interaction-group .card-interactions {
    float: none;
    margin-bottom: 30px;
  }
  .engagement-card-interaction-group .card-interactions .header-text {
    font-size: 30px;
  }
  .program-progress {
    clear: both;
  }
  .card-interactions .card-container {
    padding: 20px 0 0 0 !important;
    border: none !important;
    overflow-y: visible !important;
    background: none !important;
  }
  .no-metric-data .chart-container {
    display: none;
  }
  .no-metric-data .overlay {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    text-align: left !important;
  }
  .no-metric-data h3 {
    margin: 0 !important;
    font-size: 16px;
  }
  .colored-bar .background-color {
    background: #DBDCDB !important;
  }
  .colored-bar-positive .inner-colored-bar {
    background: #3CBF3D !important;
  }
  .colored-bar-neutral .inner-colored-bar {
    background: #FFBA31 !important;
  }
  .colored-bar-negative .inner-colored-bar {
    background: #DB5E4B !important;
  }
  .card-interactions .card-container .card-row .complete {
    background: url(/static/media/checkmark.dec0c9a8.svg) center center no-repeat #61B4FF !important;
  }
  .user-events .neutral {
    background: #FFBA31 !important;
  }
  .user-events .negative {
    background-color: #DB5E4B !important;
  }
  .user-events .positive {
    background-color: #3CBF3D !important;
  }
  .dashboard-layout .page-container, .SplitPane .sidebar, .SplitPane .page-content {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    top: auto !important;
    height: auto !important;
    overflow: visible !important;
    overflow-y: visible !important;
  }
  .sidebar {
    background: white !important;
  }
  div.user-basic.user-basic-invite > div:nth-child(7), div.user-basic.user-basic-invite > div:nth-child(8), div.user-basic.user-basic-invite > div:nth-child(9) {
    height: auto !important;
    padding-bottom: 20px !important;
    overflow: visible !important;
  }
  .user-events, .assessment-scores {
    page-break-before: always !important;
  }
}

.mfa-field-wrapper {
  position: relative;
}
.mfa-field-wrapper .mfa-field-container {
  display: flex;
  justify-content: center;
}
.mfa-field-wrapper .mfa-field-container .input-fake {
  position: relative;
  background: #fff;
  border: 2px solid #B4B4B4;
  margin: 0 2px;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.mfa-field-wrapper .mfa-field-container .input-fake .mfa-dash {
  position: absolute;
  font-size: 35px;
  color: #B4B4B4;
  left: 13px;
}
.mfa-field-wrapper .mfa-field-container .input-real {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  width: 330px;
  height: 42px;
  position: absolute;
  top: 2px;
  padding: 0 0 5px 13px;
  font-size: 30px;
  font-family: monospace;
  font-weight: bold;
  text-align: left;
  letter-spacing: 26px;
  background: transparent;
  color: #61B4FF;
  outline: none;
  border: 0;
}

.signin-container {
  width: 400px;
  margin: 75px auto;
  height: 500px;
  position: relative;
}

.forgot-password-container {
  width: 400px;
  margin: 75px auto;
  height: 500px;
}
.forgot-password-container .forgot-password-field {
  margin-top: 5px !important;
}
.forgot-password-container p {
  margin-bottom: 30px;
}
.forgot-password-container h1 {
  font-weight: 500;
  margin-bottom: 40px;
}

.login-container {
  min-width: 925px;
  min-height: 578px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container .login-wrapper .forgot-password, .login-container .login-wrapper .send-new-code {
  margin-top: 20px;
  font-size: 12px;
  color: #B4B4B4;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.login-container .login-wrapper .send-new-code.isResending {
  color: #dfdfdf !important;
  cursor: not-allowed;
}
.login-container .login-wrapper .forgot-password:hover, .login-container .login-wrapper .send-new-code:hover {
  color: #7c7b7b;
}
.login-container .login-wrapper .new-code-sent {
  display: inline-block;
  margin-top: 20px;
  font-size: 12px;
  cursor: default;
}
.login-container .login-wrapper .login-error {
  margin-top: 15px;
  position: absolute;
  bottom: -35px;
  color: #DB5E4B;
}
.login-container .login-wrapper .mfa-header {
  margin-bottom: 30px;
  font-size: 18px;
}
.login-container .login-wrapper .peerwell-logo-container img {
  margin-bottom: 20px;
  height: 38px;
  width: 259px;
  object-fit: contain;
}
.login-container .login-wrapper .login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container .login-wrapper .login-form .form-group {
  margin-bottom: 0;
}
.login-container .login-wrapper .login-form .form-group .login-field {
  border: 1px solid #B4B4B4;
  width: 250px;
  height: 40px;
  margin-top: 0;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 10px;
  color: #787878 !important;
  margin-bottom: 10px;
}
.login-container .login-wrapper .login-form .form-group .login-field:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.login-container .login-wrapper .login-form .form-group .login-field.hidden {
  margin-bottom: 0;
}
.login-container .login-wrapper .login-form .login-button {
  margin-top: 15px;
  background: #6D778B;
  width: 250px;
  height: 40px;
  color: #ffffff;
  font-weight: 900;
  border-radius: 5px;
  border: none;
}
.login-container .login-wrapper .login-form .login-button:focus {
  outline: none;
}
.login-container .login-wrapper .login-form .login-button:hover {
  opacity: 0.9;
}
.login-container .login-wrapper .login-form .disabled {
  background: #DCDCDC;
  cursor: not-allowed;
}
.login-container .login-wrapper .login-form .disabled:hover {
  opacity: 1;
}

.chart-legend {
  display: inline-block;
  overflow: auto;
  margin-right: 20px;
}

.rv-discrete-color-legend-item {
  cursor: text !important;
  background: white !important;
  padding: 0;
  display: inline-block;
  margin-right: 20px;
}
.rv-discrete-color-legend-item span {
  display: inline-block;
  float: left;
}

.rv-discrete-color-legend {
  display: block !important;
  white-space: normal !important;
}

.rv-discrete-color-legend-item__color {
  display: block;
  float: left;
  width: 12px !important;
  height: 24px !important;
  margin-right: 5px;
  margin-top: 7px;
}

.chart-legend .description {
  font-size: 12px;
  color: #3a3a48;
  display: inline-block;
  margin-top: -5px;
  vertical-align: text-top;
  font-weight: bold;
}

.hasOneBubble {
  position: relative;
  width: 200px;
  display: block;
  height: 50px;
}
.hasOneBubble .rv-discrete-color-legend {
  position: absolute;
  top: 0;
  left: 0;
}
.hasOneBubble .description {
  position: absolute;
  top: 5px;
  left: 20px;
}
.hasOneBubble .rv-discrete-color-legend-item__title {
  display: none !important;
}

.rv-xy-plot {
  height: auto !important;
  margin-bottom: 40px !important;
}

.chart-container {
  width: 100%;
  padding: 20px;
  padding-left: 0;
  padding-top: 0;
  overflow: hidden;
}

.plot-container {
  width: 100%;
  overflow: hidden;
}

.allow-scroll-x .plot-container {
  overflow-x: scroll;
}

.goal {
  position: relative;
  margin-top: -14px;
  width: 100%;
}
.goal .lines {
  position: absolute;
  left: 60px;
  right: 22px;
  top: 12px;
  border-top: 3px dashed #5AD700;
  display: block;
}

.hasGoals .rv-xy-plot .rv-hint--horizontalAlign-right:last-child {
  right: -20px !important;
  left: -20px !important;
  z-index: 1000;
}

.annotation-value {
  background: #5AD700;
  display: block;
  position: absolute;
  right: 22px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  padding: 3px;
  font-size: 14px;
}

.annotation-value-surgery {
  background-color: #787878;
  right: -22px !important;
}

.rv-crosshair {
  top: 0 !important;
  z-index: 9999;
}

.rv-crosshair__line {
  background: #787878;
  width: 2px;
  height: 381px !important;
}

.csv-download-button, .csv-download-button:hover, .csv-download-button:active, .csv-download-button:focus {
  font-size: 12px;
  line-height: 1.5;
  height: auto;
  margin-bottom: 5px;
  cursor: pointer !important;
  z-index: 999999;
  position: relative;
  float: right;
  background: none !important;
  padding: 3px;
  text-decoration: underline;
  color: #656565;
  border: none;
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
}

.smaller-number h2 {
  font-size: 18px !important;
}

.simple-number {
  width: 100%;
  box-sizing: border-box;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.simple-number h2 {
  margin-bottom: 0;
  font-size: 30px;
}
.simple-number p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.simple-number-alert h2 {
  color: #DB5E4B;
}

.breadcrumb-bar {
  width: 100%;
  background: white;
  border-bottom: 1px solid #DFDFDF !important;
  height: 50px;
  padding: 0 20px;
}
.breadcrumb-bar .caret {
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  color: #B4B4B4;
}
.breadcrumb-bar .breadcrumb-path {
  padding-top: 15px;
}
.breadcrumb-bar .breadcrumb-path a {
  color: #61B4FF;
}
.breadcrumb-bar .breadcrumb-menus {
  padding-left: 0;
  overflow: visible;
  margin-bottom: 0px;
}
.breadcrumb-bar .breadcrumb-menus .menu {
  display: block;
  float: left;
  margin-left: 0;
  padding-top: 15px;
  border-right: 1px solid #ddd;
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
}
.breadcrumb-bar .breadcrumb-menus .menu:first-child {
  padding-left: 0;
}

.breadcrumb-bar .menu {
  position: relative;
}
.breadcrumb-bar .open .caret {
  transform: rotate(180deg);
}
.breadcrumb-bar .dropdown {
  padding: 15px 0 0 0;
}
.breadcrumb-bar .dropdown-toggle {
  color: #61B4FF;
}
.breadcrumb-bar .dropdown-menu ul {
  max-height: 300px;
  overflow-y: scroll;
  top: 50px;
  margin-left: 0;
  padding-left: 0;
}
.breadcrumb-bar .dropdown-menu .form-control {
  margin-top: -5px !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  min-width: 300px;
}
.breadcrumb-bar .breadcrumb-menus .dropdown-menu {
  top: 47px;
  left: -21px;
}
.breadcrumb-bar .breadcrumb-menus .dropdown-menu li {
  padding: 6px 20px;
  cursor: pointer;
  display: block;
}
.breadcrumb-bar .breadcrumb-menus .dropdown-menu li:hover {
  text-decoration: underline;
}



.cards-charts-filter-container {
  padding: 8px 8px 0 0 !important;
  margin: 0 !important;
}
.cards-charts-filter-container .form-control, .cards-charts-filter-container .Select--single {
  width: 75% !important;
  float: left;
  box-sizing: border-box;
}
.cards-charts-filter-container label {
  padding-top: 4px;
  margin-right: 10px;
  float: left;
  box-sizing: border-box;
  margin-bottom: 0 !important;
}
.cards-charts-filter-container .col-md-4:first-child {
  padding-left: 0 !important;
}
.cards-charts-filter-container .col-md-4 {
  margin-bottom: 0 !important;
}

.pagination-container {
  display: flex;
  justify-content: center;
}
.pagination-container .caret.left, .pagination-container .caret.right {
  color: #4D5C7C !important;
  margin: 0 !important;
}
.pagination-container .caret.left.single-arrow {
  margin-right: 10px !important;
}
.pagination-container .caret.right.single-arrow {
  margin-left: 10px !important;
}
.pagination-container .min-page-arrows, .pagination-container .max-page-arrows {
  display: inline-flex;
}
.pagination-container .pagination .active {
  font-weight: bold;
}
.pagination-container .pagination li a {
  padding-left: 10px;
  padding-right: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  display: inline-block;
  color: #656565;
  font-weight: lighter;
  border: none;
}
.pagination-container .pagination li a:hover {
  text-decoration: underline;
  color: #212735;
  background: none;
}
.pagination-container .pagination li a:hover .caret {
  color: #212735 !important;
}
.pagination-container .pagination li a:focus-within {
  background: #f0ecec;
}

.user-search {
  position: relative;
  margin-top: -10px;
}
.user-search input {
  margin-top: 0;
  padding: 5px;
  height: auto;
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
}
.user-search .dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
  display: block;
  width: 100%;
}
.user-search .dropdown-menu li {
  font-size: 14px;
  padding: 5px;
}
.user-search .dropdown-menu li .email {
  display: block;
  font-size: 12px;
}
.user-search .dropdown-menu li span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-list {
  padding-top: 15px;
  position: relative;
  border-right: 1px solid #DEDFDE;
  height: 50px;
  padding-right: 20px;
}
.filter-list .dropdown-menu {
  overflow: visible;
  max-height: none;
  margin-top: 0;
  margin-left: -21px;
  border-top: 0;
  min-width: 250px;
}
.filter-list .filter-values-list {
  overflow-y: scroll;
  max-height: 300px;
}
.filter-list input {
  margin-top: 0;
  padding: 10px 5px;
  height: auto;
  width: 100%;
  font-size: 16px;
  border-color: #DEDFDE;
}
.filter-list input {
  border-top: none;
  border-left: 0;
  border-right: 0;
  margin-bottom: 5px;
}
.filter-list ul {
  padding: 3px 5px;
  margin: 0;
}
.filter-list li {
  list-style: none;
  padding: 5px;
}
.filter-list li a {
  display: block;
  width: 100%;
  height: 100%;
}
.filter-list .caret {
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: 10px;
}
.filter-list .open .caret {
  transform: rotate(180deg);
}
.filter-list .active {
  color: #61B4FF;
}

.filter-dropdown-button {
  padding: 10px;
}

.filter-list {
  float: left;
  margin-right: 20px;
}

.search-box {
  margin-top: 10px;
  width: 200px;
  float: right;
}

.users-table .warning {
  color: red;
}

.user-view .warning {
  color: #DB5E4B;
}

.user-events {
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -80px;
}
.user-events li {
  display: block;
  width: calc(100% + 35px);
  box-sizing: border-box;
  color: white;
  background-color: #FFBA31;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  font-weight: lighter;
  padding: 0 20px;
}
.user-events li.neutral {
  background-color: #FFBA31;
}
.user-events li.negative {
  background-color: #DB5E4B;
}
.user-events li.positive {
  background-color: #3CBF3D;
}

.SplitPane .sidebar, .SplitPane .page-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: calc(100vh - 100px) !important;
  overflow-y: scroll;
}

.engagement-card-interaction-group {
  display: flex;
  justify-content: space-evenly;
}
.engagement-card-interaction-group .child-1 {
  flex: 1 1;
  margin-right: 30px;
}
.engagement-card-interaction-group .child-2 {
  flex: 1 1;
  margin-left: 30px;
}

.external-service-block {
  padding-bottom: 25px;
}

@media screen {
  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
  .Resizer:hover {
    transition: all 2s ease;
  }
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
  .SplitPane .sidebar, .SplitPane .page-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: calc(100vh - 100px) !important;
    overflow-y: scroll;
  }
}

.data-set {
  overflow: hidden;
  white-space: wrap;
  margin-right: 20px;
}
.data-set h3 {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 2px;
}
.data-set p {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  overflow: hidden;
  white-space: wrap;
  word-wrap: break-word;
}

.data-set.editable p {
  cursor: pointer;
}

.labels {
  color: #C4CEE4;
  padding-top: 5px;
}

.data-set.email p, .data-set.phone p, .data-set.background p {
  color: #61B4FF;
}

.data-set.background p {
  word-wrap: normal;
  overflow: visible;
}

.data-set.background h3, .data-set.comorbidities h3 {
  color: grey;
}
.data-set.background .comorbidities, .data-set.comorbidities .comorbidities {
  color: #61B4FF;
}

.portal-container {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.portal-container .background-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
}
.portal-container .modal-panel {
  background: #FAFAFA;
  position: relative;
  z-index: 9999;
  width: 30%;
  min-width: 400px;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.portal-container .modal-panel .content-container {
  box-sizing: border-box;
  margin-top: 30px;
}
.portal-container .modal-panel-with-width {
  min-width: auto;
}
.portal-container .close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #61B4FE;
  font-size: 20px;
}
.portal-container .content-container {
  padding-bottom: 20px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
  background: #FFFFFF;
}

.react-datepicker {
  color: #656565;
  font-size: 1em;
}
.react-datepicker .react-datepicker__day--keyboard-selected {
  background: none;
}
.react-datepicker .react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker .react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker .react-datepicker__day-name, .react-datepicker .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker .react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker .react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker .react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.react-datepicker .react-datepicker__month-read-view, .react-datepicker .react-datepicker__year-read-view {
  font-weight: bold;
  visibility: visible !important;
}
.react-datepicker .react-datepicker__year-read-view--down-arrow, .react-datepicker .react-datepicker__month-read-view--down-arrow {
  border-top-color: #61B4FF !important;
  display: block;
  margin-left: 5px;
}
.react-datepicker .react-datepicker__month-read-view--selected-month, .react-datepicker .react-datepicker__year-read-view--selected-year {
  padding-right: 13px;
}
.react-datepicker .react-datepicker__navigation--next {
  border-left-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__year-option--selected {
  color: #61B4FF !important;
}
.react-datepicker .react-datepicker__year-dropdown, .react-datepicker .react-datepicker__month-dropdown {
  background: white;
}
.react-datepicker .react-datepicker__year-option:hover, .react-datepicker .react-datepicker__month-option:hover {
  background: #f1f1f1 !important;
}
.react-datepicker .react-datepicker__day {
  color: #656565 !important;
}
.react-datepicker .react-datepicker__navigation--years-previous {
  top: 3px !important;
  border-top-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__navigation--years-upcoming {
  top: 0 !important;
  border-bottom-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__day--selected {
  background-color: #61B4FF;
  color: white !important;
}
.react-datepicker .react-datepicker__day--disabled {
  background: #efefef !important;
  cursor: not-allowed;
  opacity: 0.4;
}
.react-datepicker .react-datepicker__current-month {
  color: #656565;
}
.react-datepicker .react-datepicker__day--disabled.react-datepicker__day--selected {
  color: #656565 !important;
  font-weight: bold;
}

.has-error .react-datepicker {
  border-color: #DB5E4B !important;
}

.withDropDowns .react-datepicker .react-datepicker__current-month {
  display: none;
}

.centered {
  text-align: center;
}

.input-container label {
  display: block;
}

.wide-calendar {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.wide-calendar .react-datepicker, .wide-calendar .react-datepicker__header, .wide-calendar .react-datepicker__month-container, .wide-calendar .react-datepicker__month {
  width: 100%;
  margin: 0;
}
.wide-calendar .react-datepicker {
  border-radius: 0 !important;
}
.wide-calendar .react-datepicker__day-name, .wide-calendar .react-datepicker__day {
  box-sizing: border-box;
  width: 14.2857142857%;
  margin: 0 !important;
}
.wide-calendar .react-datepicker__week .react-datepicker__day:last-child {
  border-right: 0 !important;
}
.wide-calendar .react-datepicker__week .react-datepicker__day:first-child {
  border-left: 0 !important;
}
.wide-calendar .react-datepicker__day {
  border-bottom: 0px !important;
  border-radius: 0 !important;
}
.wide-calendar .react-datepicker__day {
  border: 1px solid #ADAEAD;
  border-right: 0;
}
.wide-calendar .react-datepicker__header {
  border-bottom: 0 !important;
}

.has-error .wide-calendar {
  margin-bottom: 0 !important;
}
.has-error .error-message {
  margin-bottom: 20px;
}

.disabled-calendar {
  pointer-events: none;
  opacity: 0.5;
}

.optional-date-container {
  margin-top: -25px;
  margin-bottom: 15px;
}
.optional-date-container label {
  margin-left: 10px;
}

.dateInputField p {
  font-size: 15px;
  font-style: italic;
}

.jumbotron .dateInputField label {
  margin-bottom: 5px;
}

.has-error {
  border-color: #DB5E4B !important;
}

.error-message {
  margin-top: 3px;
  display: inline-block;
  color: #DB5E4B !important;
}

.form-group label {
  margin-bottom: 5px;
  margin-top: 10px;
}

.form-group:first-child label {
  margin-top: 0;
}

.flex .form-group:first-child label {
  margin-top: 10px;
}

.animation-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.animation-group .success-message {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
}

.update-care-team-member-form {
  text-align: center;
}
.update-care-team-member-form .member-info {
  margin-top: 5px;
}
.update-care-team-member-form .member-info, .update-care-team-member-form .member-job {
  text-align: left;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.update-care-team-member-form .member-info h3, .update-care-team-member-form .member-job h3 {
  margin-bottom: 15px;
}
.update-care-team-member-form .member-info label, .update-care-team-member-form .member-info h3, .update-care-team-member-form .member-job label, .update-care-team-member-form .member-job h3 {
  font-size: 12px;
  font-weight: normal;
  color: #787878;
}
.update-care-team-member-form .member-info select, .update-care-team-member-form .member-info p, .update-care-team-member-form .member-job select, .update-care-team-member-form .member-job p {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 20px;
}
.update-care-team-member-form .btn {
  width: 40%;
  margin-top: 25px;
}
.update-care-team-member-form .animation-group {
  flex-direction: initial;
}
.update-care-team-member-form .animation-group div {
  margin: 0 10px 0 0 !important;
}

.remove-care-team {
  font-weight: 900;
}
.remove-care-team h1 {
  font-size: 24px;
}
.remove-care-team p {
  font-size: 14px;
  color: #787878;
}
.remove-care-team .btn-container {
  display: flex;
  justify-content: space-between;
}
.remove-care-team .btn {
  height: 40px;
  width: 125px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 900;
}

.user-basic h1 {
  font-size: 24px;
  color: white;
}
.user-basic .patient-info-header {
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
}
.user-basic .data-set p {
  margin-bottom: 15px;
}
.user-basic .data-set.owner p {
  margin-bottom: 25px;
}

.user-basic-invite .procedure p, .user-basic-invite .birthday p {
  color: #DB5E4B;
}

.careteam-table {
  margin-top: 5px;
  width: 100%;
}
.careteam-table td {
  display: inline-block;
}
.careteam-table .careteam-data {
  width: 90%;
}
.careteam-table .data-set:hover {
  text-decoration: underline;
}
.careteam-table .close-button:hover {
  color: #FFFFFF;
}

.archive-container {
  margin-top: -10px;
  display: flex;
}
.archive-container p {
  margin-left: 10px;
  margin-top: 10px;
}

.archived-container input[type=checkbox] {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

.confirm-outer-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  color: #6D778B;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-container {
  padding: 30px;
  border-radius: 10px;
  box-shadow: #8c8484 4px 4px 10px;
  text-align: center;
  background: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.confirm-container .delete-button {
  display: block;
  margin: 27px auto 17px auto;
}
.confirm-container .go-back {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 900;
}
.confirm-container .go-back:hover {
  color: #61B4FF;
}
.confirm-container .subtext {
  font-weight: normal;
}

.arc-container {
  overflow: auto;
  margin-top: 15px;
}
.arc-container .arc {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 15px;
}
.arc-container svg {
  width: 100%;
  height: 100%;
  margin: 0;
}
.arc-container p {
  margin-top: 40px;
}

.program-progress {
  margin-bottom: 30px;
}

.physical-measurements h1 {
  margin-top: 40px;
  margin-bottom: 50px;
}
.physical-measurements .row {
  padding-bottom: 30px;
}

.rv-crosshair__inner--right {
  left: -4px;
}

.profile-chart .detailedInfo .bubbleContainer .day {
  padding-top: 10px !important;
}

.barContainer .labels {
  color: #6f6f6f;
}

.profile-chart {
  overflow: hidden !important;
}
.profile-chart h1 {
  font-weight: bold;
}
.profile-chart h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}
.profile-chart .rv-hint {
  left: 0 !important;
}
.profile-chart .header {
  overflow: auto;
}
.profile-chart .image {
  float: right;
  width: 100px;
}
.profile-chart .best-value {
  float: left;
}
.profile-chart .rv-xy-plot__axis__tick__text {
  font-size: 16px;
  color: #989998;
}
.profile-chart .goal {
  position: relative;
  margin-top: -14px;
  width: 510px;
}
.profile-chart .goal .lines {
  position: absolute;
  left: 60px;
  right: 22px;
  top: 12px;
  border-top: 3px dashed #5AD700;
  display: block;
}
.profile-chart .annotation-value {
  background: #5AD700;
  display: block;
  position: absolute;
  right: 22px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  padding: 3px;
  font-size: 14px;
}
.profile-chart .rv-crosshair__line {
  background: #787878;
  width: 2px;
  margin-left: -2px;
  padding-bottom: 14px;
  box-sizing: content-box;
  height: 217px !important;
}
.profile-chart .rv-crosshair {
  /* http://www.csstriangle.com  I have no idea how this somehow works.... */
}
.profile-chart .rv-crosshair .lineArrow {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10.4px 6px 0 6px;
  border-color: rgb(120, 120, 120) transparent transparent transparent;
  position: absolute;
  left: -3px;
  top: -8px;
}
.profile-chart .rv-crosshair .lineLabel {
  font-size: 18px;
  position: absolute;
  width: 200px;
  left: -105px;
  text-align: center;
  top: -35px;
  color: #787878 !important;
}
.profile-chart .rv-crosshair .annotation-value {
  background: #FF395A;
  top: 240px;
  word-break: keep-all;
  white-space: nowrap;
  text-transform: uppercase;
  margin-right: -53px;
}
.profile-chart .bottom-axis {
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  color: #656565;
  position: relative;
  height: 20px;
  font-size: 16px;
}
.profile-chart .bottom-axis span {
  position: absolute;
  display: block;
}
.profile-chart .btn {
  width: 100%;
  margin-top: 30px;
}
.profile-chart .rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick__text {
  text-anchor: start !important;
}
.profile-chart .rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick:last-child .rv-xy-plot__axis__tick__text {
  text-anchor: end !important;
}
.profile-chart .legenda {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 50px;
}
.profile-chart .legenda-item {
  float: left;
  display: inline-block;
  margin-right: 14px;
  padding-left: 0;
  font-size: 18px;
  font-weight: 300;
  color: #787878;
}
.profile-chart .legenda-item .color {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.profile-chart .detailedInfo {
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 180px;
  clear: both;
}
.profile-chart .detailedInfo .goodBubble {
  background: url(/static/media/goodBubble.51c7e407.svg) no-repeat;
}
.profile-chart .detailedInfo .badBubble {
  background: url(/static/media/badBubble.a091b441.svg) no-repeat;
}
.profile-chart .detailedInfo .bubble {
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 190px;
  height: 190px;
}
.profile-chart .detailedInfo .prehabBubble {
  border-radius: 95px;
  background-color: #61B4FF;
  width: 170px;
  height: 170px;
}
.profile-chart .detailedInfo .prehabBubbleContainer .day {
  padding-top: 45px !important;
}
.profile-chart .detailedInfo .bubbleText {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 170px;
  box-sizing: border-box;
}
.profile-chart .detailedInfo .bubbleContainer {
  width: 170px;
  height: 170px;
  box-sizing: border-box;
  position: relative;
  color: white;
  text-align: center;
  float: left;
}
.profile-chart .detailedInfo .bubbleContainer .day {
  font-weight: bold;
  font-size: 20px;
  padding-top: 30px;
}
.profile-chart .detailedInfo .bubbleContainer .value {
  font-size: 54px;
  font-weight: bold;
  padding-top: 20px;
}
.profile-chart .detailedInfo .bubbleContainer .result {
  font-size: 24px;
  font-weight: bold;
  padding-top: 20px;
}
.profile-chart .detailedInfo .barContainer {
  float: left;
  margin-left: 30px;
  width: 200px;
  height: 170px;
  position: relative;
}
.profile-chart .detailedInfo .barContainer .bar {
  width: 30px;
  height: 100%;
  position: relative;
  overflow: visible;
}
.profile-chart .detailedInfo .barContainer .roundedBar {
  width: 30px;
  height: 100%;
  background: #DCDCDC;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.profile-chart .detailedInfo .barContainer .mainColor, .profile-chart .detailedInfo .barContainer .maskColor {
  width: 100%;
  position: absolute;
}
.profile-chart .detailedInfo .topValue, .profile-chart .detailedInfo .bottomValue {
  position: absolute;
  left: 50px;
  top: 10px;
}
.profile-chart .detailedInfo .line {
  display: inline-block;
  height: 1px;
  background: #979797;
  width: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.profile-chart .detailedInfo .bottomValue {
  top: auto;
  bottom: 0;
}
.profile-chart .detailedInfo .centerLabel {
  position: absolute;
  left: 50px;
  top: calc(50% - 23px);
}
.profile-chart .detailedInfo .peep {
  width: 50px;
  height: 35px;
  margin-top: -15px;
  left: -10px;
  background: url(/static/media/sad.5af8c733.svg) no-repeat;
  position: absolute;
}
.profile-chart .detailedInfo .happyPeep {
  background: url(/static/media/happy.f9b46857.svg) no-repeat;
}
.profile-chart .isOutOfDate {
  padding-top: 10px;
}
.profile-chart .isOutOfDate .icon {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  color: white;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
  background-color: #FFBA31;
  float: left;
  margin-right: 25px;
  margin-bottom: 35px;
}
.profile-chart .isOutOfDate .content {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.profile-chart .isOutOfDate .content p {
  font-size: 16px;
  margin-bottom: 12px;
}
.profile-chart .isOutOfDate .content .days {
  margin-top: 5px;
  font-size: 18.5px;
  color: #FFBA31;
}
.profile-chart .outro {
  background-color: #F0F0F0;
  border: 1px solid #B4B4B4;
  border-radius: 10px;
  padding: 14px 22px;
  margin-top: 20px;
  overflow: auto;
  clear: both;
}
.profile-chart .outro p, .profile-chart .outro h3 {
  margin: 0;
  padding: 0;
}
.profile-chart .outro h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.profile-chart .outro .warning {
  font-weight: bold;
  color: #E9403E;
  margin-bottom: 12px;
}

.profile-locked {
  position: relative;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}
.profile-locked .locked-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  right: 0;
  text-align: center;
}
.profile-locked .chart-container {
  height: 540px;
  padding: 20px;
}
.profile-locked .locked-message {
  position: absolute;
  margin: 260px 20px 0 20px;
  background: white;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #aaa;
}
.profile-locked .blur-container {
  overflow: hidden;
}
.profile-locked .blur-darker {
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  width: 110% !important;
  margin-left: -5% !important;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 20px;
  position: relative;
  z-index: 5;
}

.no-metric-data {
  position: relative;
}
.no-metric-data h3 {
  margin-top: 180px;
}
.no-metric-data .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}
.no-metric-data .rv-xy-plot__series--linemark {
  display: none !important;
}
.no-metric-data .chart-container {
  opacity: 0.3;
  filter: blur(2px);
}
.no-metric-data .csv-download-button {
  pointer-events: none;
  cursor: not-allowed;
}

.assessment-score {
  background: white;
  border-radius: 10px;
  flex: 0 0 33.333333%;
  margin: 10px;
  box-sizing: border-box;
  list-style: none;
  box-shadow: #dfdfe7 3px 3px 5px;
  cursor: pointer;
}
.assessment-score h2 {
  background: #61B4FF;
  color: white;
  padding: 6px 12px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
}

.assessment-table tr td:first-child {
  padding-left: 12px;
}
.assessment-table tr td:last-child {
  padding-right: 12px;
}
.assessment-table tr:nth-child(even) {
  background-color: #e0e0e0;
}
.assessment-table tr:nth-child(odd) {
  background-color: white;
}

.assessment-score:hover {
  box-shadow: #c1c1c7 3px 3px 5px;
}

.assessment-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
.assessment-overlay .container {
  margin: 200px auto 0;
  width: 600px;
  border-radius: 15px;
  background: white;
  padding: 0;
  overflow-y: scroll;
  height: 600px;
  cursor: auto;
}
.assessment-overlay .container h1 {
  background: #61B4FF;
  color: white;
  padding: 6px 12px;
  width: 100%;
}
.assessment-overlay .container h3 {
  margin-left: 12px;
  margin-top: 20px;
}
.assessment-overlay .container .questions, .assessment-overlay .container .results {
  list-style: none;
  margin: 0 0 0 12px;
  padding: 0;
}
.assessment-overlay .container .questions .assessment-label, .assessment-overlay .container .results .assessment-label {
  margin-bottom: 0;
  color: #6D778B;
}
.assessment-overlay .container .questions .assessment-value, .assessment-overlay .container .results .assessment-value {
  font-weight: bold;
}
.assessment-overlay .container .no-results {
  margin-left: 16px;
}

.assessment-container {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.card-interactions {
  float: right;
}
.card-interactions .header-text {
  font-size: 21px;
  margin-bottom: 6px;
}
.card-interactions .cardContainerEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 308px;
}
.card-interactions .cardContainerEmpty .no-cards {
  font-size: 18px;
  color: grey;
  font-weight: 900;
}
.card-interactions .cardContainerWithCards {
  height: 308px;
}
.card-interactions .card-container {
  padding: 20px 20px 0px 20px;
  width: 388px;
  border: 1px solid #C4CEE4;
  border-radius: 6px;
  background: #F8FAFF;
  font-size: 14px;
  overflow-y: scroll;
}
.card-interactions .card-container a:hover {
  text-decoration: none;
}
.card-interactions .card-container .card-row {
  display: flex;
}
.card-interactions .card-container .card-row .complete {
  height: 26px;
  min-width: 26px;
  margin-right: 12px;
  background: url(/static/media/checkmark.dec0c9a8.svg) center center no-repeat #61B4FF;
  background-size: 15px;
  border: none;
  border-radius: 50%;
}
.card-interactions .card-container .card-row .incomplete {
  margin-right: 12px;
  height: 26px;
  width: 26px;
}
.card-interactions .card-container .card-row .incomplete:hover {
  cursor: auto;
}
.card-interactions .card-container .card-row .cardActivityComplete {
  font-size: 14px;
  font-weight: 900;
  color: #B4B4B4;
}
.card-interactions .card-container .card-row .cardActivityComplete:hover {
  cursor: pointer;
}
.card-interactions .card-container .card-row .cardActivityIncomplete {
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
}
.card-interactions .card-container .card-row .cardActivityComplete:hover {
  cursor: pointer;
}

.preview-col {
  padding: 20px 15px;
}

.preview {
  position: relative;
  background-color: #eeeeee;
  border: 1px solid grey;
  padding: 25px 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}
.preview img {
  width: 100%;
  margin-bottom: 10px;
}

.scaledPreview {
  min-height: 330px;
}

.scaledPreviewInModal {
  position: relative;
  max-height: 550px;
  margin-right: 15px;
  overflow: auto;
}

.divider {
  position: absolute;
  top: 812px;
  left: 4px;
  right: 0;
  height: 1px;
  opacity: 0.5;
  border-bottom: 2px dashed black;
}

.wrapper {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-top: 10pt;
  margin-bottom: 10pt;
}

.wrapper:after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.home-assessment {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-assessment .btn {
  box-sizing: border-box;
  margin-bottom: 25px;
  width: 150px;
}

.home-assessment h1 {
  align-self: flex-start;
}

.box {
  width: 100%;
  height: 150px;
  background: #F4F4F4;
  border: 1px solid gray;
  margin-bottom: 2%;
  padding: 0 2%;
}

.home-score {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.numberCircle {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 12px;
  background: #b74344;
  color: #fff;
  text-align: center;
  font: 65px Arial, sans-serif;
  font-weight: bold;
  margin-right: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding-left: 10px;
}

.column {
  float: left;
  width: 200px;
  height: 200px;
  padding: 10px 5px;
}

.column img {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid gray;
}

.home-assessment {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-assessment .btn {
  box-sizing: border-box;
  margin-bottom: 25px;
  width: 150px;
}

.home-assessment h1 {
  align-self: flex-start;
}

.box {
  width: 100%;
  height: 150px;
  background: #F4F4F4;
  border: 1px solid gray;
  margin-bottom: 10px;
  padding: 0 20px;
}

.home-score {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.numberCircle {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  padding: 15px 30px;
  background: #b74344;
  color: #fff;
  text-align: center;
  font: 65px Arial, sans-serif;
  font-weight: bold;
  margin-right: 20px;
}

.label_title {
  padding-top: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  margin-bottom: 10px;
}

.column {
  float: left;
  width: 200px;
  height: 200px;
  padding: 10px 5px;
  margin-bottom: 25px;
}

.column img {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid gray;
}

.background-group {
  overflow: auto;
}

.background-fields {
  display: flex;
  justify-content: start;
}

.comorbidities {
  grid-column-start: 4;
  grid-row-start: 1;
}

.engagement {
  margin-bottom: 40px;
  white-space: wrap;
}

.colored-bar {
  width: 100%;
}
.colored-bar .background-color {
  background: #DBDCDB;
  height: 35px;
}
.colored-bar .inner-colored-bar {
  background: #6CCC58;
  height: 100%;
  width: 100%;
}
.colored-bar .sections {
  table-layout: fixed;
  width: 100%;
  margin-top: 10px;
}
.colored-bar .sections td {
  text-align: center;
}
.colored-bar .sections td:last-child {
  text-align: right;
}
.colored-bar .sections td:first-child {
  text-align: left;
}

.colored-bar-positive .inner-colored-bar {
  background: #3CBF3D;
}

.colored-bar-neutral .inner-colored-bar {
  background: #FFBA31;
}

.colored-bar-negative .inner-colored-bar {
  background: #DB5E4B;
}

.CardInteractionsWorker_card-interactions-worker__1xC-E {
  width: 100%;
}
.CardInteractionsWorker_card-interactions-worker__1xC-E ul {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  list-style: none;
  margin: 0;
  padding: 20px;
  max-height: 500px;
  overflow-y: scroll;
}

.EventItem_event-item__3x90G {
  margin: 8px 0;
}
.EventItem_event-item__3x90G .EventItem_event-item-button__1NCvz {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
  padding: 15px 20px;
  text-align: left;
  width: 100%;
}
.EventItem_event-item__3x90G .EventItem_event-item-button__1NCvz:hover {
  background: rgba(255, 255, 255, 0.3);
}
.EventItem_event-item__3x90G .EventItem_checkmark__3ZGlV {
  width: 30px;
  height: 30px;
  margin: 10px 15px 10px 0px;
  border-radius: 50%;
  border: 2px solid rgb(65, 190, 15);
  flex-shrink: 0;
}
.EventItem_event-item__3x90G .EventItem_checkmark__3ZGlV.EventItem_completed__37Klr {
  background: rgb(65, 190, 15);
}
.EventItem_event-item__3x90G .EventItem_title__ZSjLi {
  font-size: 20px;
  margin: 0 0 5px 0;
}
.EventItem_event-item__3x90G .EventItem_title__ZSjLi.EventItem_completed__37Klr {
  text-decoration: line-through;
}
.EventItem_event-item__3x90G .EventItem_subtext__3_jOq {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
}

.WorkerView_first-row__12oYk {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  grid-template-rows: 1fr;
  grid-column-gap: 80px;
}

.autocomplete-form-control {
  padding: 6px 12px !important;
  font-size: 14px !important;
  margin-top: 0 !important;
  height: 35px !important;
}

.react-autosuggest__suggestions-list {
  max-height: 250px;
  overflow-y: scroll;
  display: inline-block;
  border: 1px solid #B4B4B4;
  padding: 0;
  margin: 0;
}
.react-autosuggest__suggestions-list li {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0;
  width: auto;
}
.react-autosuggest__suggestions-list li:hover, .react-autosuggest__suggestions-list .react-autosuggest__suggestion--highlighted {
  background-color: #61B4FF;
  color: white;
}

.drilldown-select .clickable {
  cursor: pointer;
}
.drilldown-select .form-control {
  padding: 0;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.drilldown-select .form-control .filterField {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  display: block;
  width: 100%;
  padding: 0 20px;
  position: relative;
  margin-top: 0;
  border: 0;
}
.drilldown-select .dropdown {
  background: white;
  border: 1px solid #CCCCCC;
  list-style: none;
  position: relative;
  padding: 0;
  display: none;
  z-index: 9999;
}
.drilldown-select .dropdown .parent, .drilldown-select .dropdown .submenu li {
  padding: 5px 20px;
  display: block;
  white-space: nowrap;
}
.drilldown-select .dropdown .parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drilldown-select .dropdown .parent .caret {
  display: none;
  border-left: 6px dashed;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  padding-right: 0;
}
.drilldown-select .dropdown .parent:hover .caret {
  display: inline;
}
.drilldown-select .dropdown .withChild:hover .parent, .drilldown-select .dropdown .submenu li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.drilldown-select .dropdown .withChild {
  position: relative;
}
.drilldown-select .dropdown .submenu {
  display: none;
  position: absolute;
  left: 100%;
  background: white;
  list-style: none;
  top: -1px;
  border: 1px solid #CCCCCC;
  padding: 0;
}
.drilldown-select .dropdown .withChild:hover .submenu, .drilldown-select .dropdown .withChild.hasMatchedChild:first-of-type .submenu {
  display: block;
}
.drilldown-select .dropdown:hover .withChild.hasMatchedChild:first-of-type .submenu {
  display: none;
}
.drilldown-select .dropdown:hover .withChild.hasMatchedChild:first-of-type:hover .submenu {
  display: block;
}
.drilldown-select .isOpen .dropdown {
  display: block;
}

.checkbox-form-control {
  overflow: auto;
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  padding: 0;
}
.checkbox-form-control label, .checkbox-form-control input {
  display: inline-block;
  position: absolute;
}
.checkbox-form-control input {
  top: -10px;
  left: 0;
}
.checkbox-form-control label {
  top: 10px;
  left: 60px;
}

.pick-date-profile {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}
.pick-date-profile .calendar-container {
  display: flex;
  align-items: flex-start;
}
.pick-date-profile .current-date {
  padding: 0;
  margin: 0;
  font-weight: lighter;
  font-size: 18px;
  color: #868786;
}
.pick-date-profile .noDateSet {
  color: #61B4FF;
}
.pick-date-profile .no-date-group {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 25px;
}
.pick-date-profile .no-date-group .no-date-label {
  font-size: 15px;
  color: #868786;
  font-weight: lighter;
  margin: 0;
}
.pick-date-profile .no-date-group .no-date-checkbox {
  margin: 0px !important;
}
.pick-date-profile .change-date-button {
  display: inline-block;
  width: 100px;
  margin: 5px 0 0 0;
}
.pick-date-profile .change-date-button:focus {
  outline: 0;
}
.pick-date-profile button[disabled] {
  background-color: #99cfff !important;
}
.pick-date-profile .error {
  padding: 0;
  margin: 3px 0;
  font-size: 14px;
}

.add-care-team h1 {
  margin-bottom: 20px;
}
.add-care-team .form {
  display: none;
}
.add-care-team .form-control {
  margin-bottom: 20px;
  height: 40px;
  font-weight: 900;
  color: #656565;
}
.add-care-team .data-set {
  margin: 0;
  padding-left: 12px;
}
.add-care-team .data-set p {
  color: #787878;
}
.add-care-team .placeholder {
  color: #C8C8C8;
}
.add-care-team .apiError {
  color: #DB5E4B;
  margin-left: 15px;
  font-size: 12px;
}
.add-care-team .btn {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.add-care-team .animation-group {
  flex-direction: initial;
}
.add-care-team .animation-group div {
  margin: 0 10px 0 0 !important;
}

.update-owner .owner-dropdown {
  margin-bottom: 20px;
}
.update-owner .owner-dropdown input {
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.update-owner .owner-dropdown .dropdown {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 250px;
  overflow: scroll;
}
.update-owner .owner-dropdown .dropdown li {
  padding: 0 15px;
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.update-owner .owner-dropdown .dropdown li:hover {
  background: rgba(97, 180, 255, 0.3);
}
.update-owner .btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 900;
  font-size: 14px;
}
.update-owner .animation-group {
  flex-direction: initial;
}
.update-owner .animation-group div {
  margin: 0 10px 0 0 !important;
}

.update-phone-form input[type=tel], .update-phone-form .react-phone-number-input__icon {
  margin-top: 0 !important;
}

.radio-list {
  overflow: auto;
  clear: both;
  margin-bottom: 20px;
}

.radio-list.horizontal .options {
  overflow: auto;
}
.radio-list.horizontal .control-group {
  float: left;
  display: inline-block;
}
.radio-list.horizontal .control-group label {
  margin-right: 15px;
  display: block;
  float: left;
  margin-right: 20px;
}
.radio-list.horizontal .control-group input {
  margin-right: 15px;
  margin-left: 1px;
  display: block;
  float: left;
  margin-top: 6px;
}

.flex {
  display: flex;
  flex-direction: row;
}

input[type=checkbox] {
  margin-bottom: -5px !important;
  margin-top: 10px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 23px !important;
  box-sizing: border-box;
}

.checkbox-form-control {
  height: 30px !important;
}
.checkbox-form-control label {
  top: 0px !important;
}

.portal-updateBackground {
  overflow-y: scroll;
}

.patient-details-header {
  margin-bottom: 12px !important;
}

.patient-details-form .date-input, .patient-details-form .patient-height-container, .patient-details-form .multiselect, .patient-details-form .number-input, .patient-details-form .radio-list {
  margin-top: 8px !important;
  color: #868786 !important;
}
.patient-details-form .date-input label, .patient-details-form .patient-height-container label, .patient-details-form .multiselect label, .patient-details-form .number-input label, .patient-details-form .radio-list label {
  margin-bottom: 4px;
  font-size: 20px;
}
.patient-details-form .number-input {
  width: 33%;
}
.patient-details-form .multiselect {
  width: 50%;
}
.patient-details-form .date-input {
  margin: 23px 0 !important;
}
.patient-details-form .radio-list {
  margin-bottom: 5px;
}
.patient-details-form .radio-list .control-group label {
  font-size: 16px;
  color: #656565;
}
.patient-details-form .patient-height-container {
  display: flex;
  flex-direction: row;
  color: #868786;
}
.patient-details-form .patient-height-container label {
  font-size: 16px;
}
.patient-details-form .patient-height-container .patient-height-input {
  margin-right: 5px;
  width: 33%;
}
.patient-details-form .patient-detail-form-buttons {
  margin-top: 10px;
}
.patient-details-form .patient-detail-form-buttons .btn {
  margin-top: 10px;
  height: 50px;
  width: 120px;
}
.patient-details-form .patient-detail-form-buttons .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
  height: 50px;
  width: 120px;
}

.release-form .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.release-form .form .notification {
  margin-top: 10px;
  font-weight: 900;
  text-align: center;
}
.release-form h1 {
  margin-top: 16px;
  margin-bottom: 18px;
}
.release-form .undo-release {
  color: rgb(180, 180, 180);
  font-weight: 500;
}
.release-form .btn {
  line-height: 40px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.release-form .btn-default {
  background-color: #B4B4B4 !important;
}

.portal-release .content-container {
  margin-top: 0 !important;
}
.portal-release .options {
  margin-left: 10px !important;
}
.portal-release .options input {
  margin-right: 10px !important;
}
.portal-release .options label {
  margin-right: 10px !important;
}
.portal-release .options .control-group:last-child label {
  margin-right: 0 !important;
}
.portal-release input[type=submit], .portal-release btn {
  width: 115px !important;
}

.portal-container .close-button {
  height: 26px;
  width: 26px;
  font-size: 18px;
  top: 20px;
  right: 12px;
}

.modal-panel {
  margin-top: 130px !important;
}

.delete-peer {
  padding: 0px 30px 0 30px;
  text-align: center;
}
.delete-peer .patient-list-link {
  font-weight: 900;
  font-size: 18px;
  color: #61B4FF;
}
.delete-peer .successful-delete-message {
  padding-bottom: 30px;
  margin-top: 25px;
  margin-bottom: 0px;
}
.delete-peer .patient-list-link:hover {
  text-decoration: none;
}

.transition-program .program-dropdown {
  margin-bottom: 20px;
}
.transition-program .program-dropdown input {
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.transition-program .program-dropdown .dropdown {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 250px;
  overflow: scroll;
}
.transition-program .program-dropdown .dropdown li {
  padding: 0 15px;
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.transition-program .program-dropdown .dropdown li:hover {
  background: rgba(97, 180, 255, 0.3);
}
.transition-program .btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 900;
  font-size: 14px;
}
.transition-program .animation-group {
  flex-direction: initial;
}
.transition-program .animation-group div {
  margin: 0 10px 0 0 !important;
}

.placeholder-select-field label.disabled {
  color: #C8C8C8;
}
.placeholder-select-field .optional-label {
  font-weight: normal;
}
.placeholder-select-field .Select.is-focused > .Select-control {
  border-color: #66afe9 !important;
}
.placeholder-select-field .is-focused {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.placeholder-select-field .Select-option {
  box-shadow: none !important;
  color: #787878 !important;
  font-size: 14px !important;
  font-weight: 900;
}
.placeholder-select-field .Select-option:hover, .placeholder-select-field .Select-option.is-focused {
  background-color: rgba(0, 0, 0, 0.07) !important;
}
.placeholder-select-field .Select-option.is-selected {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.placeholder-select-field .Select-value-label {
  color: #787878 !important;
  font-size: 14px !important;
}
.placeholder-select-field .Select-control {
  border: 1px solid #ccc;
  height: 40px;
  font-weight: 900;
  cursor: pointer !important;
}
.placeholder-select-field .Select-control .Select-input {
  color: #787878;
}
.placeholder-select-field .Select-control .Select-value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.placeholder-select-field .Select-control .Select-placeholder {
  line-height: 38px;
  color: #c8c8c8;
  font-weight: 900;
  font-size: 14px;
}
.placeholder-select-field .Select.disabled .Select-control {
  cursor: not-allowed !important;
}

.care-team-section {
  display: flex;
  flex-direction: column;
}
.care-team-section .added-members {
  margin-top: 52px;
}
.care-team-section .added-members .added-member {
  position: relative;
  margin-bottom: 15px;
}
.care-team-section .added-members .added-member p {
  margin: 0;
}
.care-team-section .added-members .added-member .added-member-job {
  font-size: 12px;
  font-weight: normal;
}
.care-team-section .added-members .added-member .added-member-id {
  font-size: 14px;
  font-weight: 900;
}
.care-team-section .added-members .added-member .delete-care-team-member {
  position: absolute;
  top: 10px;
  left: 235px;
  color: #B4B4B4;
}
.care-team-section .add-care-team-member-button {
  background: #6D778B;
  border-radius: 5px;
  margin-top: 75px;
  height: 40px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border: none;
  cursor: pointer;
}
.care-team-section .add-care-team-member-button:focus {
  outline: none;
}
.care-team-section .input-email-group {
  margin-top: 75px;
}
.care-team-section .input-email-group .care-team-email-input {
  width: 252px;
  margin-bottom: 20px;
  height: 40px;
  font-weight: 900;
  color: #787878;
}
.care-team-section .button-container {
  display: flex;
  justify-content: space-between;
}
.care-team-section .button-container .care-team-email-button, .care-team-section .button-container .care-team-job-button, .care-team-section .button-container .care-team-cancel-button {
  border-radius: 5px;
  height: 40px;
  width: 115px;
  color: #FFFFFF;
  font-weight: 900;
  border: none;
  cursor: pointer;
}
.care-team-section .button-container .care-team-email-button, .care-team-section .button-container .care-team-job-button {
  background: #6D778B;
}
.care-team-section .button-container .care-team-cancel-button {
  background: #B4B4B4;
}
.care-team-section .button-container .care-team-email-button:focus {
  outline-color: #6D778B;
  cursor: not-allowed;
}
.care-team-section .button-container .care-team-job-button:focus {
  outline: none;
}
.care-team-section .button-container .care-team-job-button.disabled, .care-team-section .button-container .care-team-email-button.disabled {
  cursor: not-allowed;
}
.care-team-section .job-select-group {
  margin-top: 5px;
}
.care-team-section .job-select-group .care-team-job-select {
  width: 252px;
  margin-bottom: 20px;
}
.care-team-section .input-email-group.hasAddedMembers, .care-team-section .add-care-team-member-button.hasAddedMembers {
  margin-top: 5px;
}

.admin-overlay {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.admin-overlay .admin-overlay-close-modal {
  position: absolute;
  right: 13px;
  top: 20px;
  color: #787878;
  width: 26px !important;
  height: 26px !important;
  font-size: 18px;
}
.admin-overlay .customer-dropdown-loading {
  position: absolute;
  top: 35px;
  left: 385px;
}
.admin-overlay .customer-dropdown {
  position: absolute;
  top: 26px;
  left: 275px;
  border: 1px solid rgba(44, 44, 44, 0.4588235294);
  width: 322px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
}
.admin-overlay .customer-dropdown .customer-search {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 14px;
  color: #5e5e5e;
  padding: 10px;
  border: none;
  background: #f3f3f3;
  box-shadow: 0px 0px 3px 1px rgba(114, 114, 114, 0.9019607843);
}
.admin-overlay .customer-dropdown .customer-search:focus {
  outline: none;
  border: none;
}
.admin-overlay .customer-dropdown .customer-list {
  border-radius: 5px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 150px;
  min-width: 320px;
  overflow: scroll;
}
.admin-overlay .customer-dropdown .customer-list .customer-item {
  padding-left: 10px;
  padding-bottom: 3px;
  color: #787878;
  font-size: 14px;
}
.admin-overlay .customer-dropdown .customer-list .customer-item:hover {
  background: #DCF1FF;
}

.extra-fields-loading {
  height: 235px;
  position: relative;
  display: flex;
}
.extra-fields-loading .extra-fields-loading-spinner {
  position: absolute;
  height: 60px;
  width: 60px;
  left: 310px;
  top: 70px;
}

.patient-invite-form {
  width: 100%;
  background: #FAFAFA;
  min-height: 507px;
}
.patient-invite-form .selected-customer {
  position: absolute;
  top: 35px;
  left: 275px;
  font-weight: 900;
  font-size: 14px;
  color: #61B4FF;
}
.patient-invite-form .selected-customer .clear-customer {
  margin-left: 11px;
  height: 20px;
  width: 20px;
  font-size: 12px;
  display: none;
}
.patient-invite-form .selected-customer .clear-customer:hover {
  opacity: 0.95;
}
.patient-invite-form .selected-customer:hover {
  text-decoration: underline;
}
.patient-invite-form .selected-customer:hover .clear-customer {
  display: inline;
}
.patient-invite-form .form-group {
  position: relative;
}
.patient-invite-form .form-group .error-message {
  position: absolute;
  top: 0;
  right: 20px;
  font-weight: normal;
  font-size: 12px;
}
.patient-invite-form .label-has-error {
  color: #DB5E4B !important;
}
.patient-invite-form .label-has-error .asterisk {
  color: #DB5E4B !important;
}
.patient-invite-form .has-error {
  border-color: #DB5E4B !important;
}
.patient-invite-form .sections-container {
  display: flex;
}
.patient-invite-form .asterisk {
  color: #61B4FF;
  padding-left: 1px;
}
.patient-invite-form .patient-invite-header-group {
  display: flex;
  align-items: flex-end;
  margin-bottom: 26px;
  color: #787878;
}
.patient-invite-form .patient-invite-header-group p, .patient-invite-form .patient-invite-header-group h2 {
  margin: 0;
}
.patient-invite-form .patient-invite-header-group h2 {
  font-size: 24px;
  font-weight: 900;
  margin-right: 10px;
}
.patient-invite-form .patient-invite-header-group p {
  font-size: 12px;
  font-weight: normal;
  line-height: 19px;
}
.patient-invite-form .disabled-surgery-date {
  cursor: not-allowed;
}
.patient-invite-form .disabled-surgery-date .dummy-label, .patient-invite-form .disabled-surgery-date .dummy-field {
  color: #C8C8C8;
}
.patient-invite-form .disabled-surgery-date .dummy-label {
  font-weight: normal;
  line-height: 23px;
}
.patient-invite-form .disabled-surgery-date .dummy-field {
  font-style: italic;
  font-size: 14px;
  margin-top: 9px;
}
.patient-invite-form .patient-invite-drilldown-field .filterField:hover {
  cursor: pointer;
}
.patient-invite-form .patient-invite-drilldown-field .filterField {
  padding: 6px 12px;
  color: #787878;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
}
.patient-invite-form .patient-invite-drilldown-field .dropdown .withChild .parent, .patient-invite-form .patient-invite-drilldown-field .dropdown .withChild .clickable {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 27px;
}
.patient-invite-form .dateInputField {
  position: relative;
  width: 175px;
}
.patient-invite-form .patient-invite-date-field {
  margin-top: 0;
  height: 40px;
  width: 79px;
  font-size: 14px;
  border-color: #ccc;
  border-radius: 4px;
  color: #787878;
  font-weight: 900;
}
.patient-invite-form .react-datepicker__input-container input {
  padding-left: 10px;
  padding-right: 10px;
}
.patient-invite-form .patient-invite-date-field:focus, .patient-invite-form .patient-invite-drilldown-field .filterField:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.patient-invite-form .patient-invite-date-field:hover {
  cursor: pointer;
}
.patient-invite-form .react-datepicker__day-name {
  color: #656565;
}
.patient-invite-form .optional-date-container {
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  position: absolute;
  left: 85px;
  top: 25px;
  display: flex;
}
.patient-invite-form .optional-date-container input {
  height: 20px !important;
  width: 22px !important;
  background-size: 15px;
  margin-top: 4px !important;
}
.patient-invite-form .optional-date-container label[for=optionalDate] {
  margin: 0;
  margin-left: 7px;
  font-weight: normal;
  line-height: 16px;
}
.patient-invite-form .patient-invite-fields {
  width: 550px;
}
.patient-invite-form .patient-invite-fields label {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 12px;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field {
  margin-right: 20px;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean {
  order: 9999;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control {
  width: 249px;
  height: 53px !important;
  position: reset;
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control .form-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control .form-group label {
  position: static !important;
  margin: 0 !important;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field.boolean .checkbox-form-control .form-group input {
  margin: 0 !important;
  margin-right: 15px !important;
  position: static !important;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field .medium {
  width: 250px;
  color: #787878;
  font-weight: 900;
  height: 40px;
  margin-top: 0;
  font-size: 14px;
  background: #ffffff;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field .medium::placeholder {
  font-weight: 900;
}
.patient-invite-form .patient-invite-fields .admin-extra-fields-container .extra-field .medium:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.patient-invite-form .patient-invite-fields .field-pair {
  display: flex;
}
.patient-invite-form .patient-invite-fields .field-pair .large, .patient-invite-form .patient-invite-fields .field-pair .small, .patient-invite-form .patient-invite-fields .field-pair .medium {
  margin-right: 20px;
}
.patient-invite-form .patient-invite-fields .field-pair .large, .patient-invite-form .patient-invite-fields .field-pair .small {
  color: #787878;
  font-weight: 900;
  height: 40px;
}
.patient-invite-form .patient-invite-fields .field-pair .large::placeholder, .patient-invite-form .patient-invite-fields .field-pair .small::placeholder {
  font-weight: 900;
}
.patient-invite-form .patient-invite-fields .field-pair .large {
  width: 322px;
}
.patient-invite-form .patient-invite-fields .field-pair .small {
  width: 175px;
}
.patient-invite-form .patient-invite-submit-button {
  display: block;
  height: 40px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border-radius: 5px;
  margin: 20px auto 0px auto;
  background: #6D778B;
  border: none;
  cursor: pointer;
}
.patient-invite-form .patient-invite-submit-button:focus {
  border: none;
}
.patient-invite-form .consent-checkbox {
  position: relative;
}
.patient-invite-form .consent-checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
}
.patient-invite-form .consent-checkbox label[for=consent] {
  margin-top: 7px;
  padding-left: 40px;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}
.patient-invite-form .disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.change-partner-overlay, .change-partner-overlay .customer-dropdown {
  position: relative;
  top: 0;
  left: 0;
}

.change-partner-overlay {
  background: none;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.patient-invite-fields .program-dropdown {
  margin-bottom: 20px;
}
.patient-invite-fields .program-dropdown input {
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.patient-invite-fields .program-dropdown .dropdown {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 250px;
  overflow: scroll;
}
.patient-invite-fields .program-dropdown .dropdown li {
  padding: 0 15px;
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.patient-invite-fields .program-dropdown .dropdown li:hover {
  background: rgba(97, 180, 255, 0.3);
}

.user-actions {
  margin-bottom: 20px;
}
.user-actions .btn {
  margin-right: 10px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.user-actions .btn-danger {
  background: #CF6054 !important;
}

.user-actions {
  margin-bottom: 20px;
}
.user-actions .btn {
  margin-right: 10px;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
}

.customers {
  float: left;
}

.calendars {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.core-calendar-container {
  margin: 10px;
}

.core-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.customers {
  float: left;
}

.calendars {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.weekly-adoption-container {
  margin: 10px;
}

.weekly-adoption-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.customers {
  float: left;
}

.calendars {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.billing-container {
  margin: 10px;
}
.billing-container.amount-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.billing-report-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.customers {
  float: left;
}

.calendars {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.book-of-business-container, .download-button {
  margin: 10px;
}

.book-of-business-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.card-portal-container {
  background-color: #eeeeee;
  border: 1px solid grey;
  padding: 25px 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.card-filter-searchbox {
  margin-top: 3px !important;
}

.cards-page {
  position: relative;
  padding-bottom: 30px;
}
.cards-page .top {
  padding: 20px 30px 0 30px;
}
.cards-page .top h2 {
  padding-top: 5px;
  margin-bottom: 0;
}
.cards-page .cards-list {
  border: 1px solid grey;
  padding-left: 0;
  margin: 20px 0 0 0;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}
.cards-page .card-list-item {
  display: block;
  padding: 8px 30px 8px 30px;
  font-weight: bold;
}
.cards-page .card-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.cards-page .card-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.cards-page .card-list-item:hover .fa {
  display: inline-block;
}
.cards-page .card-list-item-active {
  background-color: #61B4FF !important;
  color: white;
}
.cards-page .empty-message {
  font-size: 26px;
  width: 100%;
  margin-top: calc(50vh - 150px);
  opacity: 0.5;
  font-weight: bold;
  text-align: center;
}
.cards-page select {
  margin-bottom: 10px;
}

.doubleclick {
  height: 20px;
  bottom: 10px;
  left: 0;
  right: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  opacity: 70%;
  margin-bottom: 0;
}

.filters-title {
  margin-top: 30px;
}

.dashboard-as-staff .cards-list {
  border: 3px solid grey;
  max-height: calc(100vh - 128px);
}
.dashboard-as-staff .card-list-item:hover .fa {
  display: none;
}

.no-breadcrumb .cards-page {
  height: calc(100vh - 50px);
}

.program-list-col {
  padding-right: 0;
  padding-left: 0;
}

.card-list-col {
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid #DFDFDF;
  width: calc(100% - 375px);
}

.cards {
  flex-wrap: nowrap !important;
}

.dashboard-in-cards {
  background-color: #afd371;
}

.dashboard-in-cards #topBars {
  background-color: #dcf1ff;
}

.card-list-content {
  text-align: left;
  background-color: #dcf1ff;
  background-image: url(/static/media/patient_banner_background.75fc6a79.png);
  background-repeat: no-repeat;
  background-position: top, center;
  height: 323px;
}

.preview-col {
  width: 375px;
}

#react-select-4-listbox {
  z-index: 10;
}

.formik-markdown-field label {
  margin-bottom: 5px;
}

.markdown-group .no-disable, .markdown-group .fa-question-circle, .markdown-group .no-mobile + .separator {
  display: none;
}

.CodeMirror {
  height: auto !important;
  margin-bottom: 10px;
}

.CodeMirror-focused .CodeMirror-selected {
  background: rgb(145, 202, 255) !important;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: rgb(145, 202, 255) !important;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: rgb(145, 202, 255) !important;
}

.has-error {
  border-color: #DB5E4B !important;
}

.error-message {
  margin-top: 3px;
  display: inline-block;
  color: #DB5E4B !important;
}

.formik-text-field {
  display: block;
}

.formik-text-field label {
  margin-bottom: 5px;
  margin-top: 10px;
  display: inline-block;
  max-width: 100%;
}

.formik-text-field input {
  min-width: 500px;
  width: 100%;
  margin-bottom: 10px;
}

.bottom-bar .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
}
.bottom-bar .btn-danger {
  margin-right: 20px;
}
.bottom-bar .btn {
  width: 120px;
}

.pull-right.buttons {
  padding-bottom: 15px;
}

.invite-portal-container {
  width: 100%;
  padding: 0px 0px;
}

.dashboard-in-invite-portal {
  background-color: #afd371;
}

.dashboard-in-invite-portal #topBars {
  background-color: #dcf1ff;
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row-apps {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.row-apps img {
  max-width: 70px;
  max-height: 70px;
}

.add-top-margin {
  margin-top: 20px;
}

.bannerbox img {
  max-width: 100%;
  max-height: 100%;
}

.bannerbox {
  margin-top: 220px;
  text-align: center;
}

.jumbotron {
  border: 1px solid grey;
}

.checkbox-form-control {
  overflow: hidden;
}

.confirm .checkbox-form-control {
  height: 120px !important;
}

.radio-list.horizontal {
  margin-top: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.program_checkbox {
  margin-top: 10px;
}

.program_checkbox input[type=checkbox] {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 0px !important;
}

.content {
  margin: 0 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content .jumbotron {
  padding: 25px 30px;
}
.content .btn {
  width: 300px;
  text-align: center;
}

.filler-background {
  text-align: left;
  background-color: #dcf1ff;
  background-image: url(/static/media/patient_banner_background.75fc6a79.png);
  background-repeat: no-repeat;
  background-position: top, center;
  height: 323px;
}

.checkbox-form-control .error-message {
  margin-left: 50px;
  margin-bottom: 20px;
}

.checkbox-form-control label {
  margin-top: 10px !important;
}

.program_checkbox label {
  margin-top: 10px;
}

.modules {
  flex-wrap: nowrap !important;
}

.module-list-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0 !important;
  padding-right: 0;
  border-right: 1px solid #DFDFDF;
}

.edit-col {
  border-right: 1px solid #DFDFDF;
  height: calc(100vh - 100px);
  max-width: calc(100% - 375px);
  overflow-y: scroll;
}

.no-breadcrumb .edit-col {
  height: calc(100vh - 50px);
}

.module-edit {
  margin-top: 10px;
  margin-bottom: 10px;
}

.modules-page {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
}
.modules-page .top {
  padding: 20px 30px 0 30px;
}
.modules-page .top h2 {
  padding-top: 5px;
  margin-bottom: 0;
}
.modules-page .modules-list {
  padding-left: 0;
  margin: 20px 0 0 0;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}
.modules-page .module-list-item {
  display: block;
  padding: 8px 30px 8px 30px;
  font-weight: bold;
}
.modules-page .module-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.modules-page .module-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.modules-page .module-list-item:hover .fa {
  display: inline-block;
}
.modules-page .module-list-item-active {
  background-color: #61B4FF !important;
  color: white;
}
.modules-page .doubleclick {
  height: 20px;
  bottom: 10px;
  left: 0;
  right: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 14px;
}
.modules-page .empty-message {
  font-size: 26px;
  width: 100%;
  margin-top: calc(50vh - 150px);
  opacity: 0.5;
  font-weight: bold;
  text-align: center;
}

.dashboard-as-staff .modules-list {
  max-height: calc(100vh - 128px);
}
.dashboard-as-staff .modules-list-item:hover .fa {
  display: none;
}

.no-breadcrumb .modules-page {
  height: calc(100vh - 50px);
}

.has-error {
  border-color: #DB5E4B !important;
}

.error-message {
  margin-top: 3px;
  display: inline-block;
  color: #DB5E4B !important;
}

.formik-text-field label {
  margin-bottom: 5px;
  margin-top: 10px;
}

.formik-text-field:first-child label {
  margin-top: 0;
}

.flex .formik-text-field:first-child label {
  margin-top: 10px;
}

.formik-text-field select {
  min-width: 500px;
  width: 100%;
  margin-bottom: 10px;
}

.formik-sortable .Select-value {
  display: none;
}

.formik-sortable-field-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.formik-sortable-field {
  display: flex;
  background-color: #ebf5ff;
  border-radius: 2px;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  font-size: 0.9em;
  margin-top: 5px;
  width: -webkit-max-content;
  width: max-content;
}

.formik-sortable-field .item-grab-icon {
  width: 18px;
  background: url(/static/media/grab.78318055.svg) center center no-repeat;
  border-right-style: solid;
  border-right-color: rgba(0, 126, 255, 0.24);
}

.formik-sortable-field .item-label {
  min-width: -webkit-max-content;
  min-width: max-content;
  padding: 5px 19px 5px 5px;
  widows: -webkit-fill-available;
}

.formik-sortable-field .item-clear {
  padding: 6px;
  border-left-style: solid;
  border-left-color: rgba(0, 126, 255, 0.24);
  width: 20px;
}

.formik-sortable-field .item-clear:hover {
  background-color: #d8ebff;
}

.bottom-bar .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
}
.bottom-bar .btn-danger, .bottom-bar .btn-primary {
  margin-right: 20px;
}
.bottom-bar .btn {
  width: 120px;
}

.pull-right.buttons {
  padding-bottom: 15px;
}

.stats-container {
  margin: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #434a4a;
  color: white;
}
.stats-container .positive {
  color: #00ff00;
}
.stats-container .negative {
  color: #ff0000;
}
.stats-container h1 {
  color: white;
}
.stats-container .value {
  font-size: 40px;
}

.title {
  text-align: center;
  padding-top: 5px;
  color: white;
}

.dashboard-in-kpi-stats {
  background-color: #000000;
}
.dashboard-in-kpi-stats h1 {
  margin-bottom: 0px;
}

.difference {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.difference span {
  margin-bottom: -5px;
  margin-left: 4px;
  margin-right: 4px;
}

.stat {
  display: flex;
  flex-direction: row;
  align-items: flex-end !important;
}
.stat p {
  margin-bottom: 0px;
}

.manage-customer {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  min-width: 43%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 50px;
  margin-bottom: 50px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.manage-customer .manage-loading-indicator {
  height: 75px;
  width: 75px;
}
.manage-customer .edit-field {
  margin-bottom: 15px;
}
.manage-customer .checkbox-form-control {
  overflow: unset;
}
.manage-customer .checkbox-form-control .error-message {
  position: absolute;
  left: -50px;
  top: 25px;
}
.manage-customer p {
  margin: 0;
}
.manage-customer .header-info {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-customer .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-customer .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-customer .update-existing {
  margin-top: 20px;
}
.manage-customer .customer-actions .clinician-list {
  width: 40%;
  font-size: 13px;
}
.manage-customer span {
  font-weight: 900;
  font-size: 18px;
}
.manage-customer .manage-customer-form .form-header {
  margin-bottom: 20px;
}
.manage-customer .action-btn {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-customer .action-btn:hover {
  opacity: 0.85;
}
.manage-customer .checkbox-form-control {
  margin-top: 12px;
}
.manage-customer .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-customer .update-customer-type-warning {
  margin-top: -11px;
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
  width: 506px;
}
.manage-customer label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-customer h2 {
  margin-bottom: 20px;
  font-weight: 900;
}
.manage-customer h3 {
  font-size: 22px;
}
.manage-customer h3, .manage-customer h4 {
  margin: 0 0 5px 0;
}
.manage-customer .message {
  margin-top: 20px;
  margin-bottom: 0;
}
.manage-customer .customer-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-customer .customer-dropdown .customer-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-customer .customer-dropdown .customer-list {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-customer .customer-dropdown .customer-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-customer .customer-dropdown .customer-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}

.multi-input-field .label-message-group {
  display: flex;
  justify-content: space-between;
}
.multi-input-field .multi-input-group {
  display: flex;
  align-items: center;
}
.multi-input-field .multi-input {
  font-size: 16px;
  height: 35px;
  width: 443px;
  padding: 7px;
}
.multi-input-field .action-btn {
  margin-left: 10px;
  font-size: 18px !important;
  line-height: 30px;
  height: 33px !important;
  width: 48px !important;
  margin-bottom: 3px;
}
.multi-input-field .isDisabled, .multi-input-field .isDisabled:hover {
  opacity: 0.5 !important;
}
.multi-input-field .error {
  color: #DB5E4B;
  padding-right: 66px;
}
.multi-input-field .message {
  font-size: 15px;
  color: #61B4FF;
  margin: 0 !important;
  padding-right: 66px;
}
.multi-input-field .submitted-list {
  margin-top: 2px;
  padding: 0;
  width: 443px;
}
.multi-input-field .submitted-list .multi-item {
  width: 443px;
  border: 1px solid lightgray;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.multi-input-field .submitted-list .multi-item .remove {
  font-style: italic;
  padding-right: 5px;
}
.multi-input-field .submitted-list .multi-item:hover {
  background: #DB5E4B;
  color: white;
  opacity: 0.8;
}

.manage-clinician {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.manage-clinician .Select-clear-zone {
  display: none !important;
}
.manage-clinician .manage-loading-indicator {
  height: 75px;
  width: 75px;
}
.manage-clinician p {
  margin: 0;
}
.manage-clinician .header-prefix {
  font-weight: 900;
  font-size: 18px;
}
.manage-clinician .header-info {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-clinician .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-clinician .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-clinician .update-header {
  margin-bottom: 20px;
}
.manage-clinician .form-header {
  margin-bottom: 20px;
}
.manage-clinician .update-existing {
  margin-top: 20px;
}
.manage-clinician .update-existing .or-update {
  margin-bottom: 5px;
}
.manage-clinician .select-customer .customer-dropdown, .manage-clinician .select-customer .clinician-dropdown, .manage-clinician .select-clinician .customer-dropdown, .manage-clinician .select-clinician .clinician-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-clinician .select-customer .customer-dropdown .customer-search, .manage-clinician .select-customer .customer-dropdown .clinician-search, .manage-clinician .select-customer .clinician-dropdown .customer-search, .manage-clinician .select-customer .clinician-dropdown .clinician-search, .manage-clinician .select-clinician .customer-dropdown .customer-search, .manage-clinician .select-clinician .customer-dropdown .clinician-search, .manage-clinician .select-clinician .clinician-dropdown .customer-search, .manage-clinician .select-clinician .clinician-dropdown .clinician-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-clinician .select-customer .customer-dropdown .customer-list, .manage-clinician .select-customer .customer-dropdown .clinician-list, .manage-clinician .select-customer .clinician-dropdown .customer-list, .manage-clinician .select-customer .clinician-dropdown .clinician-list, .manage-clinician .select-clinician .customer-dropdown .customer-list, .manage-clinician .select-clinician .customer-dropdown .clinician-list, .manage-clinician .select-clinician .clinician-dropdown .customer-list, .manage-clinician .select-clinician .clinician-dropdown .clinician-list {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-clinician .select-customer .customer-dropdown .customer-list .customer-item, .manage-clinician .select-customer .customer-dropdown .customer-list .clinician-item, .manage-clinician .select-customer .customer-dropdown .clinician-list .customer-item, .manage-clinician .select-customer .customer-dropdown .clinician-list .clinician-item, .manage-clinician .select-customer .clinician-dropdown .customer-list .customer-item, .manage-clinician .select-customer .clinician-dropdown .customer-list .clinician-item, .manage-clinician .select-customer .clinician-dropdown .clinician-list .customer-item, .manage-clinician .select-customer .clinician-dropdown .clinician-list .clinician-item, .manage-clinician .select-clinician .customer-dropdown .customer-list .customer-item, .manage-clinician .select-clinician .customer-dropdown .customer-list .clinician-item, .manage-clinician .select-clinician .customer-dropdown .clinician-list .customer-item, .manage-clinician .select-clinician .customer-dropdown .clinician-list .clinician-item, .manage-clinician .select-clinician .clinician-dropdown .customer-list .customer-item, .manage-clinician .select-clinician .clinician-dropdown .customer-list .clinician-item, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .customer-item, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .clinician-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-clinician .select-customer .customer-dropdown .customer-list .customer-item:hover, .manage-clinician .select-customer .customer-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-customer .customer-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-customer .customer-dropdown .clinician-list .clinician-item:hover, .manage-clinician .select-customer .clinician-dropdown .customer-list .customer-item:hover, .manage-clinician .select-customer .clinician-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-customer .clinician-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-customer .clinician-dropdown .clinician-list .clinician-item:hover, .manage-clinician .select-clinician .customer-dropdown .customer-list .customer-item:hover, .manage-clinician .select-clinician .customer-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-clinician .customer-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-clinician .customer-dropdown .clinician-list .clinician-item:hover, .manage-clinician .select-clinician .clinician-dropdown .customer-list .customer-item:hover, .manage-clinician .select-clinician .clinician-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .clinician-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-clinician .select-customer .customer-dropdown .customer-list .isSelected, .manage-clinician .select-customer .customer-dropdown .customer-list .isSelected:hover, .manage-clinician .select-customer .customer-dropdown .clinician-list .isSelected, .manage-clinician .select-customer .customer-dropdown .clinician-list .isSelected:hover, .manage-clinician .select-customer .clinician-dropdown .customer-list .isSelected, .manage-clinician .select-customer .clinician-dropdown .customer-list .isSelected:hover, .manage-clinician .select-customer .clinician-dropdown .clinician-list .isSelected, .manage-clinician .select-customer .clinician-dropdown .clinician-list .isSelected:hover, .manage-clinician .select-clinician .customer-dropdown .customer-list .isSelected, .manage-clinician .select-clinician .customer-dropdown .customer-list .isSelected:hover, .manage-clinician .select-clinician .customer-dropdown .clinician-list .isSelected, .manage-clinician .select-clinician .customer-dropdown .clinician-list .isSelected:hover, .manage-clinician .select-clinician .clinician-dropdown .customer-list .isSelected, .manage-clinician .select-clinician .clinician-dropdown .customer-list .isSelected:hover, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .isSelected, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-clinician .create-clinician-form, .manage-clinician .update-clinician-form {
  margin-top: 20px;
}
.manage-clinician .action-btn {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-clinician .action-btn:hover {
  opacity: 0.85;
}
.manage-clinician .red {
  background-color: #da4933;
}
.manage-clinician .checkbox-form-control {
  margin-top: 12px;
}
.manage-clinician .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-clinician label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-clinician h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-clinician h3 {
  font-size: 22px;
}
.manage-clinician h3, .manage-clinician h4 {
  margin: 0 0 5px 0;
}
.manage-clinician .message {
  margin-top: 20px;
  margin-bottom: 0;
}

.manage-admin {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.manage-admin .Select-clear-zone {
  display: none !important;
}
.manage-admin p {
  margin: 0;
}
.manage-admin .header-prefix {
  font-weight: 900;
  font-size: 18px;
}
.manage-admin .header-info {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-admin .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-admin .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-admin .update-header {
  margin-bottom: 20px;
}
.manage-admin .form-header {
  margin-bottom: 20px;
}
.manage-admin .update-existing {
  margin-top: 20px;
}
.manage-admin .update-existing .or-update {
  margin-bottom: 5px;
}
.manage-admin .select-customer .customer-dropdown, .manage-admin .select-customer .admin-dropdown, .manage-admin .select-admin .customer-dropdown, .manage-admin .select-admin .admin-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-admin .select-customer .customer-dropdown .customer-search, .manage-admin .select-customer .customer-dropdown .admin-search, .manage-admin .select-customer .admin-dropdown .customer-search, .manage-admin .select-customer .admin-dropdown .admin-search, .manage-admin .select-admin .customer-dropdown .customer-search, .manage-admin .select-admin .customer-dropdown .admin-search, .manage-admin .select-admin .admin-dropdown .customer-search, .manage-admin .select-admin .admin-dropdown .admin-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-admin .select-customer .customer-dropdown .customer-list, .manage-admin .select-customer .customer-dropdown .admin-list, .manage-admin .select-customer .admin-dropdown .customer-list, .manage-admin .select-customer .admin-dropdown .admin-list, .manage-admin .select-admin .customer-dropdown .customer-list, .manage-admin .select-admin .customer-dropdown .admin-list, .manage-admin .select-admin .admin-dropdown .customer-list, .manage-admin .select-admin .admin-dropdown .admin-list {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-admin .select-customer .customer-dropdown .customer-list .customer-item, .manage-admin .select-customer .customer-dropdown .customer-list .admin-item, .manage-admin .select-customer .customer-dropdown .admin-list .customer-item, .manage-admin .select-customer .customer-dropdown .admin-list .admin-item, .manage-admin .select-customer .admin-dropdown .customer-list .customer-item, .manage-admin .select-customer .admin-dropdown .customer-list .admin-item, .manage-admin .select-customer .admin-dropdown .admin-list .customer-item, .manage-admin .select-customer .admin-dropdown .admin-list .admin-item, .manage-admin .select-admin .customer-dropdown .customer-list .customer-item, .manage-admin .select-admin .customer-dropdown .customer-list .admin-item, .manage-admin .select-admin .customer-dropdown .admin-list .customer-item, .manage-admin .select-admin .customer-dropdown .admin-list .admin-item, .manage-admin .select-admin .admin-dropdown .customer-list .customer-item, .manage-admin .select-admin .admin-dropdown .customer-list .admin-item, .manage-admin .select-admin .admin-dropdown .admin-list .customer-item, .manage-admin .select-admin .admin-dropdown .admin-list .admin-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-admin .select-customer .customer-dropdown .customer-list .customer-item:hover, .manage-admin .select-customer .customer-dropdown .customer-list .admin-item:hover, .manage-admin .select-customer .customer-dropdown .admin-list .customer-item:hover, .manage-admin .select-customer .customer-dropdown .admin-list .admin-item:hover, .manage-admin .select-customer .admin-dropdown .customer-list .customer-item:hover, .manage-admin .select-customer .admin-dropdown .customer-list .admin-item:hover, .manage-admin .select-customer .admin-dropdown .admin-list .customer-item:hover, .manage-admin .select-customer .admin-dropdown .admin-list .admin-item:hover, .manage-admin .select-admin .customer-dropdown .customer-list .customer-item:hover, .manage-admin .select-admin .customer-dropdown .customer-list .admin-item:hover, .manage-admin .select-admin .customer-dropdown .admin-list .customer-item:hover, .manage-admin .select-admin .customer-dropdown .admin-list .admin-item:hover, .manage-admin .select-admin .admin-dropdown .customer-list .customer-item:hover, .manage-admin .select-admin .admin-dropdown .customer-list .admin-item:hover, .manage-admin .select-admin .admin-dropdown .admin-list .customer-item:hover, .manage-admin .select-admin .admin-dropdown .admin-list .admin-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-admin .select-customer .customer-dropdown .customer-list .isSelected, .manage-admin .select-customer .customer-dropdown .customer-list .isSelected:hover, .manage-admin .select-customer .customer-dropdown .admin-list .isSelected, .manage-admin .select-customer .customer-dropdown .admin-list .isSelected:hover, .manage-admin .select-customer .admin-dropdown .customer-list .isSelected, .manage-admin .select-customer .admin-dropdown .customer-list .isSelected:hover, .manage-admin .select-customer .admin-dropdown .admin-list .isSelected, .manage-admin .select-customer .admin-dropdown .admin-list .isSelected:hover, .manage-admin .select-admin .customer-dropdown .customer-list .isSelected, .manage-admin .select-admin .customer-dropdown .customer-list .isSelected:hover, .manage-admin .select-admin .customer-dropdown .admin-list .isSelected, .manage-admin .select-admin .customer-dropdown .admin-list .isSelected:hover, .manage-admin .select-admin .admin-dropdown .customer-list .isSelected, .manage-admin .select-admin .admin-dropdown .customer-list .isSelected:hover, .manage-admin .select-admin .admin-dropdown .admin-list .isSelected, .manage-admin .select-admin .admin-dropdown .admin-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-admin .create-users-form, .manage-admin .update-admin-form, .manage-admin .user-details {
  margin-top: 20px;
}
.manage-admin .action-btn {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-admin .action-btn:hover {
  opacity: 0.85;
}
.manage-admin .red {
  background-color: #da4933;
}
.manage-admin .checkbox-form-control {
  margin-top: 12px;
}
.manage-admin .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-admin label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-admin h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-admin h3 {
  font-size: 22px;
}
.manage-admin h3, .manage-admin h4 {
  margin: 0 0 5px 0;
}
.manage-admin .message {
  margin-top: 20px;
  margin-bottom: 0;
}

.admin-password-container {
  margin-bottom: 10px !important;
  background-color: white !important;
}

.manage-staff {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 515px;
}
.manage-staff .checkbox-form-control {
  margin-top: 12px;
}
.manage-staff .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-staff label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-staff h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-staff h3 {
  font-size: 22px;
}
.manage-staff h3, .manage-staff h4 {
  margin: 0 0 5px 0;
}
.manage-staff .message {
  margin-top: 20px;
  margin-bottom: 0;
}
.manage-staff .clinician-dropdown .select-clinician-form {
  display: flex;
  margin-top: 5px;
}
.manage-staff .clinician-dropdown .select-clinician-form .select-clinician-drilldown {
  display: inline-block;
  width: 300px;
  margin-right: 4px;
}
.manage-staff .clinician-dropdown .select-clinician-form .select-clinician-btn {
  height: 40px;
  width: 80px;
  border-radius: 3px;
  font-size: 16px;
}
.manage-staff .clinician-dropdown .select-clinician-form .filterField {
  color: #656565;
  padding: 0 7px;
  font-size: 16px;
}
.manage-staff .display-info {
  margin-bottom: 20px;
}
.manage-staff .display-info .selected-clinician, .manage-staff .display-info .selected-codes {
  margin-bottom: 5px;
  font-size: 15px;
}
.manage-staff .display-info span {
  font-size: 16px;
  font-weight: 900;
}
.manage-staff .display-info .action-btn {
  margin-left: 3px;
}
.manage-staff .staff-actions {
  margin-top: 20px;
}
.manage-staff .staff-actions .staff-action-btns {
  margin-bottom: 20px;
}
.manage-staff .staff-actions .staff-dropdown {
  position: relative;
}
.manage-staff .staff-actions .staff-dropdown .staff-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-staff .staff-actions .staff-dropdown .staff-list {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-staff .staff-actions .staff-dropdown .staff-list .staff-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-staff .staff-actions .staff-dropdown .staff-list .staff-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-staff .staff-actions .staff-dropdown .staff-list .isSelected, .manage-staff .staff-actions .staff-dropdown .staff-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-staff .update-staff .update-staff-header span {
  font-weight: 900;
  font-size: 18px;
}
.manage-staff .update-staff-form, .manage-staff .create-staff-form {
  margin-top: 20px;
}
.manage-staff .update-staff-form .if-left-blank {
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}
.manage-staff .edit-field {
  margin-bottom: 7px;
}
.manage-staff .action-btn {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-staff .action-btn:hover {
  opacity: 0.85;
}

.manage-programs {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  height: 515px;
}

.manage-admin {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.manage-admin .Select-clear-zone {
  display: none !important;
}
.manage-admin p {
  margin: 0;
}
.manage-admin .header-prefix {
  font-weight: 900;
  font-size: 18px;
}
.manage-admin .header-info {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-admin .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-admin .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-admin .update-header {
  margin-bottom: 20px;
}
.manage-admin .form-header {
  margin-bottom: 20px;
}
.manage-admin .update-existing {
  margin-top: 20px;
}
.manage-admin .update-existing .or-update {
  margin-bottom: 5px;
}
.manage-admin .select-customer .customer-dropdown, .manage-admin .select-customer .admin-dropdown, .manage-admin .select-admin .customer-dropdown, .manage-admin .select-admin .admin-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-admin .select-customer .customer-dropdown .customer-search, .manage-admin .select-customer .customer-dropdown .admin-search, .manage-admin .select-customer .admin-dropdown .customer-search, .manage-admin .select-customer .admin-dropdown .admin-search, .manage-admin .select-admin .customer-dropdown .customer-search, .manage-admin .select-admin .customer-dropdown .admin-search, .manage-admin .select-admin .admin-dropdown .customer-search, .manage-admin .select-admin .admin-dropdown .admin-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-admin .select-customer .customer-dropdown .customer-list, .manage-admin .select-customer .customer-dropdown .admin-list, .manage-admin .select-customer .admin-dropdown .customer-list, .manage-admin .select-customer .admin-dropdown .admin-list, .manage-admin .select-admin .customer-dropdown .customer-list, .manage-admin .select-admin .customer-dropdown .admin-list, .manage-admin .select-admin .admin-dropdown .customer-list, .manage-admin .select-admin .admin-dropdown .admin-list {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-admin .select-customer .customer-dropdown .customer-list .customer-item, .manage-admin .select-customer .customer-dropdown .customer-list .admin-item, .manage-admin .select-customer .customer-dropdown .admin-list .customer-item, .manage-admin .select-customer .customer-dropdown .admin-list .admin-item, .manage-admin .select-customer .admin-dropdown .customer-list .customer-item, .manage-admin .select-customer .admin-dropdown .customer-list .admin-item, .manage-admin .select-customer .admin-dropdown .admin-list .customer-item, .manage-admin .select-customer .admin-dropdown .admin-list .admin-item, .manage-admin .select-admin .customer-dropdown .customer-list .customer-item, .manage-admin .select-admin .customer-dropdown .customer-list .admin-item, .manage-admin .select-admin .customer-dropdown .admin-list .customer-item, .manage-admin .select-admin .customer-dropdown .admin-list .admin-item, .manage-admin .select-admin .admin-dropdown .customer-list .customer-item, .manage-admin .select-admin .admin-dropdown .customer-list .admin-item, .manage-admin .select-admin .admin-dropdown .admin-list .customer-item, .manage-admin .select-admin .admin-dropdown .admin-list .admin-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-admin .select-customer .customer-dropdown .customer-list .customer-item:hover, .manage-admin .select-customer .customer-dropdown .customer-list .admin-item:hover, .manage-admin .select-customer .customer-dropdown .admin-list .customer-item:hover, .manage-admin .select-customer .customer-dropdown .admin-list .admin-item:hover, .manage-admin .select-customer .admin-dropdown .customer-list .customer-item:hover, .manage-admin .select-customer .admin-dropdown .customer-list .admin-item:hover, .manage-admin .select-customer .admin-dropdown .admin-list .customer-item:hover, .manage-admin .select-customer .admin-dropdown .admin-list .admin-item:hover, .manage-admin .select-admin .customer-dropdown .customer-list .customer-item:hover, .manage-admin .select-admin .customer-dropdown .customer-list .admin-item:hover, .manage-admin .select-admin .customer-dropdown .admin-list .customer-item:hover, .manage-admin .select-admin .customer-dropdown .admin-list .admin-item:hover, .manage-admin .select-admin .admin-dropdown .customer-list .customer-item:hover, .manage-admin .select-admin .admin-dropdown .customer-list .admin-item:hover, .manage-admin .select-admin .admin-dropdown .admin-list .customer-item:hover, .manage-admin .select-admin .admin-dropdown .admin-list .admin-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-admin .select-customer .customer-dropdown .customer-list .isSelected, .manage-admin .select-customer .customer-dropdown .customer-list .isSelected:hover, .manage-admin .select-customer .customer-dropdown .admin-list .isSelected, .manage-admin .select-customer .customer-dropdown .admin-list .isSelected:hover, .manage-admin .select-customer .admin-dropdown .customer-list .isSelected, .manage-admin .select-customer .admin-dropdown .customer-list .isSelected:hover, .manage-admin .select-customer .admin-dropdown .admin-list .isSelected, .manage-admin .select-customer .admin-dropdown .admin-list .isSelected:hover, .manage-admin .select-admin .customer-dropdown .customer-list .isSelected, .manage-admin .select-admin .customer-dropdown .customer-list .isSelected:hover, .manage-admin .select-admin .customer-dropdown .admin-list .isSelected, .manage-admin .select-admin .customer-dropdown .admin-list .isSelected:hover, .manage-admin .select-admin .admin-dropdown .customer-list .isSelected, .manage-admin .select-admin .admin-dropdown .customer-list .isSelected:hover, .manage-admin .select-admin .admin-dropdown .admin-list .isSelected, .manage-admin .select-admin .admin-dropdown .admin-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-admin .create-users-form, .manage-admin .update-admin-form, .manage-admin .user-details {
  margin-top: 20px;
}
.manage-admin .action-btn {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-admin .action-btn:hover {
  opacity: 0.85;
}
.manage-admin .red {
  background-color: #da4933;
}
.manage-admin .checkbox-form-control {
  margin-top: 12px;
}
.manage-admin .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-admin label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-admin h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-admin h3 {
  font-size: 22px;
}
.manage-admin h3, .manage-admin h4 {
  margin: 0 0 5px 0;
}
.manage-admin .message {
  margin-top: 20px;
  margin-bottom: 0;
}

.admin-password-container {
  margin-bottom: 10px !important;
  background-color: white !important;
}

.phases {
  flex-wrap: nowrap !important;
}

.phase-list-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0 !important;
  padding-right: 0;
  border-right: 1px solid #DFDFDF;
}

.edit-col {
  border-right: 1px solid #DFDFDF;
  height: calc(100vh - 100px);
  max-width: calc(100% - 375px);
  overflow-y: scroll;
}

.no-breadcrumb .edit-col {
  height: calc(100vh - 50px);
}

.phase-edit {
  margin-top: 10px;
  margin-bottom: 10px;
}

.phases-page {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
}
.phases-page .top {
  padding: 20px 30px 0 30px;
}
.phases-page .top h2 {
  padding-top: 5px;
  margin-bottom: 0;
}
.phases-page .phases-list {
  padding-left: 0;
  margin: 20px 0 0 0;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}
.phases-page .phase-list-item {
  display: block;
  padding: 8px 30px 8px 30px;
  font-weight: bold;
}
.phases-page .phase-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.phases-page .phase-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.phases-page .phase-list-item:hover .fa {
  display: inline-block;
}
.phases-page .phase-list-item-active {
  background-color: #61B4FF !important;
  color: white;
}
.phases-page .doubleclick {
  height: 20px;
  bottom: 10px;
  left: 0;
  right: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 14px;
}
.phases-page .empty-message {
  font-size: 26px;
  width: 100%;
  margin-top: calc(50vh - 150px);
  opacity: 0.5;
  font-weight: bold;
  text-align: center;
}

.dashboard-as-staff .phases-list {
  max-height: calc(100vh - 128px);
}
.dashboard-as-staff .phases-list-item:hover .fa {
  display: none;
}

.no-breadcrumb .phases-page {
  height: calc(100vh - 50px);
}

.bottom-bar .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
}
.bottom-bar .btn-danger, .bottom-bar .btn-primary {
  margin-right: 20px;
}
.bottom-bar .btn {
  width: 120px;
}

.pull-right.buttons {
  padding-bottom: 15px;
}

.programs-columns {
  display: flex;
}

.programs {
  flex-wrap: nowrap !important;
}

.program-list-col {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0 !important;
  padding-right: 0;
  border-right: 1px solid #DFDFDF;
}

.edit-col {
  border-right: 1px solid #DFDFDF;
  height: calc(100vh - 100px);
  max-width: 100%;
  overflow-y: scroll;
}

.no-breadcrumb .edit-col {
  height: calc(100vh - 50px);
}

.program-edit {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: calc(100% - 375px);
}

.pin-cards {
  padding-left: 0;
}

.btn-simulate {
  margin-left: 10px;
}

.programs-page {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
}
.programs-page .top {
  padding: 20px 30px 0 30px;
}
.programs-page .top h2 {
  padding-top: 5px;
  margin-bottom: 0;
}
.programs-page .programs-list {
  padding-left: 0;
  margin: 20px 0 0 0;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}
.programs-page .program-list-item {
  display: block;
  padding: 8px 30px 8px 30px;
  font-weight: bold;
}
.programs-page .program-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.programs-page .program-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.programs-page .program-list-item:hover .fa {
  display: inline-block;
}
.programs-page .program-list-item-active {
  background-color: #61B4FF !important;
  color: white;
}
.programs-page .double-click {
  height: 20px;
  bottom: 10px;
  left: 0;
  right: 0;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}
.programs-page .empty-message {
  font-size: 26px;
  width: 100%;
  margin-top: calc(50vh - 150px);
  opacity: 0.5;
  font-weight: bold;
  text-align: center;
}

.dashboard-as-staff .programs-list {
  max-height: calc(100vh - 128px);
}
.dashboard-as-staff .programs-list-item:hover .fa {
  display: none;
}

.no-breadcrumb .programs-page {
  height: calc(100vh - 50px);
}

.bottom-bar .btn-cancel {
  background-color: #B4B4B4 !important;
  margin-right: 20px;
}
.bottom-bar .btn-danger {
  margin-right: 20px;
}
.bottom-bar .btn {
  width: 120px;
}

.pull-right.buttons {
  padding-bottom: 15px;
}

.list-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.programs-lists {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.programs-lists select,
.programs-lists input {
  max-width: 200px;
  height: 24px;
  padding-left: 8px;
}

.programs-field-label {
  font-weight: bold;
  margin: 16px 0 8px;
  display: block;
}

.program-cards {
  width: 60%;
}

.cards-list {
  list-style: none;
  padding: 0;
}

.card-list-item {
  padding: 4px 16px;
  cursor: pointer;
}
.card-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.card-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.card-list-item:hover .fa {
  display: inline-block;
}

.empty-card-list {
  padding-left: 8px;
  display: block;
}

.event-type-radio {
  display: inline-block;
  vertical-align: middle;
}

.radio-field-label {
  font-weight: bold;
  margin: 0px 5px 0px;
  display: inline-block;
  vertical-align: middle;
}

.profile-picture-container {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 38px;
  overflow: hidden;
  background: #ccc;
  float: right;
  margin-right: 40px;
  margin-top: -75px;
  cursor: pointer;
}

.profile-picture-edit-text {
  position: absolute;
  width: 75px;
  height: 75px;
  vertical-align: middle;
  line-height: 75px;
  text-align: center;
  z-index: 2;
  color: white;
  font-weight: bold;
  display: none;
}

.profile-picture-container:hover .profile-picture-edit-text {
  display: block;
}

.profile-picture-container img {
  position: absolute;
  width: 75px;
  height: 75px;
  z-index: 1;
}

.partner-user-form {
  width: 100%;
  background: #FAFAFA;
}
.partner-user-form .react-autosuggest__suggestions-container--open {
  position: absolute;
  background: #FFFFFF;
  z-index: 1;
}
.partner-user-form .autocomplete-form-control {
  height: 50px;
}
.partner-user-form .form-group {
  margin-bottom: 0;
}
.partner-user-form .form-group .has-error {
  border-color: #DB5E4B !important;
}
.partner-user-form .form-group .label-has-error {
  color: #DB5E4B !important;
}
.partner-user-form .form-group .label-has-error .asterisk {
  color: #DB5E4B !important;
}
.partner-user-form .form-group {
  position: relative;
}
.partner-user-form .form-group label {
  margin-top: 0;
}
.partner-user-form .form-group .error-message {
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 12px;
}
.partner-user-form .partner-user-header-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;
}
.partner-user-form .partner-user-header-container .partner-user-form-header {
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 24px;
  color: #787878;
}
.partner-user-form .partner-user-fields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.partner-user-form .partner-user-fields .admin-only-fields {
  display: flex;
  align-items: flex-end;
}
.partner-user-form .partner-user-fields .admin-only-fields .customer {
  width: 184px;
  height: 40px !important;
}
.partner-user-form .partner-user-fields .admin-only-fields .userable-type {
  width: 124px;
}
.partner-user-form .partner-user-fields label {
  font-size: 12px;
  font-weight: normal;
}
.partner-user-form .partner-user-fields .partner-user-input-field {
  border-radius: 5px;
  font-weight: 900;
  height: 40px;
  color: #787878;
}
.partner-user-form .partner-user-fields .name {
  width: 186px;
}
.partner-user-form .partner-user-fields .email {
  width: 243px;
}
.partner-user-form .partner-user-fields .partner-user-select-field {
  height: 40px;
}
.partner-user-form .partner-user-fields .group {
  width: 184px;
}
.partner-user-form .blocked-field-container .blocked, .partner-user-form .blocked-field-container .checkbox-form-control {
  display: inline;
}
.partner-user-form .blocked-field-container .blocked-description {
  padding-top: 2px;
  margin-left: 32px;
  font-size: 14px;
}
.partner-user-form .partner-user-submit-button {
  display: block;
  height: 41px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border-radius: 5px;
  margin: 40px auto 0 auto;
  background: #6D778B;
  border: none;
}
.partner-user-form .partner-user-submit-button:focus {
  border: none;
}
.partner-user-form .asterisk {
  color: #61B4FF;
  padding-left: 1px;
}
.partner-user-form .disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.dashboard-in-team .breadcrumb-bar {
  padding: 0px;
}
.dashboard-in-team .breadcrumb-bar button, .dashboard-in-team .breadcrumb-bar input {
  border: none;
}
.dashboard-in-team .breadcrumb-bar button:focus, .dashboard-in-team .breadcrumb-bar input:focus {
  outline: none;
}
.dashboard-in-team .breadcrumb-bar .management-row {
  background: #5B6272;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 19px 20px;
}
.dashboard-in-team .breadcrumb-bar .management-row .search-container {
  height: 30px;
  width: 443px;
}
.dashboard-in-team .breadcrumb-bar .management-row .search-container .search-input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
  font-weight: 900;
}
.dashboard-in-team .breadcrumb-bar .management-row .search-container .search-input:focus {
  border-color: #DCDCDC;
}
.dashboard-in-team .breadcrumb-bar .management-row .add-partner-user-button {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
  background: #61B4FF;
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  z-index: 9999;
  font-weight: 900;
}
.dashboard-in-team .breadcrumb-bar .management-row .add-partner-user-button:hover {
  background: #359bf5;
}

.team-list {
  background: #FFFFFF;
}
.team-list .partner-user-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  height: calc(100vh - 100px);
  overflow: scroll;
}
.team-list .partner-user-table-container .loading-indicator {
  position: absolute;
  top: 250px;
  left: 50%;
  height: 75px;
  width: 75px;
  transform: translate(-50%, -50%);
}
.team-list .partner-user-table-container .no-results-container {
  position: absolute;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.team-list .partner-user-table-container .no-results-container .no-results {
  padding: 18px 26px 18px 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #F5F5F5;
  border-radius: 5px;
}
.team-list .partner-user-table-container .no-results-container .no-results .no-results-found-header {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #CC5062;
}
.team-list .partner-user-table-container .no-results-container .no-results .you-can-search-on {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}
.team-list .partner-user-table-container .no-results-container .no-results .fields-to-search-on .field-to-search-on {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding: 0 !important;
  margin: 0 !important;
}
.team-list .partner-user-table {
  border-collapse: collapse;
  width: 100%;
}
.team-list .partner-user-table .hideTableRows {
  visibility: hidden;
}
.team-list .partner-user-table .hideTableRows tr .resend-invite-button, .team-list .partner-user-table .hideTableRows tr .edit-partner-user-button, .team-list .partner-user-table .hideTableRows tr .view-patients-button {
  visibility: hidden;
}
.team-list .partner-user-table thead.hideTableHead {
  visibility: hidden !important;
}
.team-list .partner-user-table thead tr:hover {
  background: inherit;
}
.team-list .partner-user-table td, .team-list .partner-user-table th {
  padding: 3px 0;
}
.team-list .partner-user-table th {
  font-weight: normal;
  font-size: 12px;
}
.team-list .partner-user-table th.sortable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.team-list .partner-user-table td {
  font-weight: 900;
  font-size: 14px;
}
.team-list .partner-user-table tr {
  cursor: default;
}
.team-list .partner-user-table tr td:first-child, .team-list .partner-user-table tr th:first-child {
  padding-left: 20px;
}
.team-list .partner-user-table tr .not-available {
  padding-left: 10px;
}
.team-list .partner-user-table tr:hover {
  background: #DCF1FF;
}
.team-list .partner-user-table tr:hover .resend-invite-button, .team-list .partner-user-table tr:hover .edit-partner-user-button, .team-list .partner-user-table tr:hover .view-patients-button {
  visibility: visible;
}
.team-list .partner-user-table .tdWithButtonsExtraWidth {
  width: 344px !important;
}
.team-list .partner-user-table .td-with-buttons {
  text-align: right;
  padding-right: 20px;
  width: 300px;
}
.team-list .partner-user-table .td-with-buttons .resend-invite-button, .team-list .partner-user-table .td-with-buttons .edit-partner-user-button, .team-list .partner-user-table .td-with-buttons .view-patients-button {
  visibility: hidden;
  height: 25px;
  background: #6D778B;
  border: none;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  border-radius: 5px;
}
.team-list .partner-user-table .td-with-buttons .resend-invite-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
  margin-right: 15px;
}
.team-list .partner-user-table .td-with-buttons .edit-partner-user-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
}
.team-list .partner-user-table .td-with-buttons .view-patients-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 8px;
  margin-right: 15px;
}
.team-list .partner-user-table .td-with-buttons .resend-invite-button:focus, .team-list .partner-user-table .td-with-buttons .edit-partner-user-button:focus, .team-list .partner-user-table .td-with-buttons .view-patients-button:focus {
  outline: none;
}
.team-list .partner-user-table .edit-icon {
  margin-right: 5px;
}
.team-list .partner-user-table .clickedRow {
  background: #61B4FF !important;
  color: #FFFFFF !important;
}
.team-list .partner-user-table .clickedRow .resend-invite-button, .team-list .partner-user-table .clickedRow .edit-partner-user-button, .team-list .partner-user-table .clickedRow .view-patients-button {
  visibility: visible;
}
.team-list .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  color: #DCDCDC;
  margin-left: 5px;
}

.portal-container-in-team .close-button {
  color: #787878;
}
.portal-container-in-team .resend-invite {
  display: flex;
  padding: 10px 10px 0 10px;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  background-color: #FAFAFA;
}
.portal-container-in-team .resend-invite .resend-invite-header {
  margin-bottom: 30px;
  font-size: 16px;
  color: #787878;
}
.portal-container-in-team .resend-invite .resend-invite-button {
  height: 30px;
  padding: 0 10px;
  background-color: #6D778B;
  border-radius: 5px;
  color: #FFFFFF;
  border: none;
}
.portal-container-in-team .resend-invite .resend-invite-button:focus {
  outline: none;
}
.portal-container-in-team .resend-invite .resend-invite-button:hover {
  opacity: 0.9;
}

.dashboard-in-patients {
  overflow-y: hidden;
}
.dashboard-in-patients .breadcrumb-bar {
  padding: 0;
}
.dashboard-in-patients .breadcrumb-bar button, .dashboard-in-patients .breadcrumb-bar input {
  border: none;
}
.dashboard-in-patients .breadcrumb-bar button:focus, .dashboard-in-patients .breadcrumb-bar input:focus {
  outline: none;
}
.dashboard-in-patients .breadcrumb-bar .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  color: #FFFFFF;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 20px 0 20px;
  height: 50px;
  background: #5B6272;
  color: #ffffff;
  font-size: 14;
  font-weight: 900;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left {
  display: flex;
  padding-bottom: 10px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter {
  font-size: 14px;
  padding: 0 20px 0 20px;
  margin-right: 1px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background: #6D778B;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter .group-filter-label {
  line-height: 16px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper {
  position: relative;
  width: 156px;
  padding: 0;
  max-height: 218px;
  display: flex;
  justify-content: flex-start;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-label {
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  height: 100%;
  width: 100%;
  align-items: center;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-label span {
  display: inline-block;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container {
  position: absolute;
  left: -2px;
  top: 40px;
  border-top: 3px solid #f5f5f5;
  border-radius: 5px;
  background: #FFFFFF;
  max-height: 218px;
  width: 157px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-searchbox {
  width: 100%;
  overflow-x: hidden;
  height: 22px;
  color: #787878;
  padding: 5px 10px 12px 10px;
  border-bottom: 2px solid #e9e9e9;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu {
  margin-bottom: 0px;
  max-height: 181px;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding-left: 10px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child:first-child {
  padding-top: 4px !important;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child:hover {
  background: #DCF1FF;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child.no-results:hover, .dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter-submenu-wrapper .group-filter-submenu-container .group-filter-submenu .group-filter-child.too-many-results:hover {
  cursor: not-allowed;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .group-filter:hover {
  background: #848EA0;
}
.dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .selectedGroupFilter, .dashboard-in-patients .breadcrumb-bar .partner-admin-row .partner-admin-row-left .selectedGroupFilter:hover {
  background: #A2ACBC;
}
.dashboard-in-patients .breadcrumb-bar .non-admin-keyword-search {
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container {
  height: 30px;
  margin-left: 9px;
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container .search-field-input {
  height: 100%;
  font-weight: 900;
  flex-shrink: 0;
  width: 353px;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container .asNonAdmin {
  width: 303px;
  font-weight: 600;
}
.dashboard-in-patients .breadcrumb-bar .search-field-container .search-field-button {
  height: 30px;
  width: 85px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #61B4FF;
  color: #FFF;
}
.dashboard-in-patients .breadcrumb-bar .add-patient-button {
  height: 30px;
  width: 115px;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
  background: #61B4FF;
}
.dashboard-in-patients .breadcrumb-bar .add-patient-button:hover {
  background: #359bf5;
}
.dashboard-in-patients .breadcrumb-bar .addPatientButtonAsOwner {
  float: right;
  background: #6D778B;
  color: #FFFFFF;
}
.dashboard-in-patients .breadcrumb-bar .addPatientButtonAsOwner:hover {
  background: #5c6375;
}
.dashboard-in-patients .breadcrumb-bar .filters-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0 20px;
  height: 50px;
  width: 100vw;
  background: #A2ACBC;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filters {
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filters .search-container {
  height: 30px;
  width: 353px;
  margin-left: 9px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filters .search-container .search-input {
  height: 100%;
  width: 100%;
  font-weight: 900;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter {
  border-radius: 10px 10px 0 0;
  background: #B6C0D0;
  height: 100%;
  padding: 0px 20px;
  margin-right: 1px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group {
  height: 100%;
  display: flex;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-label, .dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #646464;
  line-height: 16px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-label {
  font-size: 14px;
  padding-right: 8px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filter-number {
  font-size: 18px;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter .filter-text-group .filterNumberImportant {
  color: #DB5E4B !important;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter:hover {
  background: #C6CEDB;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .filter:hover .filter-text-group .filter-label, .dashboard-in-patients .breadcrumb-bar .filters-container .filter:hover .filter-text-group .filter-number {
  color: #646464;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter, .dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter:hover {
  background: #FFFFFF;
}
.dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter .filter-text-group .filter-label, .dashboard-in-patients .breadcrumb-bar .filters-container .selectedFilter:hover .filter-text-group .filter-label {
  color: #646464 !important;
}

.patients {
  display: flex;
  flex-direction: column;
}
.patients button, .patients input {
  border: none;
}
.patients .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: 9px;
  color: #DCDCDC;
}
.patients .filters {
  display: flex;
  padding: 10px 20px 0 20px;
  height: 50px;
  width: 100vw;
  background: #A2ACBC;
}
.patients .filters .filter {
  border-radius: 10px 10px 0 0;
  background: #B6C0D0;
  height: 100%;
  padding: 0px 20px;
  margin-right: 1px;
}
.patients .filters .filter .filter-text-group {
  height: 100%;
  display: flex;
}
.patients .filters .filter .filter-text-group .filter-label, .patients .filters .filter .filter-text-group .filter-number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #646464;
  line-height: 16px;
}
.patients .filters .filter .filter-text-group .filter-label {
  font-size: 14px;
  padding-right: 8px;
}
.patients .filters .filter .filter-text-group .filter-number {
  font-size: 18px;
}
.patients .filters .filter .filter-text-group .filterNumberImportant {
  color: #DB5E4B !important;
}
.patients .filters .filter:hover {
  background: #C6CEDB;
}
.patients .filters .filter:hover .filter-text-group .filter-label, .patients .filters .filter:hover .filter-text-group .filter-number {
  color: #646464;
}
.patients .filters .selectedFilter, .patients .filters .selectedFilter:hover {
  background: #FFFFFF;
}
.patients .filters .selectedFilter .filter-text-group .filter-label, .patients .filters .selectedFilter:hover .filter-text-group .filter-label {
  color: #646464 !important;
}
.patients .keyword-search-help-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
}
.patients .keyword-search-help-container .keyword-search-help {
  display: flex;
  width: 440px;
}
.patients .keyword-search-help-container .keyword-search-help .keyword-search-text-content {
  margin-left: 14px;
  padding-top: 35px;
}
.patients .keyword-search-help-container .keyword-search-help .keyword-search-text-content .main-text {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
}
.patients .search-status {
  margin-top: 10px;
  margin-left: 20px;
}
.patients .search-status .search-status-text, .patients .search-status .search-status-number {
  font-weight: 900;
  color: #646464;
}
.patients .search-status .search-status-text {
  font-size: 14px;
  padding-right: 8px;
}
.patients .search-status .search-status-number {
  font-size: 18px;
}
.patients .patient-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 140px);
  overflow: scroll;
}
.patients .patient-table-container .loading-indicator {
  position: -webkit-sticky;
  position: sticky;
  left: 50%;
  bottom: 50%;
  height: 75px;
  width: 75px;
}
.patients .patient-table-container .no-results-container {
  position: absolute;
  z-index: -1;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.patients .patient-table-container .no-results-container .no-results {
  padding: 18px 26px 18px 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #F5F5F5;
  border-radius: 5px;
}
.patients .patient-table-container .no-results-container .no-results .no-results-found-header {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #CC5062;
}
.patients .patient-table-container .no-results-container .no-results .you-can-search-on {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}
.patients .patient-table-container .no-results-container .no-results .fields-to-search-on .field-to-search-on {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding: 0 !important;
  margin: 0 !important;
}
.patients .patient-table-container .patient-table {
  margin-top: 30px;
  margin-bottom: 25px;
  border-collapse: collapse;
  width: 100%;
}
.patients .patient-table-container .patient-table .hideTableRows {
  visibility: hidden;
}
.patients .patient-table-container .patient-table .hideTableRows tr .view-patient-details-button {
  visibility: hidden;
}
.patients .patient-table-container .patient-table thead.hideTableHead {
  visibility: hidden;
}
.patients .patient-table-container .patient-table thead tr:hover {
  background: inherit;
}
.patients .patient-table-container .patient-table td, .patients .patient-table-container .patient-table th {
  padding: 3px 0;
}
.patients .patient-table-container .patient-table th {
  font-weight: normal;
  font-size: 12px;
}
.patients .patient-table-container .patient-table th:hover {
  text-decoration: underline;
  cursor: pointer;
}
.patients .patient-table-container .patient-table td {
  font-weight: 900;
  font-size: 14px;
}
.patients .patient-table-container .patient-table td.negative {
  color: #DB5E4B;
}
.patients .patient-table-container .patient-table tr {
  cursor: default;
}
.patients .patient-table-container .patient-table tr td:first-child, .patients .patient-table-container .patient-table tr th:first-child {
  padding-left: 20px;
}
.patients .patient-table-container .patient-table tr:hover {
  background: #DCF1FF;
}
.patients .patient-table-container .patient-table tr:hover .view-patient-details-button {
  visibility: visible;
}
.patients .patient-table-container .patient-table .td-with-button {
  text-align: right;
  padding-right: 20px;
}
.patients .patient-table-container .patient-table .td-with-button .view-patient-details-button {
  visibility: hidden;
  height: 25px;
  width: 103px;
  background: #6D778B;
  border: none;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  border-radius: 5px;
}
.patients .patient-table-container .patient-table .td-with-button .view-patient-details-button:focus {
  outline: none;
}
.patients .patient-table-container .patient-table .edit-icon {
  margin-right: 5px;
}
.patients .patient-table-container .patient-table .clickedRow {
  background: #61B4FF !important;
  color: #FFFFFF !important;
}
.patients .patient-table-container .patient-table .clickedRow .view-patient-details-button {
  visibility: visible;
}
.patients .search-loading-indicator {
  margin-left: 50vw;
  margin-top: 40vh;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .view-patient-details-button {
    width: 120px !important;
  }
}
@-moz-document url-prefix() {
  .view-patient-details-button {
    width: 120px !important;
  }
}
.portal-container-in-patients {
  overflow-y: scroll;
}
.portal-container-in-patients .close-button {
  color: #787878;
}
.portal-container-in-patients .modal-panel {
  margin-top: 50px !important;
}

.datatable {
  width: 100%;
  padding: 10px;
}
.datatable .datatable-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.datatable .datatable-top .datatable-name {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.datatable .datatable-top .datatable-name h1 {
  margin: 0;
}
.datatable .datatable-top .datatable-search {
  flex: 1 1;
  margin-left: 100px;
}
.datatable .datatable-top .datatable-search input {
  margin: 0;
  width: 100%;
}
.datatable .MuiDataGrid-columnHeaders {
  background: #5b6272;
}
.datatable .MuiDataGrid-columnHeaders div,
.datatable .MuiDataGrid-columnHeaders button {
  color: white;
  font-weight: bold;
}
.datatable .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:hover {
  background: rgba(0, 0, 0, 0.1);
}
.datatable .MuiDataGrid-row:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05);
}
.datatable p {
  margin: 0;
}
.datatable a {
  color: #4a74e3;
}
.datatable a:hover {
  color: #7394eb;
}

.MuiDataGrid-menu div,
.MuiDataGrid-menu p,
.MuiDataGrid-menu span,
.datatable div,
.datatable p,
.datatable span {
  font-size: 1.4rem;
}
.MuiDataGrid-menu svg,
.datatable svg {
  font-size: 1.6rem;
}

.dashboard-layout #topBars {
  height: auto;
}
.dashboard-layout #root > div {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) .content-container {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) .content-container .workers {
  height: calc(100% - 50px);
}
.dashboard-layout #root > div > div:nth-child(2) .content-container .workers .datatable {
  height: 100%;
}
.dashboard-layout #root > div > div:nth-child(2) .content-container .workers .datatable .MuiTablePagination-actions {
  margin-right: 100px;
}

.portal-container .modal-panel {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.coach-form {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #FAFAFA;
}
.coach-form .react-autosuggest__suggestions-container--open {
  position: absolute;
  background: #FFFFFF;
  z-index: 1;
}
.coach-form .autocomplete-form-control {
  height: 50px;
}
.coach-form .coach-header-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;
}
.coach-form .coach-header-container .coach-form-header {
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 24px;
  color: #787878;
}
.coach-form .coach-fields {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.coach-form .coach-fields label {
  font-size: 12px;
  font-weight: normal;
}
.coach-form .coach-fields .coach-input-field {
  border-radius: 5px;
  font-weight: 900;
  height: 40px;
  color: #787878;
}
.coach-form .coach-fields .name {
  width: 20em;
}
.coach-form .coach-fields .email {
  width: 30em;
}
.coach-form .checkbox-field-container .checkbox, .coach-form .checkbox-field-container .checkbox-form-control {
  display: inline;
}
.coach-form .checkbox-field-container .checkbox-description {
  padding-top: 2px;
  margin-left: 32px;
  font-size: 14px;
}
.coach-form .coach-submit-button {
  display: block;
  height: 41px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border-radius: 5px;
  margin: 40px auto 0 auto;
  background: #6D778B;
  border: none;
}
.coach-form .coach-submit-button:focus {
  border: none;
}
.coach-form .asterisk {
  color: #61B4FF;
  padding-left: 1px;
}
.coach-form .disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.dashboard-in-coaches .breadcrumb-bar {
  padding: 0px;
}
.dashboard-in-coaches .breadcrumb-bar button, .dashboard-in-coaches .breadcrumb-bar input {
  border: none;
}
.dashboard-in-coaches .breadcrumb-bar button:focus, .dashboard-in-coaches .breadcrumb-bar input:focus {
  outline: none;
}
.dashboard-in-coaches .breadcrumb-bar .management-row {
  background: #5B6272;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 19px 20px;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .search-container {
  height: 30px;
  width: 443px;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .search-container .search-input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
  font-weight: 900;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .search-container .search-input:focus {
  border-color: #DCDCDC;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .add-coach-button {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
  background: #61B4FF;
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  z-index: 9999;
  font-weight: 900;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .add-coach-button:hover {
  background: #359bf5;
}

.coaches-list {
  background: #FFFFFF;
}
.coaches-list .coach-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  height: calc(100vh - 100px);
  overflow: scroll;
}
.coaches-list .coach-table-container .loading-indicator {
  position: absolute;
  top: 250px;
  left: 50%;
  height: 75px;
  width: 75px;
  transform: translate(-50%, -50%);
}
.coaches-list .coach-table-container .no-results-container {
  position: absolute;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.coaches-list .coach-table-container .no-results-container .no-results {
  padding: 18px 26px 18px 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #F5F5F5;
  border-radius: 5px;
}
.coaches-list .coach-table-container .no-results-container .no-results .no-results-found-header {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #CC5062;
}
.coaches-list .coach-table-container .no-results-container .no-results .you-can-search-on {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}
.coaches-list .coach-table-container .no-results-container .no-results .fields-to-search-on .field-to-search-on {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding: 0 !important;
  margin: 0 !important;
}
.coaches-list .coach-table {
  border-collapse: collapse;
  width: 100%;
}
.coaches-list .coach-table .hideTableRows {
  visibility: hidden;
}
.coaches-list .coach-table .hideTableRows tr .resend-invite-button, .coaches-list .coach-table .hideTableRows tr .edit-coach-button, .coaches-list .coach-table .hideTableRows tr .view-patients-button {
  visibility: hidden;
}
.coaches-list .coach-table thead.hideTableHead {
  visibility: hidden !important;
}
.coaches-list .coach-table thead tr:hover {
  background: inherit;
}
.coaches-list .coach-table td, .coaches-list .coach-table th {
  padding: 3px 0;
}
.coaches-list .coach-table th {
  font-weight: normal;
  font-size: 12px;
}
.coaches-list .coach-table th.sortable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.coaches-list .coach-table td {
  font-weight: 900;
  font-size: 14px;
}
.coaches-list .coach-table tr {
  cursor: default;
}
.coaches-list .coach-table tr td:first-child, .coaches-list .coach-table tr th:first-child {
  padding-left: 20px;
}
.coaches-list .coach-table tr .not-available {
  padding-left: 10px;
}
.coaches-list .coach-table tr:hover {
  background: #DCF1FF;
}
.coaches-list .coach-table tr:hover .resend-invite-button, .coaches-list .coach-table tr:hover .edit-coach-button, .coaches-list .coach-table tr:hover .view-patients-button {
  visibility: visible;
}
.coaches-list .coach-table .td-with-buttons {
  text-align: right;
  padding-right: 20px;
}
.coaches-list .coach-table .td-with-buttons .resend-invite-button, .coaches-list .coach-table .td-with-buttons .edit-coach-button, .coaches-list .coach-table .td-with-buttons .view-patients-button {
  visibility: hidden;
  height: 25px;
  background: #6D778B;
  border: none;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  border-radius: 5px;
}
.coaches-list .coach-table .td-with-buttons .resend-invite-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
  margin-right: 15px;
}
.coaches-list .coach-table .td-with-buttons .edit-coach-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
}
.coaches-list .coach-table .td-with-buttons .view-patients-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 8px;
  margin-right: 15px;
}
.coaches-list .coach-table .td-with-buttons .resend-invite-button:focus, .coaches-list .coach-table .td-with-buttons .edit-coach-button:focus, .coaches-list .coach-table .td-with-buttons .view-patients-button:focus {
  outline: none;
}
.coaches-list .coach-table .edit-icon {
  margin-right: 5px;
}
.coaches-list .coach-table .clickedRow {
  background: #61B4FF !important;
  color: #FFFFFF !important;
}
.coaches-list .coach-table .clickedRow .resend-invite-button, .coaches-list .coach-table .clickedRow .edit-coach-button, .coaches-list .coach-table .clickedRow .view-patients-button {
  visibility: visible;
}
.coaches-list .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  color: #DCDCDC;
  margin-left: 5px;
}

.portal-container-in-coaches .close-button {
  color: #787878;
}
.portal-container-in-coaches .resend-invite {
  display: flex;
  padding: 10px 10px 0 10px;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  background-color: #FAFAFA;
}
.portal-container-in-coaches .resend-invite .resend-invite-header {
  margin-bottom: 30px;
  font-size: 16px;
  color: #787878;
}
.portal-container-in-coaches .resend-invite .resend-invite-button {
  height: 30px;
  padding: 0 10px;
  background-color: #6D778B;
  border-radius: 5px;
  color: #FFFFFF;
  border: none;
}
.portal-container-in-coaches .resend-invite .resend-invite-button:focus {
  outline: none;
}
.portal-container-in-coaches .resend-invite .resend-invite-button:hover {
  opacity: 0.9;
}

.blocked-container {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  top: 50px;
  left: 0;
  right: 0;
  background: #FFFFFF;
}
.blocked-container .message {
  font-size: 16px;
  border-radius: 5px;
  position: absolute;
  top: 25%;
  left: 50%;
  text-align: center;
  margin-left: -150px;
  padding: 20px;
  background: white;
}
.blocked-container img {
  margin-bottom: 20px;
}
.blocked-container h3 {
  color: #505050;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 25px;
}
.blocked-container p {
  font-size: 12px;
  margin-bottom: 0;
}
.blocked-container p a {
  color: #0866d1;
}

.update-password-container .error-message {
  display: block;
  margin-left: 45px !important;
}
.update-password-container .form-group {
  display: block !important;
}
.update-password-container .btn-main {
  width: 280px;
  margin-left: auto;
  margin-right: auto;
  color: white !important;
  text-decoration: none !important;
}

.registration-container {
  min-width: 925px;
  min-height: 578px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.registration-container .registration-wrapper .peerwell-logo-container img {
  margin-bottom: 20px;
  height: 38px;
  width: 259px;
  object-fit: cover;
}
.registration-container .registration-wrapper .registrant-email {
  margin-bottom: 22px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 900;
  color: #787878;
}
.registration-container .registration-wrapper .registration-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.registration-container .registration-wrapper .registration-form .form-group {
  margin-bottom: 0;
}
.registration-container .registration-wrapper .registration-form .form-group .registration-field {
  border: 1px solid #B4B4B4;
  width: 250px;
  height: 40px;
  margin-top: 0;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 10px;
  color: #787878;
  margin-bottom: 10px;
}
.registration-container .registration-wrapper .registration-form .form-group .registration-field:focus {
  border-color: #66afe9 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.registration-container .registration-wrapper .registration-form .form-group .registration-field.hidden {
  margin-bottom: 0;
}
.registration-container .registration-wrapper .registration-form .dummy-field {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.registration-container .registration-wrapper .registration-form .registration-button {
  margin-top: 10px;
  background: #6D778B;
  width: 250px;
  height: 40px;
  color: #ffffff;
  font-weight: 900;
  border-radius: 5px;
  border: none;
}
.registration-container .registration-wrapper .registration-form .registration-button:focus {
  outline: none;
}
.registration-container .registration-wrapper .registration-form .registration-button:hover {
  opacity: 0.9;
}
.registration-container .registration-wrapper .registration-form .password-requirements {
  position: absolute;
  visibility: hidden;
  top: -20px;
  left: 270px;
  width: 400px;
}
.registration-container .registration-wrapper .registration-form .password-requirements .requirement {
  display: flex;
  align-items: center;
}
.registration-container .registration-wrapper .registration-form .password-requirements .requirementSatisfied .checkmark {
  visibility: visible;
}
.registration-container .registration-wrapper .registration-form .password-requirements .requirementSatisfied .description {
  color: #61B4FF;
}
.registration-container .registration-wrapper .registration-form .password-requirements.asCareTeamUser {
  top: 20px;
}
.registration-container .registration-wrapper .registration-form .showPasswordRequirements {
  visibility: visible;
}
.registration-container .registration-wrapper .registration-form .hidePasswordRequirements .requirementSatisfied .checkmark {
  visibility: hidden;
}
.registration-container .registration-wrapper .registration-form .password-repeat-requirements {
  display: none;
}
.registration-container .registration-wrapper .registration-form .error-message {
  position: absolute;
  display: none;
}
.registration-container .registration-wrapper .registration-form .has-error {
  width: 250px;
  height: 40px;
}
.registration-container .registration-wrapper .registration-form .checkmark {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 12px;
  background: url(/static/media/checkmark.dec0c9a8.svg) center center no-repeat #61B4FF;
  background-size: 14px;
  border: none;
  border-radius: 50%;
  visibility: hidden;
}
.registration-container .registration-wrapper .registration-form .disabled {
  background: #DCDCDC;
  cursor: not-allowed;
}
.registration-container .registration-wrapper .registration-form .disabled:hover {
  opacity: 1;
}


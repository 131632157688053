.partner-user-form {
  width: 100%;
  background: #FAFAFA;
}
.partner-user-form .react-autosuggest__suggestions-container--open {
  position: absolute;
  background: #FFFFFF;
  z-index: 1;
}
.partner-user-form .autocomplete-form-control {
  height: 50px;
}
.partner-user-form .form-group {
  margin-bottom: 0;
}
.partner-user-form .form-group .has-error {
  border-color: #DB5E4B !important;
}
.partner-user-form .form-group .label-has-error {
  color: #DB5E4B !important;
}
.partner-user-form .form-group .label-has-error .asterisk {
  color: #DB5E4B !important;
}
.partner-user-form .form-group {
  position: relative;
}
.partner-user-form .form-group label {
  margin-top: 0;
}
.partner-user-form .form-group .error-message {
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 12px;
}
.partner-user-form .partner-user-header-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;
}
.partner-user-form .partner-user-header-container .partner-user-form-header {
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 24px;
  color: #787878;
}
.partner-user-form .partner-user-fields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.partner-user-form .partner-user-fields .admin-only-fields {
  display: flex;
  align-items: flex-end;
}
.partner-user-form .partner-user-fields .admin-only-fields .customer {
  width: 184px;
  height: 40px !important;
}
.partner-user-form .partner-user-fields .admin-only-fields .userable-type {
  width: 124px;
}
.partner-user-form .partner-user-fields label {
  font-size: 12px;
  font-weight: normal;
}
.partner-user-form .partner-user-fields .partner-user-input-field {
  border-radius: 5px;
  font-weight: 900;
  height: 40px;
  color: #787878;
}
.partner-user-form .partner-user-fields .name {
  width: 186px;
}
.partner-user-form .partner-user-fields .email {
  width: 243px;
}
.partner-user-form .partner-user-fields .partner-user-select-field {
  height: 40px;
}
.partner-user-form .partner-user-fields .group {
  width: 184px;
}
.partner-user-form .blocked-field-container .blocked, .partner-user-form .blocked-field-container .checkbox-form-control {
  display: inline;
}
.partner-user-form .blocked-field-container .blocked-description {
  padding-top: 2px;
  margin-left: 32px;
  font-size: 14px;
}
.partner-user-form .partner-user-submit-button {
  display: block;
  height: 41px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border-radius: 5px;
  margin: 40px auto 0 auto;
  background: #6D778B;
  border: none;
}
.partner-user-form .partner-user-submit-button:focus {
  border: none;
}
.partner-user-form .asterisk {
  color: #61B4FF;
  padding-left: 1px;
}
.partner-user-form .disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

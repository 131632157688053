.placeholder-select-field label.disabled {
  color: #C8C8C8;
}
.placeholder-select-field .optional-label {
  font-weight: normal;
}
.placeholder-select-field .Select.is-focused > .Select-control {
  border-color: #66afe9 !important;
}
.placeholder-select-field .is-focused {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.placeholder-select-field .Select-option {
  box-shadow: none !important;
  color: #787878 !important;
  font-size: 14px !important;
  font-weight: 900;
}
.placeholder-select-field .Select-option:hover, .placeholder-select-field .Select-option.is-focused {
  background-color: rgba(0, 0, 0, 0.07) !important;
}
.placeholder-select-field .Select-option.is-selected {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.placeholder-select-field .Select-value-label {
  color: #787878 !important;
  font-size: 14px !important;
}
.placeholder-select-field .Select-control {
  border: 1px solid #ccc;
  height: 40px;
  font-weight: 900;
  cursor: pointer !important;
}
.placeholder-select-field .Select-control .Select-input {
  color: #787878;
}
.placeholder-select-field .Select-control .Select-value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.placeholder-select-field .Select-control .Select-placeholder {
  line-height: 38px;
  color: #c8c8c8;
  font-weight: 900;
  font-size: 14px;
}
.placeholder-select-field .Select.disabled .Select-control {
  cursor: not-allowed !important;
}

.user-actions {
  margin-bottom: 20px;
}
.user-actions .btn {
  margin-right: 10px;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
}

.blocked-container {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  top: 50px;
  left: 0;
  right: 0;
  background: #FFFFFF;
}
.blocked-container .message {
  font-size: 16px;
  border-radius: 5px;
  position: absolute;
  top: 25%;
  left: 50%;
  text-align: center;
  margin-left: -150px;
  padding: 20px;
  background: white;
}
.blocked-container img {
  margin-bottom: 20px;
}
.blocked-container h3 {
  color: #505050;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 25px;
}
.blocked-container p {
  font-size: 12px;
  margin-bottom: 0;
}
.blocked-container p a {
  color: #0866d1;
}

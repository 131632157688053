.arc-container {
  overflow: auto;
  margin-top: 15px;
}
.arc-container .arc {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 15px;
}
.arc-container svg {
  width: 100%;
  height: 100%;
  margin: 0;
}
.arc-container p {
  margin-top: 40px;
}

.home-assessment {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-assessment .btn {
  box-sizing: border-box;
  margin-bottom: 25px;
  width: 150px;
}

.home-assessment h1 {
  align-self: flex-start;
}

.box {
  width: 100%;
  height: 150px;
  background: #F4F4F4;
  border: 1px solid gray;
  margin-bottom: 10px;
  padding: 0 20px;
}

.home-score {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.numberCircle {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  padding: 15px 30px;
  background: #b74344;
  color: #fff;
  text-align: center;
  font: 65px Arial, sans-serif;
  font-weight: bold;
  margin-right: 20px;
}

.label_title {
  padding-top: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  margin-bottom: 10px;
}

.column {
  float: left;
  width: 200px;
  height: 200px;
  padding: 10px 5px;
  margin-bottom: 25px;
}

.column img {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid gray;
}

.invite-portal-container {
  width: 100%;
  padding: 0px 0px;
}

.dashboard-in-invite-portal {
  background-color: #afd371;
}

.dashboard-in-invite-portal #topBars {
  background-color: #dcf1ff;
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row-apps {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.row-apps img {
  max-width: 70px;
  max-height: 70px;
}

.add-top-margin {
  margin-top: 20px;
}

.bannerbox img {
  max-width: 100%;
  max-height: 100%;
}

.bannerbox {
  margin-top: 220px;
  text-align: center;
}

.jumbotron {
  border: 1px solid grey;
}

.checkbox-form-control {
  overflow: hidden;
}

.confirm .checkbox-form-control {
  height: 120px !important;
}

.radio-list.horizontal {
  margin-top: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.program_checkbox {
  margin-top: 10px;
}

.program_checkbox input[type=checkbox] {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 0px !important;
}

.content {
  margin: 0 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content .jumbotron {
  padding: 25px 30px;
}
.content .btn {
  width: 300px;
  text-align: center;
}

.filler-background {
  text-align: left;
  background-color: #dcf1ff;
  background-image: url("./../../assets/patient_banner_background.png");
  background-repeat: no-repeat;
  background-position: top, center;
  height: 323px;
}

.checkbox-form-control .error-message {
  margin-left: 50px;
  margin-bottom: 20px;
}

.checkbox-form-control label {
  margin-top: 10px !important;
}

.program_checkbox label {
  margin-top: 10px;
}

.preview-col {
  padding: 20px 15px;
}

.preview {
  position: relative;
  background-color: #eeeeee;
  border: 1px solid grey;
  padding: 25px 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}
.preview img {
  width: 100%;
  margin-bottom: 10px;
}

.scaledPreview {
  min-height: 330px;
}

.scaledPreviewInModal {
  position: relative;
  max-height: 550px;
  margin-right: 15px;
  overflow: auto;
}

.divider {
  position: absolute;
  top: 812px;
  left: 4px;
  right: 0;
  height: 1px;
  opacity: 0.5;
  border-bottom: 2px dashed black;
}

.wrapper {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-top: 10pt;
  margin-bottom: 10pt;
}

.wrapper:after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.event-item {
  margin: 8px 0;
}
.event-item .event-item-button {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
  padding: 15px 20px;
  text-align: left;
  width: 100%;
}
.event-item .event-item-button:hover {
  background: rgba(255, 255, 255, 0.3);
}
.event-item .checkmark {
  width: 30px;
  height: 30px;
  margin: 10px 15px 10px 0px;
  border-radius: 50%;
  border: 2px solid rgb(65, 190, 15);
  flex-shrink: 0;
}
.event-item .checkmark.completed {
  background: rgb(65, 190, 15);
}
.event-item .title {
  font-size: 20px;
  margin: 0 0 5px 0;
}
.event-item .title.completed {
  text-decoration: line-through;
}
.event-item .subtext {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
}

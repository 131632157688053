.colored-bar {
  width: 100%;
}
.colored-bar .background-color {
  background: #DBDCDB;
  height: 35px;
}
.colored-bar .inner-colored-bar {
  background: #6CCC58;
  height: 100%;
  width: 100%;
}
.colored-bar .sections {
  table-layout: fixed;
  width: 100%;
  margin-top: 10px;
}
.colored-bar .sections td {
  text-align: center;
}
.colored-bar .sections td:last-child {
  text-align: right;
}
.colored-bar .sections td:first-child {
  text-align: left;
}

.colored-bar-positive .inner-colored-bar {
  background: #3CBF3D;
}

.colored-bar-neutral .inner-colored-bar {
  background: #FFBA31;
}

.colored-bar-negative .inner-colored-bar {
  background: #DB5E4B;
}

.user-view .warning {
  color: #DB5E4B;
}

.user-events {
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -80px;
}
.user-events li {
  display: block;
  width: calc(100% + 35px);
  box-sizing: border-box;
  color: white;
  background-color: #FFBA31;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  font-weight: lighter;
  padding: 0 20px;
}
.user-events li.neutral {
  background-color: #FFBA31;
}
.user-events li.negative {
  background-color: #DB5E4B;
}
.user-events li.positive {
  background-color: #3CBF3D;
}

.SplitPane .sidebar, .SplitPane .page-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: calc(100vh - 100px) !important;
  overflow-y: scroll;
}

.engagement-card-interaction-group {
  display: flex;
  justify-content: space-evenly;
}
.engagement-card-interaction-group .child-1 {
  flex: 1;
  margin-right: 30px;
}
.engagement-card-interaction-group .child-2 {
  flex: 1;
  margin-left: 30px;
}

.external-service-block {
  padding-bottom: 25px;
}

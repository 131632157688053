.dashboard-as-default .navigation-bar {
  background: #5B6272;
}

.dashboard-as-owner .navigation-bar, .dashboard-as-care_team_user .navigation-bar {
  background: #A2ACBC;
}

.navigation-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  position: relative;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}
.navigation-bar .nav-pages-container {
  flex-grow: 1;
}
.navigation-bar .nav-pages-container .nav-pages {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page {
  padding-right: 22px;
  font-weight: 900;
  list-style: none;
  color: #FFFFFF;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page a, .navigation-bar .nav-pages-container .nav-pages .nav-page a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.navigation-bar .nav-pages-container .nav-pages .withChild a {
  padding-left: 15px;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page .isChild {
  padding-right: 0;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page .isChild a {
  color: #656565;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined {
  position: relative;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > a::after, .navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > span::after {
  content: "";
  position: absolute;
  background: #FFFFFF;
  display: block;
  height: 1px;
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > a::after {
  width: calc(100% - 20px);
}
.navigation-bar .nav-pages-container .nav-pages .nav-page.underlined > span::after {
  width: calc(100% - 45px);
}
.navigation-bar .help-link, .navigation-bar .help-link:hover {
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  text-decoration: none;
}
.navigation-bar .help-link .question-circle, .navigation-bar .help-link:hover .question-circle {
  padding-right: 7px;
  margin-top: 2px;
}
.navigation-bar .help-link .help-text, .navigation-bar .help-link:hover .help-text {
  margin-top: 2px;
}
.navigation-bar .navigation-dropdown {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -20px;
  right: -40px;
  top: 34px !important;
  overflow: visible;
  min-width: unset;
  width: fit-content;
}
.navigation-bar .navigation-dropdown li {
  font-size: 14px !important;
  color: #6D778B;
}
.navigation-bar .navigation-dropdown li a {
  font-weight: 510 !important;
  color: #6D778B;
}
.navigation-bar .account-options-container {
  position: relative;
  margin-right: 6px;
}
.navigation-bar .account-options-container .user-fullname {
  color: #FFFFFF;
  font-weight: 510;
  font-size: 14px;
  white-space: nowrap;
  vertical-align: middle;
}
.navigation-bar .account-options-container .account-options {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -20px;
  right: 0;
  top: 33px !important;
}
.navigation-bar .account-options-container .account-options li {
  font-size: 14px !important;
}
.navigation-bar .account-options-container .account-options li a {
  font-weight: 510 !important;
  color: #6D778B;
}
.navigation-bar .logo {
  flex-shrink: 0;
  width: 124px;
}
.navigation-bar .logo img {
  height: 100%;
  width: 100%;
}
.navigation-bar .partner-logo {
  max-width: 300px;
  height: 20px;
  margin-right: 40px;
}
.navigation-bar .nav-divider {
  height: 30px;
  border-left: 1px solid #A2ACBC;
  padding-right: 20px;
  margin-left: 20px;
}
.navigation-bar .main-loading-indicator {
  opacity: 0.5;
  width: 19px;
  height: 19px;
}
.navigation-bar .dropdown-toggle {
  color: #818DA8;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none !important;
}
.navigation-bar .dropdown-menu li:hover {
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.navigation-bar .dropdown-menu a:hover {
  background: none;
  text-decoration: none;
}
.navigation-bar .caret {
  color: #DCDCDC;
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: 8px;
}
.navigation-bar .open .caret {
  transform: rotate(180deg);
}
.navigation-bar .withChild {
  position: relative;
  overflow: visible;
  padding: 0;
}
.navigation-bar .withChild .submenu {
  list-style: none;
  position: absolute;
  left: 100%;
  background-color: #F7F8F7;
  top: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: none;
  padding: 0;
}
.navigation-bar .withChild .submenu li {
  width: 100%;
  padding: 5px 20px;
  white-space: nowrap;
}

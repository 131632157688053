.breadcrumb-bar .menu {
  position: relative;
}
.breadcrumb-bar .open .caret {
  transform: rotate(180deg);
}
.breadcrumb-bar .dropdown {
  padding: 15px 0 0 0;
}
.breadcrumb-bar .dropdown-toggle {
  color: #61B4FF;
}
.breadcrumb-bar .dropdown-menu ul {
  max-height: 300px;
  overflow-y: scroll;
  top: 50px;
  margin-left: 0;
  padding-left: 0;
}
.breadcrumb-bar .dropdown-menu .form-control {
  margin-top: -5px !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  min-width: 300px;
}
.breadcrumb-bar .breadcrumb-menus .dropdown-menu {
  top: 47px;
  left: -21px;
}
.breadcrumb-bar .breadcrumb-menus .dropdown-menu li {
  padding: 6px 20px;
  cursor: pointer;
  display: block;
}
.breadcrumb-bar .breadcrumb-menus .dropdown-menu li:hover {
  text-decoration: underline;
}

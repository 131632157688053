.datatable {
  width: 100%;
  padding: 10px;
}
.datatable .datatable-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.datatable .datatable-top .datatable-name {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.datatable .datatable-top .datatable-name h1 {
  margin: 0;
}
.datatable .datatable-top .datatable-search {
  flex: 1;
  margin-left: 100px;
}
.datatable .datatable-top .datatable-search input {
  margin: 0;
  width: 100%;
}
.datatable .MuiDataGrid-columnHeaders {
  background: #5b6272;
}
.datatable .MuiDataGrid-columnHeaders div,
.datatable .MuiDataGrid-columnHeaders button {
  color: white;
  font-weight: bold;
}
.datatable .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:hover {
  background: rgba(0, 0, 0, 0.1);
}
.datatable .MuiDataGrid-row:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05);
}
.datatable p {
  margin: 0;
}
.datatable a {
  color: #4a74e3;
}
.datatable a:hover {
  color: #7394eb;
}

.MuiDataGrid-menu div,
.MuiDataGrid-menu p,
.MuiDataGrid-menu span,
.datatable div,
.datatable p,
.datatable span {
  font-size: 1.4rem;
}
.MuiDataGrid-menu svg,
.datatable svg {
  font-size: 1.6rem;
}

.update-care-team-member-form {
  text-align: center;
}
.update-care-team-member-form .member-info {
  margin-top: 5px;
}
.update-care-team-member-form .member-info, .update-care-team-member-form .member-job {
  text-align: left;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.update-care-team-member-form .member-info h3, .update-care-team-member-form .member-job h3 {
  margin-bottom: 15px;
}
.update-care-team-member-form .member-info label, .update-care-team-member-form .member-info h3, .update-care-team-member-form .member-job label, .update-care-team-member-form .member-job h3 {
  font-size: 12px;
  font-weight: normal;
  color: #787878;
}
.update-care-team-member-form .member-info select, .update-care-team-member-form .member-info p, .update-care-team-member-form .member-job select, .update-care-team-member-form .member-job p {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 20px;
}
.update-care-team-member-form .btn {
  width: 40%;
  margin-top: 25px;
}
.update-care-team-member-form .animation-group {
  flex-direction: initial;
}
.update-care-team-member-form .animation-group div {
  margin: 0 10px 0 0 !important;
}

.optional-date-container {
  margin-top: -25px;
  margin-bottom: 15px;
}
.optional-date-container label {
  margin-left: 10px;
}

.dateInputField p {
  font-size: 15px;
  font-style: italic;
}

.jumbotron .dateInputField label {
  margin-bottom: 5px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
  background: #FFFFFF;
}

.react-datepicker {
  color: #656565;
  font-size: 1em;
}
.react-datepicker .react-datepicker__day--keyboard-selected {
  background: none;
}
.react-datepicker .react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker .react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker .react-datepicker__day-name, .react-datepicker .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker .react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker .react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker .react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.react-datepicker .react-datepicker__month-read-view, .react-datepicker .react-datepicker__year-read-view {
  font-weight: bold;
  visibility: visible !important;
}
.react-datepicker .react-datepicker__year-read-view--down-arrow, .react-datepicker .react-datepicker__month-read-view--down-arrow {
  border-top-color: #61B4FF !important;
  display: block;
  margin-left: 5px;
}
.react-datepicker .react-datepicker__month-read-view--selected-month, .react-datepicker .react-datepicker__year-read-view--selected-year {
  padding-right: 13px;
}
.react-datepicker .react-datepicker__navigation--next {
  border-left-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__year-option--selected {
  color: #61B4FF !important;
}
.react-datepicker .react-datepicker__year-dropdown, .react-datepicker .react-datepicker__month-dropdown {
  background: white;
}
.react-datepicker .react-datepicker__year-option:hover, .react-datepicker .react-datepicker__month-option:hover {
  background: #f1f1f1 !important;
}
.react-datepicker .react-datepicker__day {
  color: #656565 !important;
}
.react-datepicker .react-datepicker__navigation--years-previous {
  top: 3px !important;
  border-top-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__navigation--years-upcoming {
  top: 0 !important;
  border-bottom-color: #61B4FF !important;
}
.react-datepicker .react-datepicker__day--selected {
  background-color: #61B4FF;
  color: white !important;
}
.react-datepicker .react-datepicker__day--disabled {
  background: #efefef !important;
  cursor: not-allowed;
  opacity: 0.4;
}
.react-datepicker .react-datepicker__current-month {
  color: #656565;
}
.react-datepicker .react-datepicker__day--disabled.react-datepicker__day--selected {
  color: #656565 !important;
  font-weight: bold;
}

.has-error .react-datepicker {
  border-color: #DB5E4B !important;
}

.withDropDowns .react-datepicker .react-datepicker__current-month {
  display: none;
}

.centered {
  text-align: center;
}

.input-container label {
  display: block;
}

.wide-calendar {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.wide-calendar .react-datepicker, .wide-calendar .react-datepicker__header, .wide-calendar .react-datepicker__month-container, .wide-calendar .react-datepicker__month {
  width: 100%;
  margin: 0;
}
.wide-calendar .react-datepicker {
  border-radius: 0 !important;
}
.wide-calendar .react-datepicker__day-name, .wide-calendar .react-datepicker__day {
  box-sizing: border-box;
  width: 14.2857142857%;
  margin: 0 !important;
}
.wide-calendar .react-datepicker__week .react-datepicker__day:last-child {
  border-right: 0 !important;
}
.wide-calendar .react-datepicker__week .react-datepicker__day:first-child {
  border-left: 0 !important;
}
.wide-calendar .react-datepicker__day {
  border-bottom: 0px !important;
  border-radius: 0 !important;
}
.wide-calendar .react-datepicker__day {
  border: 1px solid #ADAEAD;
  border-right: 0;
}
.wide-calendar .react-datepicker__header {
  border-bottom: 0 !important;
}

.has-error .wide-calendar {
  margin-bottom: 0 !important;
}
.has-error .error-message {
  margin-bottom: 20px;
}

.disabled-calendar {
  pointer-events: none;
  opacity: 0.5;
}

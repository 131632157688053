.remove-care-team {
  font-weight: 900;
}
.remove-care-team h1 {
  font-size: 24px;
}
.remove-care-team p {
  font-size: 14px;
  color: #787878;
}
.remove-care-team .btn-container {
  display: flex;
  justify-content: space-between;
}
.remove-care-team .btn {
  height: 40px;
  width: 125px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 900;
}

.pick-date-profile {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}
.pick-date-profile .calendar-container {
  display: flex;
  align-items: flex-start;
}
.pick-date-profile .current-date {
  padding: 0;
  margin: 0;
  font-weight: lighter;
  font-size: 18px;
  color: #868786;
}
.pick-date-profile .noDateSet {
  color: #61B4FF;
}
.pick-date-profile .no-date-group {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 25px;
}
.pick-date-profile .no-date-group .no-date-label {
  font-size: 15px;
  color: #868786;
  font-weight: lighter;
  margin: 0;
}
.pick-date-profile .no-date-group .no-date-checkbox {
  margin: 0px !important;
}
.pick-date-profile .change-date-button {
  display: inline-block;
  width: 100px;
  margin: 5px 0 0 0;
}
.pick-date-profile .change-date-button:focus {
  outline: 0;
}
.pick-date-profile button[disabled] {
  background-color: #99cfff !important;
}
.pick-date-profile .error {
  padding: 0;
  margin: 3px 0;
  font-size: 14px;
}

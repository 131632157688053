.has-error {
  border-color: #DB5E4B !important;
}

.error-message {
  margin-top: 3px;
  display: inline-block;
  color: #DB5E4B !important;
}

.form-group label {
  margin-bottom: 5px;
  margin-top: 10px;
}

.form-group:first-child label {
  margin-top: 0;
}

.flex .form-group:first-child label {
  margin-top: 10px;
}

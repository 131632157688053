.filter-list {
  padding-top: 15px;
  position: relative;
  border-right: 1px solid #DEDFDE;
  height: 50px;
  padding-right: 20px;
}
.filter-list .dropdown-menu {
  overflow: visible;
  max-height: none;
  margin-top: 0;
  margin-left: -21px;
  border-top: 0;
  min-width: 250px;
}
.filter-list .filter-values-list {
  overflow-y: scroll;
  max-height: 300px;
}
.filter-list input {
  margin-top: 0;
  padding: 10px 5px;
  height: auto;
  width: 100%;
  font-size: 16px;
  border-color: #DEDFDE;
}
.filter-list input {
  border-top: none;
  border-left: 0;
  border-right: 0;
  margin-bottom: 5px;
}
.filter-list ul {
  padding: 3px 5px;
  margin: 0;
}
.filter-list li {
  list-style: none;
  padding: 5px;
}
.filter-list li a {
  display: block;
  width: 100%;
  height: 100%;
}
.filter-list .caret {
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: 10px;
}
.filter-list .open .caret {
  transform: rotate(180deg);
}
.filter-list .active {
  color: #61B4FF;
}

.filter-dropdown-button {
  padding: 10px;
}

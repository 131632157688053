.physical-measurements h1 {
  margin-top: 40px;
  margin-bottom: 50px;
}
.physical-measurements .row {
  padding-bottom: 30px;
}

.rv-crosshair__inner--right {
  left: -4px;
}

.profile-chart .detailedInfo .bubbleContainer .day {
  padding-top: 10px !important;
}

.barContainer .labels {
  color: #6f6f6f;
}

.add-care-team h1 {
  margin-bottom: 20px;
}
.add-care-team .form {
  display: none;
}
.add-care-team .form-control {
  margin-bottom: 20px;
  height: 40px;
  font-weight: 900;
  color: #656565;
}
.add-care-team .data-set {
  margin: 0;
  padding-left: 12px;
}
.add-care-team .data-set p {
  color: #787878;
}
.add-care-team .placeholder {
  color: #C8C8C8;
}
.add-care-team .apiError {
  color: #DB5E4B;
  margin-left: 15px;
  font-size: 12px;
}
.add-care-team .btn {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.add-care-team .animation-group {
  flex-direction: initial;
}
.add-care-team .animation-group div {
  margin: 0 10px 0 0 !important;
}

.user-search {
  position: relative;
  margin-top: -10px;
}
.user-search input {
  margin-top: 0;
  padding: 5px;
  height: auto;
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
}
.user-search .dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
  display: block;
  width: 100%;
}
.user-search .dropdown-menu li {
  font-size: 14px;
  padding: 5px;
}
.user-search .dropdown-menu li .email {
  display: block;
  font-size: 12px;
}
.user-search .dropdown-menu li span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-basic h1 {
  font-size: 24px;
  color: white;
}
.user-basic .patient-info-header {
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
}
.user-basic .data-set p {
  margin-bottom: 15px;
}
.user-basic .data-set.owner p {
  margin-bottom: 25px;
}

.user-basic-invite .procedure p, .user-basic-invite .birthday p {
  color: #DB5E4B;
}

.careteam-table {
  margin-top: 5px;
  width: 100%;
}
.careteam-table td {
  display: inline-block;
}
.careteam-table .careteam-data {
  width: 90%;
}
.careteam-table .data-set:hover {
  text-decoration: underline;
}
.careteam-table .close-button:hover {
  color: #FFFFFF;
}

.archive-container {
  margin-top: -10px;
  display: flex;
}
.archive-container p {
  margin-left: 10px;
  margin-top: 10px;
}

.archived-container input[type=checkbox] {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

.phases {
  flex-wrap: nowrap !important;
}

.phase-list-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0 !important;
  padding-right: 0;
  border-right: 1px solid #DFDFDF;
}

.edit-col {
  border-right: 1px solid #DFDFDF;
  height: calc(100vh - 100px);
  max-width: calc(100% - 375px);
  overflow-y: scroll;
}

.no-breadcrumb .edit-col {
  height: calc(100vh - 50px);
}

.phase-edit {
  margin-top: 10px;
  margin-bottom: 10px;
}

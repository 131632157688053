@media screen {
  html, body {
    height: 100%;
    overflow-x: hidden;
  }
  .dashboard-layout .dropdown-menu {
    border-radius: 0;
    top: auto;
  }
  .dashboard-layout #topBars {
    height: 100px;
  }
  .dashboard-layout #root {
    min-height: calc(100% - 100px);
    position: relative;
  }
  .dashboard-layout #root > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .dashboard-layout .content-container, .dashboard-layout .page-container {
    min-height: 100%;
  }
  .dashboard-layout .page-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .dashboard-layout .page-container > div {
    min-height: 100%;
    margin: 0;
  }
  .dashboard-layout .page-container .with-sidebar {
    overflow: auto;
  }
  .dashboard-layout .limit-width {
    max-width: 1290px !important;
  }
  .dashboard-layout .sidebar {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    width: 200px;
    height: 100%;
    float: left;
    box-sizing: border-box;
    position: relative;
    background-color: #6D778B;
    overflow-y: scroll;
  }
  .dashboard-layout .sidebar-right {
    float: right;
    right: 0;
    z-index: 9999;
  }
  .dashboard-layout .sidebar p a {
    color: #61B4FF;
  }
  .dashboard-layout .page-content {
    box-sizing: border-box;
    float: left;
    padding-left: 35px;
    padding-top: 20px;
    width: calc(100% - 210px);
    padding-right: 35px;
    min-height: 100%;
  }
  .dashboard-layout h1, .dashboard-layout h2, .dashboard-layout h3, .dashboard-layout h4 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .dashboard-layout h2 {
    font-size: 25px;
  }
  .dashboard-layout p, .dashboard-layout table, .dashboard-layout li {
    font-size: 16px;
  }
  .dashboard-layout a {
    color: #656565;
  }
  .dashboard-layout a:hover {
    text-decoration: underline;
    color: #656565;
    opacity: 1;
  }
  .dashboard-layout .table thead th {
    font-weight: normal;
    border: none;
  }
  .dashboard-layout .table tbody tr {
    border: none;
  }
  .dashboard-layout .table .bold-first-column tr td:first-child, .dashboard-layout .table .bold-first-column tr th:first-child {
    font-weight: bold;
  }
  .dashboard-layout .single-row-table thead th {
    font-weight: normal;
    border: none;
    padding-bottom: 0;
    font-size: 12px;
    padding-top: 0;
  }
  .dashboard-layout .single-row-table thead th:first-child {
    padding-left: 0;
  }
  .dashboard-layout .single-row-table tbody td {
    font-weight: bold;
    border: none;
    padding-top: 0;
  }
  .dashboard-layout .single-row-table tbody td:first-child {
    padding-left: 0;
  }
  .dashboard-layout input[type=password], .dashboard-layout input[type=tel] {
    margin-top: 0;
    background: white;
  }
  .dashboard-layout .error-message {
    color: #DB5E4B;
  }
  .dashboard-layout .btn-sm {
    height: 30px;
    line-height: 16px;
    font-size: 14px;
  }
  .form .form-message {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .btn-danger, .btn-danger:hover {
    background: #DB5E4B !important;
  }
  .action-btn {
    min-width: 50px;
    border: none;
  }
  input[type=checkbox] {
    outline: 2px solid #61B4FF;
    margin-bottom: -10px !important;
    margin-top: 10px !important;
    appearance: none;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 23px;
    box-sizing: border-box;
  }
  input[type=checkbox]:checked {
    background: url("./../../assets/checkmark.svg") center center no-repeat #61B4FF !important;
    background-size: 26px;
    border: none;
  }
  .no-breadcrumb #topBars {
    height: 50px;
  }
  .no-breadcrumb #root {
    min-height: calc(100% - 50px);
  }
  .no-breadcrumb .sidebar {
    height: calc(100% - 50px) !important;
  }
  .welcome-to-the-danger-zone #navigationBar .navigation-bar {
    background-color: #eb4511 !important;
    background-image: linear-gradient(315deg, #eb4511 0%, #b02e0c 74%) !important;
  }
}

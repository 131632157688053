.data-set {
  overflow: hidden;
  white-space: wrap;
  margin-right: 20px;
}
.data-set h3 {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 2px;
}
.data-set p {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  overflow: hidden;
  white-space: wrap;
  word-wrap: break-word;
}

.data-set.editable p {
  cursor: pointer;
}

.labels {
  color: #C4CEE4;
  padding-top: 5px;
}

.data-set.email p, .data-set.phone p, .data-set.background p {
  color: #61B4FF;
}

.data-set.background p {
  word-wrap: normal;
  overflow: visible;
}

.data-set.background h3, .data-set.comorbidities h3 {
  color: grey;
}
.data-set.background .comorbidities, .data-set.comorbidities .comorbidities {
  color: #61B4FF;
}

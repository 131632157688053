#react-select-4-listbox {
  z-index: 10;
}

.formik-markdown-field label {
  margin-bottom: 5px;
}

.markdown-group .no-disable, .markdown-group .fa-question-circle, .markdown-group .no-mobile + .separator {
  display: none;
}

.CodeMirror {
  height: auto !important;
  margin-bottom: 10px;
}

.CodeMirror-focused .CodeMirror-selected {
  background: rgb(145, 202, 255) !important;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: rgb(145, 202, 255) !important;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: rgb(145, 202, 255) !important;
}

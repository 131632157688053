.update-owner .owner-dropdown {
  margin-bottom: 20px;
}
.update-owner .owner-dropdown input {
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.update-owner .owner-dropdown .dropdown {
  min-height: fit-content;
  max-height: 250px;
  overflow: scroll;
}
.update-owner .owner-dropdown .dropdown li {
  padding: 0 15px;
  font-weight: 900;
  font-size: 14px;
  color: #787878;
}
.update-owner .owner-dropdown .dropdown li:hover {
  background: rgba(97, 180, 255, 0.3);
}
.update-owner .btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 900;
  font-size: 14px;
}
.update-owner .animation-group {
  flex-direction: initial;
}
.update-owner .animation-group div {
  margin: 0 10px 0 0 !important;
}

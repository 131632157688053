.profile-chart {
  overflow: hidden !important;
}
.profile-chart h1 {
  font-weight: bold;
}
.profile-chart h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}
.profile-chart .rv-hint {
  left: 0 !important;
}
.profile-chart .header {
  overflow: auto;
}
.profile-chart .image {
  float: right;
  width: 100px;
}
.profile-chart .best-value {
  float: left;
}
.profile-chart .rv-xy-plot__axis__tick__text {
  font-size: 16px;
  color: #989998;
}
.profile-chart .goal {
  position: relative;
  margin-top: -14px;
  width: 510px;
}
.profile-chart .goal .lines {
  position: absolute;
  left: 60px;
  right: 22px;
  top: 12px;
  border-top: 3px dashed #5AD700;
  display: block;
}
.profile-chart .annotation-value {
  background: #5AD700;
  display: block;
  position: absolute;
  right: 22px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  padding: 3px;
  font-size: 14px;
}
.profile-chart .rv-crosshair__line {
  background: #787878;
  width: 2px;
  margin-left: -2px;
  padding-bottom: 14px;
  box-sizing: content-box;
  height: 217px !important;
}
.profile-chart .rv-crosshair {
  /* http://www.csstriangle.com  I have no idea how this somehow works.... */
}
.profile-chart .rv-crosshair .lineArrow {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10.4px 6px 0 6px;
  border-color: rgb(120, 120, 120) transparent transparent transparent;
  position: absolute;
  left: -3px;
  top: -8px;
}
.profile-chart .rv-crosshair .lineLabel {
  font-size: 18px;
  position: absolute;
  width: 200px;
  left: -105px;
  text-align: center;
  top: -35px;
  color: #787878 !important;
}
.profile-chart .rv-crosshair .annotation-value {
  background: #FF395A;
  top: 240px;
  word-break: keep-all;
  white-space: nowrap;
  text-transform: uppercase;
  margin-right: -53px;
}
.profile-chart .bottom-axis {
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  color: #656565;
  position: relative;
  height: 20px;
  font-size: 16px;
}
.profile-chart .bottom-axis span {
  position: absolute;
  display: block;
}
.profile-chart .btn {
  width: 100%;
  margin-top: 30px;
}
.profile-chart .rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick__text {
  text-anchor: start !important;
}
.profile-chart .rv-xy-plot__axis--horizontal .rv-xy-plot__axis__tick:last-child .rv-xy-plot__axis__tick__text {
  text-anchor: end !important;
}
.profile-chart .legenda {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 50px;
}
.profile-chart .legenda-item {
  float: left;
  display: inline-block;
  margin-right: 14px;
  padding-left: 0;
  font-size: 18px;
  font-weight: 300;
  color: #787878;
}
.profile-chart .legenda-item .color {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.profile-chart .detailedInfo {
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 180px;
  clear: both;
}
.profile-chart .detailedInfo .goodBubble {
  background: url("./images/goodBubble.svg") no-repeat;
}
.profile-chart .detailedInfo .badBubble {
  background: url("./images/badBubble.svg") no-repeat;
}
.profile-chart .detailedInfo .bubble {
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: 190px;
  height: 190px;
}
.profile-chart .detailedInfo .prehabBubble {
  border-radius: 95px;
  background-color: #61B4FF;
  width: 170px;
  height: 170px;
}
.profile-chart .detailedInfo .prehabBubbleContainer .day {
  padding-top: 45px !important;
}
.profile-chart .detailedInfo .bubbleText {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 170px;
  box-sizing: border-box;
}
.profile-chart .detailedInfo .bubbleContainer {
  width: 170px;
  height: 170px;
  box-sizing: border-box;
  position: relative;
  color: white;
  text-align: center;
  float: left;
}
.profile-chart .detailedInfo .bubbleContainer .day {
  font-weight: bold;
  font-size: 20px;
  padding-top: 30px;
}
.profile-chart .detailedInfo .bubbleContainer .value {
  font-size: 54px;
  font-weight: bold;
  padding-top: 20px;
}
.profile-chart .detailedInfo .bubbleContainer .result {
  font-size: 24px;
  font-weight: bold;
  padding-top: 20px;
}
.profile-chart .detailedInfo .barContainer {
  float: left;
  margin-left: 30px;
  width: 200px;
  height: 170px;
  position: relative;
}
.profile-chart .detailedInfo .barContainer .bar {
  width: 30px;
  height: 100%;
  position: relative;
  overflow: visible;
}
.profile-chart .detailedInfo .barContainer .roundedBar {
  width: 30px;
  height: 100%;
  background: #DCDCDC;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.profile-chart .detailedInfo .barContainer .mainColor, .profile-chart .detailedInfo .barContainer .maskColor {
  width: 100%;
  position: absolute;
}
.profile-chart .detailedInfo .topValue, .profile-chart .detailedInfo .bottomValue {
  position: absolute;
  left: 50px;
  top: 10px;
}
.profile-chart .detailedInfo .line {
  display: inline-block;
  height: 1px;
  background: #979797;
  width: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.profile-chart .detailedInfo .bottomValue {
  top: auto;
  bottom: 0;
}
.profile-chart .detailedInfo .centerLabel {
  position: absolute;
  left: 50px;
  top: calc(50% - 23px);
}
.profile-chart .detailedInfo .peep {
  width: 50px;
  height: 35px;
  margin-top: -15px;
  left: -10px;
  background: url("./images/sad.svg") no-repeat;
  position: absolute;
}
.profile-chart .detailedInfo .happyPeep {
  background: url("./images/happy.svg") no-repeat;
}
.profile-chart .isOutOfDate {
  padding-top: 10px;
}
.profile-chart .isOutOfDate .icon {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  color: white;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
  background-color: #FFBA31;
  float: left;
  margin-right: 25px;
  margin-bottom: 35px;
}
.profile-chart .isOutOfDate .content {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.profile-chart .isOutOfDate .content p {
  font-size: 16px;
  margin-bottom: 12px;
}
.profile-chart .isOutOfDate .content .days {
  margin-top: 5px;
  font-size: 18.5px;
  color: #FFBA31;
}
.profile-chart .outro {
  background-color: #F0F0F0;
  border: 1px solid #B4B4B4;
  border-radius: 10px;
  padding: 14px 22px;
  margin-top: 20px;
  overflow: auto;
  clear: both;
}
.profile-chart .outro p, .profile-chart .outro h3 {
  margin: 0;
  padding: 0;
}
.profile-chart .outro h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.profile-chart .outro .warning {
  font-weight: bold;
  color: #E9403E;
  margin-bottom: 12px;
}

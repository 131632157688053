@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
html {
  margin: 0;
  padding: 0;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  color: #656565;
  font-family: Roboto;
  background-color: #fefefe;
}

button {
  font-family: Roboto;
}

a {
  color: #787878;
}

a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.page-container {
  min-width: 855px;
}

h1 {
  color: #656565;
  font-size: 30px;
  margin-top: 0;
  font-weight: 500;
}

.btn, .btn:hover, .btn:focus, .btn[disabled] {
  background-color: #61B4FF !important;
  border: none;
  line-height: 23px;
  height: 55px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 19px;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}

.btn[disabled] {
  background-color: #B4B4B4 !important;
}

.btn-main {
  padding: 13px;
  vertical-align: middle;
  width: 100%;
  margin-top: 20px;
  display: block;
}

input[type=text], input[type=tel], input[type=password], .phone-control {
  background: none;
  font-size: 19px;
  height: 50px;
  padding: 10px 15px;
  border: 1px solid #B4B4B4;
  margin-top: 50px;
  font-weight: normal;
  color: #61B4FF;
  appearance: none !important;
  box-shadow: none !important;
}

input:focus {
  outline: none !important;
  border: 1px solid #61B4FF;
}

input.has-error, input.has-error:focus {
  border: 1px solid #DB5E4B !important;
}

input[type=text]:disabled, input[type=password]:disabled {
  color: #656565;
}

/* do not group these rules (it will then fail in all browsers) */
*::-webkit-input-placeholder {
  color: #C4C4C4 !important;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #C4C4C4 !important;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #C4C4C4 !important;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #C4C4C4 !important;
}

a:hover {
  cursor: pointer;
  color: #61B4FF;
}

p, label {
  font-size: 19px;
  line-height: 23px;
  margin-bottom: 20px;
}

table a {
  color: #61B4FF;
}

.big-number {
  color: #61B4FF;
  font-size: 37px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.caret-up {
  transform: rotate(180deg);
}

.caret.right {
  transform: rotate(270deg);
}

.caret.left {
  transform: rotate(90deg);
}

.background-group {
  overflow: auto;
}

.background-fields {
  display: flex;
  justify-content: start;
}

.comorbidities {
  grid-column-start: 4;
  grid-row-start: 1;
}

.portal-container .modal-panel {
  width: fit-content;
}

.coach-form {
  width: fit-content;
  background: #FAFAFA;
}
.coach-form .react-autosuggest__suggestions-container--open {
  position: absolute;
  background: #FFFFFF;
  z-index: 1;
}
.coach-form .autocomplete-form-control {
  height: 50px;
}
.coach-form .coach-header-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 36px;
}
.coach-form .coach-header-container .coach-form-header {
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 24px;
  color: #787878;
}
.coach-form .coach-fields {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.coach-form .coach-fields label {
  font-size: 12px;
  font-weight: normal;
}
.coach-form .coach-fields .coach-input-field {
  border-radius: 5px;
  font-weight: 900;
  height: 40px;
  color: #787878;
}
.coach-form .coach-fields .name {
  width: 20em;
}
.coach-form .coach-fields .email {
  width: 30em;
}
.coach-form .checkbox-field-container .checkbox, .coach-form .checkbox-field-container .checkbox-form-control {
  display: inline;
}
.coach-form .checkbox-field-container .checkbox-description {
  padding-top: 2px;
  margin-left: 32px;
  font-size: 14px;
}
.coach-form .coach-submit-button {
  display: block;
  height: 41px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border-radius: 5px;
  margin: 40px auto 0 auto;
  background: #6D778B;
  border: none;
}
.coach-form .coach-submit-button:focus {
  border: none;
}
.coach-form .asterisk {
  color: #61B4FF;
  padding-left: 1px;
}
.coach-form .disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

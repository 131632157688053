.manage-admin {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  height: fit-content;
}
.manage-admin .Select-clear-zone {
  display: none !important;
}
.manage-admin p {
  margin: 0;
}
.manage-admin .header-prefix {
  font-weight: 900;
  font-size: 18px;
}
.manage-admin .header-info {
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-admin .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-admin .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-admin .update-header {
  margin-bottom: 20px;
}
.manage-admin .form-header {
  margin-bottom: 20px;
}
.manage-admin .update-existing {
  margin-top: 20px;
}
.manage-admin .update-existing .or-update {
  margin-bottom: 5px;
}
.manage-admin .select-customer .customer-dropdown, .manage-admin .select-customer .admin-dropdown, .manage-admin .select-admin .customer-dropdown, .manage-admin .select-admin .admin-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-admin .select-customer .customer-dropdown .customer-search, .manage-admin .select-customer .customer-dropdown .admin-search, .manage-admin .select-customer .admin-dropdown .customer-search, .manage-admin .select-customer .admin-dropdown .admin-search, .manage-admin .select-admin .customer-dropdown .customer-search, .manage-admin .select-admin .customer-dropdown .admin-search, .manage-admin .select-admin .admin-dropdown .customer-search, .manage-admin .select-admin .admin-dropdown .admin-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-admin .select-customer .customer-dropdown .customer-list, .manage-admin .select-customer .customer-dropdown .admin-list, .manage-admin .select-customer .admin-dropdown .customer-list, .manage-admin .select-customer .admin-dropdown .admin-list, .manage-admin .select-admin .customer-dropdown .customer-list, .manage-admin .select-admin .customer-dropdown .admin-list, .manage-admin .select-admin .admin-dropdown .customer-list, .manage-admin .select-admin .admin-dropdown .admin-list {
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-admin .select-customer .customer-dropdown .customer-list .customer-item, .manage-admin .select-customer .customer-dropdown .customer-list .admin-item, .manage-admin .select-customer .customer-dropdown .admin-list .customer-item, .manage-admin .select-customer .customer-dropdown .admin-list .admin-item, .manage-admin .select-customer .admin-dropdown .customer-list .customer-item, .manage-admin .select-customer .admin-dropdown .customer-list .admin-item, .manage-admin .select-customer .admin-dropdown .admin-list .customer-item, .manage-admin .select-customer .admin-dropdown .admin-list .admin-item, .manage-admin .select-admin .customer-dropdown .customer-list .customer-item, .manage-admin .select-admin .customer-dropdown .customer-list .admin-item, .manage-admin .select-admin .customer-dropdown .admin-list .customer-item, .manage-admin .select-admin .customer-dropdown .admin-list .admin-item, .manage-admin .select-admin .admin-dropdown .customer-list .customer-item, .manage-admin .select-admin .admin-dropdown .customer-list .admin-item, .manage-admin .select-admin .admin-dropdown .admin-list .customer-item, .manage-admin .select-admin .admin-dropdown .admin-list .admin-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-admin .select-customer .customer-dropdown .customer-list .customer-item:hover, .manage-admin .select-customer .customer-dropdown .customer-list .admin-item:hover, .manage-admin .select-customer .customer-dropdown .admin-list .customer-item:hover, .manage-admin .select-customer .customer-dropdown .admin-list .admin-item:hover, .manage-admin .select-customer .admin-dropdown .customer-list .customer-item:hover, .manage-admin .select-customer .admin-dropdown .customer-list .admin-item:hover, .manage-admin .select-customer .admin-dropdown .admin-list .customer-item:hover, .manage-admin .select-customer .admin-dropdown .admin-list .admin-item:hover, .manage-admin .select-admin .customer-dropdown .customer-list .customer-item:hover, .manage-admin .select-admin .customer-dropdown .customer-list .admin-item:hover, .manage-admin .select-admin .customer-dropdown .admin-list .customer-item:hover, .manage-admin .select-admin .customer-dropdown .admin-list .admin-item:hover, .manage-admin .select-admin .admin-dropdown .customer-list .customer-item:hover, .manage-admin .select-admin .admin-dropdown .customer-list .admin-item:hover, .manage-admin .select-admin .admin-dropdown .admin-list .customer-item:hover, .manage-admin .select-admin .admin-dropdown .admin-list .admin-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-admin .select-customer .customer-dropdown .customer-list .isSelected, .manage-admin .select-customer .customer-dropdown .customer-list .isSelected:hover, .manage-admin .select-customer .customer-dropdown .admin-list .isSelected, .manage-admin .select-customer .customer-dropdown .admin-list .isSelected:hover, .manage-admin .select-customer .admin-dropdown .customer-list .isSelected, .manage-admin .select-customer .admin-dropdown .customer-list .isSelected:hover, .manage-admin .select-customer .admin-dropdown .admin-list .isSelected, .manage-admin .select-customer .admin-dropdown .admin-list .isSelected:hover, .manage-admin .select-admin .customer-dropdown .customer-list .isSelected, .manage-admin .select-admin .customer-dropdown .customer-list .isSelected:hover, .manage-admin .select-admin .customer-dropdown .admin-list .isSelected, .manage-admin .select-admin .customer-dropdown .admin-list .isSelected:hover, .manage-admin .select-admin .admin-dropdown .customer-list .isSelected, .manage-admin .select-admin .admin-dropdown .customer-list .isSelected:hover, .manage-admin .select-admin .admin-dropdown .admin-list .isSelected, .manage-admin .select-admin .admin-dropdown .admin-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-admin .create-users-form, .manage-admin .update-admin-form, .manage-admin .user-details {
  margin-top: 20px;
}
.manage-admin .action-btn {
  height: 30px;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-admin .action-btn:hover {
  opacity: 0.85;
}
.manage-admin .red {
  background-color: #da4933;
}
.manage-admin .checkbox-form-control {
  margin-top: 12px;
}
.manage-admin .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-admin label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-admin h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-admin h3 {
  font-size: 22px;
}
.manage-admin h3, .manage-admin h4 {
  margin: 0 0 5px 0;
}
.manage-admin .message {
  margin-top: 20px;
  margin-bottom: 0;
}

.admin-password-container {
  margin-bottom: 10px !important;
  background-color: white !important;
}

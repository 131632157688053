.profile-locked {
  position: relative;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}
.profile-locked .locked-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  right: 0;
  text-align: center;
}
.profile-locked .chart-container {
  height: 540px;
  padding: 20px;
}
.profile-locked .locked-message {
  position: absolute;
  margin: 260px 20px 0 20px;
  background: white;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #aaa;
}
.profile-locked .blur-container {
  overflow: hidden;
}
.profile-locked .blur-darker {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  width: 110% !important;
  margin-left: -5% !important;
  margin-top: -10px;
  margin-bottom: -10px;
  padding: 20px;
  position: relative;
  z-index: 5;
}

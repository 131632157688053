.csv-download-button, .csv-download-button:hover, .csv-download-button:active, .csv-download-button:focus {
  font-size: 12px;
  line-height: 1.5;
  height: auto;
  margin-bottom: 5px;
  cursor: pointer !important;
  z-index: 999999;
  position: relative;
  float: right;
  background: none !important;
  padding: 3px;
  text-decoration: underline;
  color: #656565;
  border: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.user-actions {
  margin-bottom: 20px;
}
.user-actions .btn {
  margin-right: 10px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.user-actions .btn-danger {
  background: #CF6054 !important;
}

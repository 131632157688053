.manage-customer {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  min-width: 43%;
  max-width: fit-content;
  margin-left: 50px;
  margin-bottom: 50px;
  max-height: fit-content;
}
.manage-customer .manage-loading-indicator {
  height: 75px;
  width: 75px;
}
.manage-customer .edit-field {
  margin-bottom: 15px;
}
.manage-customer .checkbox-form-control {
  overflow: unset;
}
.manage-customer .checkbox-form-control .error-message {
  position: absolute;
  left: -50px;
  top: 25px;
}
.manage-customer p {
  margin: 0;
}
.manage-customer .header-info {
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-customer .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-customer .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-customer .update-existing {
  margin-top: 20px;
}
.manage-customer .customer-actions .clinician-list {
  width: 40%;
  font-size: 13px;
}
.manage-customer span {
  font-weight: 900;
  font-size: 18px;
}
.manage-customer .manage-customer-form .form-header {
  margin-bottom: 20px;
}
.manage-customer .action-btn {
  height: 30px;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-customer .action-btn:hover {
  opacity: 0.85;
}
.manage-customer .checkbox-form-control {
  margin-top: 12px;
}
.manage-customer .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-customer .update-customer-type-warning {
  margin-top: -11px;
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
  width: 506px;
}
.manage-customer label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-customer h2 {
  margin-bottom: 20px;
  font-weight: 900;
}
.manage-customer h3 {
  font-size: 22px;
}
.manage-customer h3, .manage-customer h4 {
  margin: 0 0 5px 0;
}
.manage-customer .message {
  margin-top: 20px;
  margin-bottom: 0;
}
.manage-customer .customer-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-customer .customer-dropdown .customer-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-customer .customer-dropdown .customer-list {
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-customer .customer-dropdown .customer-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-customer .customer-dropdown .customer-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}

.care-team-section {
  display: flex;
  flex-direction: column;
}
.care-team-section .added-members {
  margin-top: 52px;
}
.care-team-section .added-members .added-member {
  position: relative;
  margin-bottom: 15px;
}
.care-team-section .added-members .added-member p {
  margin: 0;
}
.care-team-section .added-members .added-member .added-member-job {
  font-size: 12px;
  font-weight: normal;
}
.care-team-section .added-members .added-member .added-member-id {
  font-size: 14px;
  font-weight: 900;
}
.care-team-section .added-members .added-member .delete-care-team-member {
  position: absolute;
  top: 10px;
  left: 235px;
  color: #B4B4B4;
}
.care-team-section .add-care-team-member-button {
  background: #6D778B;
  border-radius: 5px;
  margin-top: 75px;
  height: 40px;
  width: 252px;
  color: #FFFFFF;
  font-weight: 900;
  border: none;
  cursor: pointer;
}
.care-team-section .add-care-team-member-button:focus {
  outline: none;
}
.care-team-section .input-email-group {
  margin-top: 75px;
}
.care-team-section .input-email-group .care-team-email-input {
  width: 252px;
  margin-bottom: 20px;
  height: 40px;
  font-weight: 900;
  color: #787878;
}
.care-team-section .button-container {
  display: flex;
  justify-content: space-between;
}
.care-team-section .button-container .care-team-email-button, .care-team-section .button-container .care-team-job-button, .care-team-section .button-container .care-team-cancel-button {
  border-radius: 5px;
  height: 40px;
  width: 115px;
  color: #FFFFFF;
  font-weight: 900;
  border: none;
  cursor: pointer;
}
.care-team-section .button-container .care-team-email-button, .care-team-section .button-container .care-team-job-button {
  background: #6D778B;
}
.care-team-section .button-container .care-team-cancel-button {
  background: #B4B4B4;
}
.care-team-section .button-container .care-team-email-button:focus {
  outline-color: #6D778B;
  cursor: not-allowed;
}
.care-team-section .button-container .care-team-job-button:focus {
  outline: none;
}
.care-team-section .button-container .care-team-job-button.disabled, .care-team-section .button-container .care-team-email-button.disabled {
  cursor: not-allowed;
}
.care-team-section .job-select-group {
  margin-top: 5px;
}
.care-team-section .job-select-group .care-team-job-select {
  width: 252px;
  margin-bottom: 20px;
}
.care-team-section .input-email-group.hasAddedMembers, .care-team-section .add-care-team-member-button.hasAddedMembers {
  margin-top: 5px;
}

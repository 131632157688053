.animation-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.animation-group .success-message {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
}

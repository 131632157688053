.manage-clinician {
  background: #eeeeee;
  border: 1px solid gray;
  padding: 24px 30px 36px 30px;
  width: 43%;
  margin-left: 50px;
  height: fit-content;
}
.manage-clinician .Select-clear-zone {
  display: none !important;
}
.manage-clinician .manage-loading-indicator {
  height: 75px;
  width: 75px;
}
.manage-clinician p {
  margin: 0;
}
.manage-clinician .header-prefix {
  font-weight: 900;
  font-size: 18px;
}
.manage-clinician .header-info {
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.manage-clinician .header-info .header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: flex-end;
}
.manage-clinician .header-info .header-group .selected-customer {
  margin-right: 20px;
}
.manage-clinician .update-header {
  margin-bottom: 20px;
}
.manage-clinician .form-header {
  margin-bottom: 20px;
}
.manage-clinician .update-existing {
  margin-top: 20px;
}
.manage-clinician .update-existing .or-update {
  margin-bottom: 5px;
}
.manage-clinician .select-customer .customer-dropdown, .manage-clinician .select-customer .clinician-dropdown, .manage-clinician .select-clinician .customer-dropdown, .manage-clinician .select-clinician .clinician-dropdown {
  position: relative;
  font-weight: 400;
  color: unset;
}
.manage-clinician .select-customer .customer-dropdown .customer-search, .manage-clinician .select-customer .customer-dropdown .clinician-search, .manage-clinician .select-customer .clinician-dropdown .customer-search, .manage-clinician .select-customer .clinician-dropdown .clinician-search, .manage-clinician .select-clinician .customer-dropdown .customer-search, .manage-clinician .select-clinician .customer-dropdown .clinician-search, .manage-clinician .select-clinician .clinician-dropdown .customer-search, .manage-clinician .select-clinician .clinician-dropdown .clinician-search {
  height: 45px;
  width: 300px;
  padding: 6px;
  font-size: 16px;
}
.manage-clinician .select-customer .customer-dropdown .customer-list, .manage-clinician .select-customer .customer-dropdown .clinician-list, .manage-clinician .select-customer .clinician-dropdown .customer-list, .manage-clinician .select-customer .clinician-dropdown .clinician-list, .manage-clinician .select-clinician .customer-dropdown .customer-list, .manage-clinician .select-clinician .customer-dropdown .clinician-list, .manage-clinician .select-clinician .clinician-dropdown .customer-list, .manage-clinician .select-clinician .clinician-dropdown .clinician-list {
  min-height: fit-content;
  max-height: 150px;
  width: 300px;
  border: 1px solid gray;
  overflow: scroll;
  list-style-type: none;
  padding: 4px 0;
}
.manage-clinician .select-customer .customer-dropdown .customer-list .customer-item, .manage-clinician .select-customer .customer-dropdown .customer-list .clinician-item, .manage-clinician .select-customer .customer-dropdown .clinician-list .customer-item, .manage-clinician .select-customer .customer-dropdown .clinician-list .clinician-item, .manage-clinician .select-customer .clinician-dropdown .customer-list .customer-item, .manage-clinician .select-customer .clinician-dropdown .customer-list .clinician-item, .manage-clinician .select-customer .clinician-dropdown .clinician-list .customer-item, .manage-clinician .select-customer .clinician-dropdown .clinician-list .clinician-item, .manage-clinician .select-clinician .customer-dropdown .customer-list .customer-item, .manage-clinician .select-clinician .customer-dropdown .customer-list .clinician-item, .manage-clinician .select-clinician .customer-dropdown .clinician-list .customer-item, .manage-clinician .select-clinician .customer-dropdown .clinician-list .clinician-item, .manage-clinician .select-clinician .clinician-dropdown .customer-list .customer-item, .manage-clinician .select-clinician .clinician-dropdown .customer-list .clinician-item, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .customer-item, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .clinician-item {
  padding: 1px 6px;
  font-size: 16px;
}
.manage-clinician .select-customer .customer-dropdown .customer-list .customer-item:hover, .manage-clinician .select-customer .customer-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-customer .customer-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-customer .customer-dropdown .clinician-list .clinician-item:hover, .manage-clinician .select-customer .clinician-dropdown .customer-list .customer-item:hover, .manage-clinician .select-customer .clinician-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-customer .clinician-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-customer .clinician-dropdown .clinician-list .clinician-item:hover, .manage-clinician .select-clinician .customer-dropdown .customer-list .customer-item:hover, .manage-clinician .select-clinician .customer-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-clinician .customer-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-clinician .customer-dropdown .clinician-list .clinician-item:hover, .manage-clinician .select-clinician .clinician-dropdown .customer-list .customer-item:hover, .manage-clinician .select-clinician .clinician-dropdown .customer-list .clinician-item:hover, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .customer-item:hover, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .clinician-item:hover {
  cursor: pointer !important;
  background: rgba(97, 180, 255, 0.3);
}
.manage-clinician .select-customer .customer-dropdown .customer-list .isSelected, .manage-clinician .select-customer .customer-dropdown .customer-list .isSelected:hover, .manage-clinician .select-customer .customer-dropdown .clinician-list .isSelected, .manage-clinician .select-customer .customer-dropdown .clinician-list .isSelected:hover, .manage-clinician .select-customer .clinician-dropdown .customer-list .isSelected, .manage-clinician .select-customer .clinician-dropdown .customer-list .isSelected:hover, .manage-clinician .select-customer .clinician-dropdown .clinician-list .isSelected, .manage-clinician .select-customer .clinician-dropdown .clinician-list .isSelected:hover, .manage-clinician .select-clinician .customer-dropdown .customer-list .isSelected, .manage-clinician .select-clinician .customer-dropdown .customer-list .isSelected:hover, .manage-clinician .select-clinician .customer-dropdown .clinician-list .isSelected, .manage-clinician .select-clinician .customer-dropdown .clinician-list .isSelected:hover, .manage-clinician .select-clinician .clinician-dropdown .customer-list .isSelected, .manage-clinician .select-clinician .clinician-dropdown .customer-list .isSelected:hover, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .isSelected, .manage-clinician .select-clinician .clinician-dropdown .clinician-list .isSelected:hover {
  background: rgba(97, 180, 255, 0.7);
}
.manage-clinician .create-clinician-form, .manage-clinician .update-clinician-form {
  margin-top: 20px;
}
.manage-clinician .action-btn {
  height: 30px;
  width: fit-content;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #61B4FF;
  color: white;
}
.manage-clinician .action-btn:hover {
  opacity: 0.85;
}
.manage-clinician .red {
  background-color: #da4933;
}
.manage-clinician .checkbox-form-control {
  margin-top: 12px;
}
.manage-clinician .checkbox-form-control label {
  left: 35px;
  margin-top: 0 !important;
}
.manage-clinician label {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}
.manage-clinician h2 {
  margin-bottom: 15px;
  font-weight: 900;
}
.manage-clinician h3 {
  font-size: 22px;
}
.manage-clinician h3, .manage-clinician h4 {
  margin: 0 0 5px 0;
}
.manage-clinician .message {
  margin-top: 20px;
  margin-bottom: 0;
}

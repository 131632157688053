.breadcrumb-bar {
  width: 100%;
  background: white;
  border-bottom: 1px solid #DFDFDF !important;
  height: 50px;
  padding: 0 20px;
}
.breadcrumb-bar .caret {
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  color: #B4B4B4;
}
.breadcrumb-bar .breadcrumb-path {
  padding-top: 15px;
}
.breadcrumb-bar .breadcrumb-path a {
  color: #61B4FF;
}
.breadcrumb-bar .breadcrumb-menus {
  padding-left: 0;
  overflow: visible;
  margin-bottom: 0px;
}
.breadcrumb-bar .breadcrumb-menus .menu {
  display: block;
  float: left;
  margin-left: 0;
  padding-top: 15px;
  border-right: 1px solid #ddd;
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
}
.breadcrumb-bar .breadcrumb-menus .menu:first-child {
  padding-left: 0;
}

.assessment-score {
  background: white;
  border-radius: 10px;
  flex: 0 0 33.333333%;
  margin: 10px;
  box-sizing: border-box;
  list-style: none;
  box-shadow: #dfdfe7 3px 3px 5px;
  cursor: pointer;
}
.assessment-score h2 {
  background: #61B4FF;
  color: white;
  padding: 6px 12px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
}

.assessment-table tr td:first-child {
  padding-left: 12px;
}
.assessment-table tr td:last-child {
  padding-right: 12px;
}
.assessment-table tr:nth-child(even) {
  background-color: #e0e0e0;
}
.assessment-table tr:nth-child(odd) {
  background-color: white;
}

.assessment-score:hover {
  box-shadow: #c1c1c7 3px 3px 5px;
}

.checkbox-form-control {
  overflow: auto;
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  padding: 0;
}
.checkbox-form-control label, .checkbox-form-control input {
  display: inline-block;
  position: absolute;
}
.checkbox-form-control input {
  top: -10px;
  left: 0;
}
.checkbox-form-control label {
  top: 10px;
  left: 60px;
}

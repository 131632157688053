.list-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.programs-lists {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.programs-lists select,
.programs-lists input {
  max-width: 200px;
  height: 24px;
  padding-left: 8px;
}

.programs-field-label {
  font-weight: bold;
  margin: 16px 0 8px;
  display: block;
}

.program-cards {
  width: 60%;
}

.cards-list {
  list-style: none;
  padding: 0;
}

.card-list-item {
  padding: 4px 16px;
  cursor: pointer;
}
.card-list-item .fa {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.card-list-item:hover {
  background-color: #61B4FF;
  color: white;
}
.card-list-item:hover .fa {
  display: inline-block;
}

.empty-card-list {
  padding-left: 8px;
  display: block;
}

.event-type-radio {
  display: inline-block;
  vertical-align: middle;
}

.radio-field-label {
  font-weight: bold;
  margin: 0px 5px 0px;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-in-coaches .breadcrumb-bar {
  padding: 0px;
}
.dashboard-in-coaches .breadcrumb-bar button, .dashboard-in-coaches .breadcrumb-bar input {
  border: none;
}
.dashboard-in-coaches .breadcrumb-bar button:focus, .dashboard-in-coaches .breadcrumb-bar input:focus {
  outline: none;
}
.dashboard-in-coaches .breadcrumb-bar .management-row {
  background: #5B6272;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 19px 20px;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .search-container {
  height: 30px;
  width: 443px;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .search-container .search-input {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
  color: #787878;
  font-weight: 900;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .search-container .search-input:focus {
  border-color: #DCDCDC;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .add-coach-button {
  height: 30px;
  width: fit-content;
  padding: 0 10px;
  background: #61B4FF;
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  z-index: 9999;
  font-weight: 900;
}
.dashboard-in-coaches .breadcrumb-bar .management-row .add-coach-button:hover {
  background: #359bf5;
}

.coaches-list {
  background: #FFFFFF;
}
.coaches-list .coach-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  height: calc(100vh - 100px);
  overflow: scroll;
}
.coaches-list .coach-table-container .loading-indicator {
  position: absolute;
  top: 250px;
  left: 50%;
  height: 75px;
  width: 75px;
  transform: translate(-50%, -50%);
}
.coaches-list .coach-table-container .no-results-container {
  position: absolute;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.coaches-list .coach-table-container .no-results-container .no-results {
  padding: 18px 26px 18px 18px;
  width: fit-content;
  background: #F5F5F5;
  border-radius: 5px;
}
.coaches-list .coach-table-container .no-results-container .no-results .no-results-found-header {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #CC5062;
}
.coaches-list .coach-table-container .no-results-container .no-results .you-can-search-on {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}
.coaches-list .coach-table-container .no-results-container .no-results .fields-to-search-on .field-to-search-on {
  font-size: 14px;
  font-weight: 900;
  color: #787878;
  padding: 0 !important;
  margin: 0 !important;
}
.coaches-list .coach-table {
  border-collapse: collapse;
  width: 100%;
}
.coaches-list .coach-table .hideTableRows {
  visibility: hidden;
}
.coaches-list .coach-table .hideTableRows tr .resend-invite-button, .coaches-list .coach-table .hideTableRows tr .edit-coach-button, .coaches-list .coach-table .hideTableRows tr .view-patients-button {
  visibility: hidden;
}
.coaches-list .coach-table thead.hideTableHead {
  visibility: hidden !important;
}
.coaches-list .coach-table thead tr:hover {
  background: inherit;
}
.coaches-list .coach-table td, .coaches-list .coach-table th {
  padding: 3px 0;
}
.coaches-list .coach-table th {
  font-weight: normal;
  font-size: 12px;
}
.coaches-list .coach-table th.sortable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.coaches-list .coach-table td {
  font-weight: 900;
  font-size: 14px;
}
.coaches-list .coach-table tr {
  cursor: default;
}
.coaches-list .coach-table tr td:first-child, .coaches-list .coach-table tr th:first-child {
  padding-left: 20px;
}
.coaches-list .coach-table tr .not-available {
  padding-left: 10px;
}
.coaches-list .coach-table tr:hover {
  background: #DCF1FF;
}
.coaches-list .coach-table tr:hover .resend-invite-button, .coaches-list .coach-table tr:hover .edit-coach-button, .coaches-list .coach-table tr:hover .view-patients-button {
  visibility: visible;
}
.coaches-list .coach-table .td-with-buttons {
  text-align: right;
  padding-right: 20px;
}
.coaches-list .coach-table .td-with-buttons .resend-invite-button, .coaches-list .coach-table .td-with-buttons .edit-coach-button, .coaches-list .coach-table .td-with-buttons .view-patients-button {
  visibility: hidden;
  height: 25px;
  background: #6D778B;
  border: none;
  font-size: 14px;
  font-weight: 900;
  color: #FFFFFF;
  border-radius: 5px;
}
.coaches-list .coach-table .td-with-buttons .resend-invite-button {
  width: fit-content;
  padding: 0 10px;
  margin-right: 15px;
}
.coaches-list .coach-table .td-with-buttons .edit-coach-button {
  width: fit-content;
  padding: 0 10px;
}
.coaches-list .coach-table .td-with-buttons .view-patients-button {
  width: fit-content;
  padding: 0 8px;
  margin-right: 15px;
}
.coaches-list .coach-table .td-with-buttons .resend-invite-button:focus, .coaches-list .coach-table .td-with-buttons .edit-coach-button:focus, .coaches-list .coach-table .td-with-buttons .view-patients-button:focus {
  outline: none;
}
.coaches-list .coach-table .edit-icon {
  margin-right: 5px;
}
.coaches-list .coach-table .clickedRow {
  background: #61B4FF !important;
  color: #FFFFFF !important;
}
.coaches-list .coach-table .clickedRow .resend-invite-button, .coaches-list .coach-table .clickedRow .edit-coach-button, .coaches-list .coach-table .clickedRow .view-patients-button {
  visibility: visible;
}
.coaches-list .caret {
  border-top: 11px dashed;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  color: #DCDCDC;
  margin-left: 5px;
}

.portal-container-in-coaches .close-button {
  color: #787878;
}
.portal-container-in-coaches .resend-invite {
  display: flex;
  padding: 10px 10px 0 10px;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  background-color: #FAFAFA;
}
.portal-container-in-coaches .resend-invite .resend-invite-header {
  margin-bottom: 30px;
  font-size: 16px;
  color: #787878;
}
.portal-container-in-coaches .resend-invite .resend-invite-button {
  height: 30px;
  padding: 0 10px;
  background-color: #6D778B;
  border-radius: 5px;
  color: #FFFFFF;
  border: none;
}
.portal-container-in-coaches .resend-invite .resend-invite-button:focus {
  outline: none;
}
.portal-container-in-coaches .resend-invite .resend-invite-button:hover {
  opacity: 0.9;
}
